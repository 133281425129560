var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { say, showRootComponent } from "../Utils/webHelper";
import { moduleContent as HubModule } from "../Components/Hub";
import { moduleContent as ProjectContentModule } from "../Components/ProjectContent";
import { Popup } from "./IHub";
import { getListOfAllModules } from "../Utils/modules";
import * as React from "react";
import { cleansePageName } from "../Utils/transformer";
import { getDisplayNameByModuleInstanceId, getModuleInstanceDisplayName } from "../Components/ProjectContent/Utils/projectInfo";
import styles from './../HubFramework/HubFramework.module.css';
class PathRouting {
    constructor() {
        this._observers = [];
        //...
    }
    static get Instance() {
        // Do you need arguments? Make it a regular static method instead.
        return this._instance || (this._instance = new this());
    }
    subscribe(observer) {
        this._observers.push(observer);
    }
    unsubscribe(observer) {
        this._observers = this._observers.filter(obs => obs !== observer);
    }
    _notify() {
        this._observers.forEach(observer => observer());
    }
    get currentProject() {
        return this._currentProject;
    }
    set currentProject(value) {
        this._currentProject = value;
        if (!value.displayName) {
            getDisplayNameByModuleInstanceId(value.moduleInstanceId).then((displayName) => {
                value.displayName = displayName;
                this._notify();
            });
        }
        this._notify();
    }
    get currentModule() {
        return this._currentModule;
    }
    set currentModule(value) {
        this._currentModule = value;
        if (!value.displayName) {
            getModuleInstanceDisplayName(this.currentProject.moduleInstanceId, value.moduleInstanceId).then((displayName) => {
                value.displayName = displayName;
                this._notify();
            });
        }
        this._notify();
    }
    get currentPage() {
        return this._currentPage;
    }
    set currentPage(value) {
        this._currentPage = value;
        this._notify();
    }
    get currentParameters() {
        return this._currentParameters;
    }
    set currentParameters(value) {
        this._currentParameters = value;
        this._notify();
    }
    clearModule() {
        this._currentModule = undefined;
        this._notify();
    }
    clearPage() {
        this._currentPage = undefined;
        this._notify();
    }
    clearParameters() {
        this._currentParameters = undefined;
        this._notify();
    }
    clearProject() {
        this._currentProject = undefined;
        this._notify();
    }
    /**
     * Converts the current path to a url
     * e.g. /hub/projectId/IDMS/moduleInstanceIdIDMS/Users/Module1Page1Parameters
     */
    getPathAsUrl() {
        let path = this.getPathForNavigation();
        if (this.currentPage) {
            path += "/" + cleansePageName(this.currentPage.displayName);
        }
        if (this.currentParameters) {
            path += "&" + this.currentParameters.moduleInstanceId;
        }
        return path;
    }
    /**
     * Prepare the path for the navigation bar on the left
     */
    getPathForNavigation() {
        let path = "/#Hub";
        if (this.currentProject) {
            path += "/" + this.currentProject.moduleName;
            path += "/" + this.currentProject.moduleInstanceId;
        }
        if (this.currentModule) {
            path += "/" + this.currentModule.moduleName;
            path += "/" + this.currentModule.moduleInstanceId;
        }
        return path;
    }
    getStartPage() {
        let path = "/#Hub";
        return window.location.origin + path;
    }
    createProjectUrl() {
        return "/#Hub/" + this.currentProject.moduleName + "/" + this.currentProject.moduleInstanceId;
    }
    createHubUrl() {
        return "/#Hub";
    }
    /**
     * Creates the path tiles for the top of the page
     */
    createPathTiles() {
        var _a;
        let tiles = [];
        // Add Hub module
        const moduleHub = HubModule;
        tiles.push(React.createElement("span", { className: `${styles.pathTilesLink}`, onClick: () => switchModule(moduleHub.moduleName) },
            React.createElement("a", { id: `linkToHub`, className: styles.crumbLink, href: this.createHubUrl() }, "Hub")));
        if (this.currentProject) {
            const moduleProjectContent = ProjectContentModule;
            tiles.push(React.createElement("span", { className: `${styles.pathTiles}` }, "/"));
            tiles.push(React.createElement("span", { className: `${styles.pathTilesLink}`, onClick: () => {
                    var _a;
                    return switchModule(moduleProjectContent.moduleName, {
                        moduleInstanceId: this.currentProject.moduleInstanceId,
                        MiiDisplayname: (_a = this.currentProject.displayName) !== null && _a !== void 0 ? _a : undefined
                    });
                } },
                React.createElement("a", { id: "linkToProject", className: styles.crumbLink, href: this.createProjectUrl() }, (_a = this.currentProject.displayName) !== null && _a !== void 0 ? _a : "Project")));
        }
        if (this.currentModule) {
            tiles.push(React.createElement("span", { className: `${styles.pathTiles}` }, "/"));
            tiles.push(React.createElement("span", { className: `${styles.pathTilesLink}`, onClick: () => __awaiter(this, void 0, void 0, function* () {
                    return switchModule(this.currentModule.moduleName, {
                        moduleInstanceId: this.currentModule.moduleInstanceId,
                        MiiDisplayname: this.currentModule.displayName
                    });
                }) },
                React.createElement("a", { id: "linkToModule", href: this.getPathForNavigation() }, this.currentModule.displayName)));
        }
        if (this.currentPage) {
            tiles.push(React.createElement("span", { className: `${styles.pathTiles}` }, "/"));
            tiles.push(React.createElement("span", { className: `${styles.pathTilesLink}`, id: "linkToPage" }, this.currentPage.displayName));
        }
        // create a key for each element in tiles
        tiles = tiles.map((tile, index) => {
            return React.cloneElement(tile, { key: index });
        });
        this.setBrowserTabTitle();
        return tiles;
    }
    setBrowserTabTitle() {
        let projectName = "";
        if (this.currentProject && this.currentProject.displayName) {
            projectName = this.currentProject.displayName + " - ";
        }
        if (this.currentModule) {
            projectName += this.currentModule.displayName + " - ";
        }
        if (this.currentPage) {
            projectName += this.currentPage.displayName;
        }
        document.title = projectName;
    }
}
export const HubPathRouting = PathRouting.Instance;
export class Breadcrumbs extends React.Component {
    constructor(props) {
        super(props);
        this.updateBreadcrumbs = () => {
            this.setState({ breadcrumbs: HubPathRouting.createPathTiles() });
        };
        this.state = {
            breadcrumbs: HubPathRouting.createPathTiles()
        };
    }
    componentDidMount() {
        HubPathRouting.subscribe(this.updateBreadcrumbs);
    }
    componentWillUnmount() {
        HubPathRouting.unsubscribe(this.updateBreadcrumbs);
    }
    render() {
        return (React.createElement("div", { id: `breadcrumbs` }, this.state.breadcrumbs));
    }
}
export function switchModule(moduleName, options) {
    // const a_moduleInstanceId = options?.moduleInstanceId ?? '';
    // const a_MiiDisplayname = options?.MiiDisplayname ?? '';
    // const a_page = options?.page ?? '';
    // const a_linkedId = options?.linkedId ?? {id: '', type: ''};
    //say("switching module to " + moduleName + " ,mii:" + a_moduleInstanceId + " ,miiDisplayname:" + a_MiiDisplayname + " ,Page:" + a_page + " ," + a_linkedId + "..")
    var _a, _b, _c, _d, _e;
    let modules = getListOfAllModules();
    let module = modules[moduleName];
    if (module === undefined) {
        console.error("ERROR: module " + moduleName + " not found");
        // TODO: show error message popup
        return;
    }
    let moduleInfo = module.moduleContent;
    const moduleInstanceId = (_a = options === null || options === void 0 ? void 0 : options.moduleInstanceId) !== null && _a !== void 0 ? _a : 'moduleInstanceId';
    const MiiDisplayname = (_b = options === null || options === void 0 ? void 0 : options.MiiDisplayname) !== null && _b !== void 0 ? _b : moduleInstanceId;
    const page = (options === null || options === void 0 ? void 0 : options.page) &&
        ((_d = (_c = moduleInfo.pages.find(p => cleansePageName(p.displayName) === cleansePageName(options.page))) === null || _c === void 0 ? void 0 : _c.displayName) !== null && _d !== void 0 ? _d : moduleInfo.defaultPage);
    const linkedId = (_e = options === null || options === void 0 ? void 0 : options.linkedId) !== null && _e !== void 0 ? _e : { id: '', type: '' };
    // if the page of the current module is clicked, do nothing
    if (HubPathRouting.currentPage && HubPathRouting.currentPage.displayName === page) {
        return;
    }
    // Don't set the DevSecOps Hub and Project Content module as a module
    // Clear the modules if the Hub or Project Content module is clicked
    switch (moduleName) {
        // Hub Module
        case HubModule.moduleName:
            HubPathRouting.clearProject();
            HubPathRouting.clearModule();
            HubPathRouting.clearParameters();
            HubPathRouting.clearPage();
            break;
        // Project layer
        case ProjectContentModule.moduleName:
            HubPathRouting.clearModule();
            HubPathRouting.clearParameters();
            HubPathRouting.clearPage();
            // if Project Content is already loaded, do not reload it again
            if (HubPathRouting.currentModule) {
                HubPathRouting.currentProject = {
                    moduleInstanceId: moduleInstanceId,
                    moduleName: moduleInfo.moduleName
                };
            }
            break;
        // Module layer
        default:
            say("module layer");
            let currentModuleDisplayName = undefined;
            // if the module stays the same, but the page changes, update the page
            if (HubPathRouting.currentModule && HubPathRouting.currentModule.moduleName === moduleName) {
                currentModuleDisplayName = HubPathRouting.currentModule.displayName;
            }
            HubPathRouting.currentModule = {
                moduleInstanceId: moduleInstanceId,
                displayName: currentModuleDisplayName,
                moduleName: moduleInfo.moduleName
            };
            // if page is not set, clear it
            if ((options && options.page === undefined) || options === undefined) {
                HubPathRouting.clearPage();
            }
    }
    if (options && options.page) {
        HubPathRouting.currentPage = {
            //moduleInstanceId: moduleInstanceId,
            displayName: page,
            moduleName: moduleInfo.moduleName
        };
    }
    HubPathRouting.getPathAsUrl();
    if (Object.keys(modules).indexOf(moduleName) !== -1) {
        let hubArgs = {
            moduleName: moduleName,
            idName: moduleInstanceId,
            displayName: MiiDisplayname,
            page: page,
            linkedId: linkedId
        };
        let attributes = { hubArgs: hubArgs };
        let module = React.createElement(modules[moduleName].Module, attributes);
        showRootComponent(module);
    }
    else {
        this.setState({ Popup: Popup.Error, PopupMessage: "Module not found. Please look into module config." });
    }
}
