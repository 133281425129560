import { fetchFromApi, getBackendApiURL } from "./backendCalls";
/**
 * List all module instances of a project
 * @param projectId
 */
export function listModuleInstances(projectId) {
    const url = getBackendApiURL() + "projects/" + projectId + "/modules";
    return fetchFromApi(url, "GET");
}
/**
 * Get information about a module instance
 * @param projectId
 * @param moduleInstanceId
 */
export function getModuleInstanceInformation(projectId, moduleInstanceId) {
    const url = getBackendApiURL() + "projects/" + projectId + "/modules/" + moduleInstanceId;
    return fetchFromApi(url, "GET");
}
export function listStaticModules() {
    const url = getBackendApiURL() + "static/modules";
    return fetchFromApi(url, "GET");
}
