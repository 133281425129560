import * as React from "react";
import { Button } from "react-bootstrap";
import HubFunctions from "../../../HubFramework/hubFunctions";
export class Start extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: ""
        };
    }
    componentDidMount() {
    }
    render() {
        HubFunctions.PopupCreate("Hello", "this is a popup message");
        return React.createElement(React.Fragment, null,
            React.createElement("p", null, " This is a template page."),
            React.createElement(Button, { onClick: () => HubFunctions.showLoading() }, "Show Loading"),
            React.createElement("br", null));
    }
}
