import * as React from "react";
import "@uib/css/themes/draeger-theme-bright.css";
import { HubFramework } from "HubFramework";
import { moduleContent } from "./moduleContent";
export class Module extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // display name
            name: props.hubArgs.displayName,
            // module instance id
            id: props.hubArgs.idName
        };
    }
    setDisplayName(name) {
        this.setState({ name: name });
    }
    render() {
        return React.createElement(HubFramework, { hubContent: moduleContent, module: this });
    }
}
