import * as React from "react";
import { KafkaEventsPage } from "../../DAMS/Tabs/KafkaEvents";
import { Module as KafkaModule } from "../../DAMS/module";
/**
 * This is the Logs tab in the IDMS module. It is a wrapper around the KafkaEventsPage from the DAMS module.
 */
export class Logs extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return React.createElement(KafkaEventsPage, { parentModule: new KafkaModule(null) });
    }
}
