import * as React from "react";
import { moduleContent } from "../moduleContent";
import { Documentation } from "../../Documentation/Documentation";
export class IDMSDocumentation extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return React.createElement(React.Fragment, null,
            React.createElement(Documentation, { component: moduleContent.moduleName }));
    }
}
