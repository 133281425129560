import * as React from "react";
import styles from '../../Components/Hub/Hub.module.css';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { GlobalDictionary } from "../globalDictionary";
import { PROFILEIMAGE } from "./ProfileImageApi";
export class ProfilImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contact: this.props.contact,
            image: undefined
        };
    }
    componentDidMount() {
        GlobalDictionary.subscribe(() => this.getImage(PROFILEIMAGE + this.props.contact.mail));
    }
    componentWillUnmount() {
        GlobalDictionary.unsubscribe(() => this.getImage(PROFILEIMAGE + this.props.contact.mail));
    }
    getImage(key) {
        const image = GlobalDictionary.get(key);
        this.setState({ image: image });
        GlobalDictionary.unsubscribe(() => this.getImage(PROFILEIMAGE + this.props.contact.mail));
    }
    render() {
        let image = React.createElement("div", { className: `${styles.roundedImage} ${styles.greybackground}` });
        if (this.state.image) {
            const imageSrc = `data:image/jpeg;base64,${this.state.image}`;
            image = React.createElement("img", { src: imageSrc, alt: "Profile Image", className: styles.roundedImage });
        }
        return React.createElement(OverlayTrigger, { placement: "bottom", delay: { show: 1, hide: 10 }, overlay: React.createElement(Tooltip, { id: "tooltip-key" },
                this.props.contact.mail,
                " - ",
                this.props.contact.job) }, image);
    }
}
