var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { listStaticModules } from "../BackendFacade/moduleCalls";
import { listProjects } from "../Components/Hub/BackendFacade/apiCalls";
import HubFunctions from "../HubFramework/hubFunctions";
class globalDictionary {
    constructor() {
        this.MODULEINSTANCES = "moduleInstances";
        this.PROJECTS = "projects";
        this.STATICMODULES = "staticModules";
        this.observers = [];
        this.dictionary = {};
    }
    static get Instance() {
        // Do you need arguments? Make it a regular static method instead.
        return this._instance || (this._instance = new this());
    }
    subscribe(observer) {
        this.observers.push(observer);
    }
    unsubscribe(observer) {
        this.observers = this.observers.filter(obs => obs !== observer);
    }
    notify() {
        this.observers.forEach(observer => observer());
    }
    set(key, value) {
        this.dictionary[key] = value;
        this.notify();
    }
    get(key) {
        return this.dictionary[key];
    }
    remove(key) {
        delete this.dictionary[key];
    }
    getStaticModules() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.dictionary["staticModules"] == undefined) {
                const data = yield listStaticModules();
                if (!data.success) {
                    HubFunctions.PopupCreate("Error", data.message);
                    return [];
                }
                this.dictionary["staticModules"] = data.data.value;
                return data.data.value;
            }
            return this.dictionary["staticModules"];
        });
    }
    getProjects() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.dictionary["projects"] == undefined) {
                const data = yield listProjects();
                this.dictionary["projects"] = data.data.value;
                return data.data.value;
            }
            return this.dictionary["projects"];
        });
    }
    setProjects(value) {
        this.dictionary["projects"] = value;
    }
}
export const GlobalDictionary = globalDictionary.Instance;
