var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { HubPathRouting } from "../../../HubFramework/pathBuilder";
import { GlobalDictionary } from "../../../Utils/globalDictionary";
import { IApiKeyContentTypes, Popup, SearchByApiKeys } from "../Interfaces/IApiKeyManagement";
import { deleteApiKeys, getApiKeys } from "../BackendFacade/apiCalls";
import { Button, Form, FormControl, Modal, Table } from "react-bootstrap";
import styles from "../Hub.module.css";
import { isUserroleSufficient } from "../../../Utils/authorization";
import { UserRoles } from "../../../Utils";
import { IconDelete, IconEdit } from "../../../Assets/svgs";
import { deepCopy, formatDate, replaceProjectGuidWithDisplayName } from "../../../Utils/transformer";
import { convertAuthlevelToTenantsystem, GlobalTenantSystemContent, TenantSystemContent, } from "../../IDMS/Utils/tenantSystem";
import ApiKeyField from "../Components/ApiKeyField";
import { IApiKeysEditMode } from "../Interfaces/IApiKeysAddEditModal";
import { ApiKeys_AddEditModal } from "./Modals/ApiKeys_AddEdit";
import HubFunctions from "../../../HubFramework/hubFunctions";
export class ApiKeyManagement extends React.Component {
    constructor(props) {
        super(props);
        // get project id if called from within project, "null" if called from within hub
        let projectId = "null";
        if (HubPathRouting.currentProject) {
            projectId = HubPathRouting.currentProject.moduleInstanceId;
        }
        // load all module instances for scope replacement
        GlobalDictionary.getProjects();
        this.state = {
            apiKeys: [],
            Sort_Key: "displayName",
            Sort_Reverse: false,
            filterBy: SearchByApiKeys.Include,
            filterState: {},
            filterValueState: {},
            selectedApiKey: undefined,
            Popup: Popup.None,
            // AddEditModal: undefined,
            currentProject: projectId,
            showKey1: false,
            showKey2: false,
            AddEditModal: undefined
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!GlobalDictionary.get("apiKeys" + this.state.currentProject)) {
                yield this.getApiKeysWait();
            }
            else {
                this.getApiKeys();
            }
        });
    }
    getApiKeysWait() {
        return __awaiter(this, void 0, void 0, function* () {
            HubFunctions.showLoading();
            yield this.getApiKeys();
        });
    }
    /**
     * Get the config data from the backend
     */
    getApiKeys() {
        return __awaiter(this, void 0, void 0, function* () {
            getApiKeys(this.state.currentProject).then((data) => {
                if (data.success) {
                    GlobalDictionary.set("apiKeys" + this.state.currentProject, data.data.value);
                    this.setState({ apiKeys: data.data.value });
                }
                else {
                    HubFunctions.PopupCreate("Error", "Error getting api Keys: " + data.message);
                }
                HubFunctions.hideLoading();
            });
        });
    }
    setSortByKey(key) {
        this.setState({ Sort_Reverse: !this.state.Sort_Reverse, Sort_Key: key });
    }
    showSortIcon(key) {
        if (key === this.state.Sort_Key) {
            return this.state.Sort_Reverse ? React.createElement("i", { className: "uib-icon--arrow1-down uib-icon" }) : React.createElement("i", { className: "uib-icon--arrow1-up uib-icon" });
        }
    }
    sort() {
        return this.sortListByKey(this.state.apiKeys, this.state.Sort_Key, this.state.Sort_Reverse);
    }
    /**
     * Displays the details of the tenant config
     * @param key
     * @constructor
     */
    Details_show(key) {
        let content;
        if (key.contentType === IApiKeyContentTypes.hubIdmsTenantJson) {
            const authLevel = JSON.parse(key.content);
            const tenantSystem = convertAuthlevelToTenantsystem(authLevel, this.state.currentProject);
            let globalId = tenantSystem.find(x => x.scopes.length === 1 && x.scopes.every((value, index) => value === ["user"][index])).id;
            content = React.createElement(React.Fragment, null,
                React.createElement(GlobalTenantSystemContent, { tenantSystem: tenantSystem, globalId: globalId }),
                React.createElement(TenantSystemContent, { tenantSystem: tenantSystem, global: false }));
        }
        else {
            content = React.createElement(FormControl, { disabled: true, id: "content", className: styles.ApiKeyContentUndefined, value: key.content });
        }
        const popupContent = React.createElement("div", null,
            React.createElement("div", { id: "contentType", className: styles.ApiKeyContentType },
                "Content Type: ",
                key.contentType),
            React.createElement("div", { id: "content" }, content),
            React.createElement("div", { className: styles.ApiKeyHeader }, "Key 1"),
            React.createElement("div", null,
                React.createElement(ApiKeyField, { keyString: key.primaryApiKey })),
            React.createElement("div", { className: styles.ApiKeyHeader }, "Key 2"),
            React.createElement("div", null,
                React.createElement(ApiKeyField, { keyString: key.secondaryApiKey })));
        HubFunctions.PopupCreateSingle(`Api Key ${key.displayName} Details`, popupContent);
    }
    /**
     * sort the users list by clicking on the column header
     * @param list
     * @param key
     * @param reverse
     */
    sortListByKey(list, key, reverse = false) {
        if (list.length === 0) {
            return [];
        }
        let apiKeysCopy = deepCopy(list);
        // apply filters
        //listofKeys = this.applyFilter(list, listofKeys)
        // if first item of list is a number
        switch (key) {
            case "lastLogin":
                apiKeysCopy.sort((a, b) => {
                    // if lastlogin is undefined, sort it to the end
                    if (list[a].lastUsedOn === undefined) {
                        return 1;
                    }
                    if (list[b].lastUsedOn === undefined) {
                        return -1;
                    }
                    if (list[a].lastUsedOn < list[b].lastUsedOn) {
                        return 1;
                    }
                    if (list[a].lastUsedOn > list[b].lastUsedOn) {
                        return -1;
                    }
                    return 0;
                });
                break;
            default:
                break;
        }
        if (reverse) {
            apiKeysCopy.reverse();
        }
        return apiKeysCopy;
    }
    /**
     * prepare user add/edit popup config
     * @param key key of the user, empty for a new user
     */
    EditEntry_show(key) {
        let AddEdit = {
            editMode: undefined,
            tenantSystem: undefined,
            Selected_Value: key,
            listOfSubscopes: [], // TODO: add subscopes
            expirationDate: false,
        };
        // if a new api key shall be created
        if (key === undefined) {
            AddEdit.editMode = IApiKeysEditMode.Add;
            AddEdit.Selected_Value = {
                displayName: "",
                secondaryApiKey: "",
                primaryApiKey: "",
                projectId: this.state.currentProject,
                apiKeyId: "",
                content: "",
                contentType: IApiKeyContentTypes.hubIdmsTenantJson,
                lastUsedOn: "",
                validUntil: "",
            };
            AddEdit.tenantSystem = [{ id: 0, scopes: ["user"], value: "", tmp: "", fullscope: "user" }];
        }
        else {
            AddEdit.editMode = IApiKeysEditMode.Edit;
            AddEdit.Selected_Value = deepCopy(key);
            if (key.validUntil !== null && key.validUntil !== "") {
                AddEdit.expirationDate = true;
            }
            // if contentType is from the hub, convert it to the tenant system
            if (AddEdit.Selected_Value.contentType === IApiKeyContentTypes.hubIdmsTenantJson) {
                const authLevel = JSON.parse(AddEdit.Selected_Value.content);
                AddEdit.tenantSystem = convertAuthlevelToTenantsystem(authLevel, this.state.currentProject);
            }
            else {
                AddEdit.tenantSystem = undefined;
            }
        }
        this.setState({ Popup: Popup.ApiKey_AddEdit, AddEditModal: AddEdit });
    }
    /**
     * Activate popup for delete config entry confirmation
     * @param key key of the config entry
     */
    Delete_show(key) {
        HubFunctions.PopupCreate("Delete Api Key", `Do you really want to delete ${key.displayName}?`, this.Delete_apply.bind(this, key));
    }
    /**
     * Confirm api key deletion
     */
    Delete_apply(key) {
        HubFunctions.showLoading();
        deleteApiKeys(key.projectId, key.apiKeyId).then((data) => __awaiter(this, void 0, void 0, function* () {
            if (!data.success) {
                HubFunctions.hideLoading();
                HubFunctions.PopupCreate("Error", data.message);
            }
            else {
                yield this.getApiKeysWait();
            }
        }));
    }
    closePopup() {
        this.setState({ Popup: Popup.None });
    }
    render() {
        var _a;
        let popupContent = React.createElement("div", null);
        switch (this.state.Popup) {
            case Popup.ApiKey_AddEdit:
                popupContent = React.createElement(ApiKeys_AddEditModal, { state: this.state.AddEditModal, projectId: this.state.currentProject, onSuccessFunction: () => { this.closePopup(); this.getApiKeysWait(); }, closeFunction: () => this.closePopup() });
                break;
            default:
                break;
        }
        return React.createElement(React.Fragment, null,
            React.createElement(Modal, { size: "lg", show: this.state.Popup !== Popup.None, onHide: () => this.setState({ Popup: Popup.None }) }, popupContent),
            React.createElement("div", { className: styles.ScrollPageUsers },
                React.createElement(Form, null,
                    React.createElement(Table, { striped: true, className: "", id: "tableOfUsers" },
                        React.createElement("thead", { className: `uib-table__header ${styles.tablehead}` },
                            React.createElement("tr", { className: `uib-table__row--header ${styles.tableHeadRow}` },
                                React.createElement("th", { className: styles.hoverPointer, onClick: () => this.setSortByKey("displayName") },
                                    "Display Name",
                                    this.showSortIcon("displayName")),
                                !HubPathRouting.currentProject &&
                                    React.createElement("th", { className: `${styles.hoverPointer}`, onClick: () => this.setSortByKey("projectId") },
                                        "Project Id",
                                        this.showSortIcon("projectId")),
                                React.createElement("th", { className: `${styles.hoverPointer} ${styles.alignCenter}`, onClick: () => this.setSortByKey("lastUsedOn") },
                                    "Last Used On",
                                    this.showSortIcon("lastUsedOn")),
                                React.createElement("th", { className: `${styles.hoverPointer} ${styles.alignCenter}`, onClick: () => this.setSortByKey("validUntil") },
                                    "Valid Until",
                                    this.showSortIcon("validUntil")),
                                React.createElement("th", { className: `${styles.hoverPointer} ${styles.alignCenter}` }, "Details"),
                                React.createElement("th", { className: styles.alignCenter }, "Edit"),
                                React.createElement("th", { className: styles.alignCenter }, "Delete"))),
                        React.createElement("tbody", null, this.sort()
                            .map((apiKey, index) => {
                            var _a, _b;
                            return React.createElement("tr", { key: index },
                                React.createElement("td", null, apiKey.displayName),
                                !HubPathRouting.currentProject &&
                                    React.createElement("td", null, replaceProjectGuidWithDisplayName(apiKey.projectId)),
                                React.createElement("td", { className: styles.alignCenter }, (_a = formatDate(apiKey.lastUsedOn)) !== null && _a !== void 0 ? _a : "Never"),
                                React.createElement("td", { className: styles.alignCenter }, (_b = formatDate(apiKey.validUntil)) !== null && _b !== void 0 ? _b : "-"),
                                React.createElement("td", { className: styles.alignCenter },
                                    React.createElement(Button, { variant: "outline-primary", className: styles.details, id: "details", onClick: () => this.Details_show(apiKey) }, "Details")),
                                React.createElement("td", { className: styles.alignCenter },
                                    React.createElement(Button, { variant: "outline-primary", className: styles.ButtonLogo, id: "edit", onClick: () => {
                                            this.EditEntry_show(apiKey);
                                        } }, IconEdit)),
                                React.createElement("td", { className: styles.alignCenter },
                                    React.createElement(Button, { variant: "outline-danger", className: styles.ButtonLogo, id: "delete", onClick: () => {
                                            this.Delete_show(apiKey);
                                        } }, IconDelete)));
                        }))))),
            React.createElement("div", { className: `container ${styles.FooterRoot}` },
                React.createElement("div", { className: "row" },
                    isUserroleSufficient(UserRoles.admin, (_a = HubPathRouting.currentProject) === null || _a === void 0 ? void 0 : _a.moduleInstanceId) &&
                        React.createElement("div", { className: "col-auto" },
                            React.createElement(Button, { className: "uib-button uib-button--primary", id: "add", onClick: () => this.EditEntry_show(undefined) }, "+ Add")),
                    React.createElement("div", { className: `col-sm ${styles.FooterUser}`, id: "noOfKeys" },
                        "Api Keys Count: ",
                        this.state.apiKeys.length))));
    }
}
