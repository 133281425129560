// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ApiKeyField-module__inputContainer____h7JK {
    position: relative;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.ApiKeyField-module__keyInput___SwrSx {
    padding-right: 50px; /* adjust as needed */
}


.ApiKeyField-module__spaceLeft___em83t {
    margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Hub/Components/ApiKeyField.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,mBAAmB,EAAE,qBAAqB;AAC9C;;;AAGA;IACI,iBAAiB;AACrB","sourcesContent":[".inputContainer {\r\n    position: relative;\r\n    display: flex;\r\n    width: 100%;\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.keyInput {\r\n    padding-right: 50px; /* adjust as needed */\r\n}\r\n\r\n\r\n.spaceLeft {\r\n    margin-left: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": `ApiKeyField-module__inputContainer____h7JK`,
	"keyInput": `ApiKeyField-module__keyInput___SwrSx`,
	"spaceLeft": `ApiKeyField-module__spaceLeft___em83t`
};
export default ___CSS_LOADER_EXPORT___;
