import { EditProject } from "./Tabs/EditProject";
import { EditUsers } from "./Tabs/EditUsers";
import { Overview } from "./Tabs/Overview";
import { DOOPDocumentation } from "./Tabs/DOOPDocumentation";
import { Logs } from "./Tabs/Logs";
/**
 * The content of the module
 * This is mandatory for every module. It contains all the information about the module.
 */
export const moduleContent = {
    moduleName: "doopProd",
    title: "DOOP",
    pages: [
        {
            displayName: "Orders",
            contentPage: Overview
        },
        {
            displayName: "Configuration",
            contentPage: EditProject
        },
        {
            displayName: "User Management",
            contentPage: EditUsers
        },
        {
            displayName: "Logs",
            contentPage: Logs
        },
        {
            displayName: "Documentation",
            contentPage: DOOPDocumentation
        }
    ],
    creationProps: [
        {
            displayName: "Order Number",
            name: "DefaultOrderNumber",
            type: "string",
            description: "The default order number",
            value: "",
        },
        {
            displayName: "Default Price Model",
            name: "DefaultPriceModel",
            type: "string",
            description: "The default price model",
            value: "",
        },
    ],
    defaultPage: "Orders",
};
