var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { fetchFromApi, getBackendApiURLModuleInstance } from "../../../BackendFacade";
import { DirectoryType } from "../Interfaces/IFiles";
import { AutomationConfig, Blob1080URL, BlobDocuments, BlobOriginalURL, listDirectoryContent, fileDetails, Upload } from "../constants";
import { replaceDoubleSlashesWithSingleSlash } from "../../../Utils/transformer";
/**
 * @param path Expects the following structure: [projectname
 * @param type
 */
export function getDirContentNames(path, type) {
    // adjust the type for the backend
    type = type.replace("Images", "image")
        .replace("Documents", "document")
        .replace("Secret Files", "secret");
    let url = getBackendApiURLModuleInstance() + listDirectoryContent + "?path=" + path + "&type=" + type;
    return fetchFromApi(url, "GET");
}
export function getAutomationConfig(project) {
    let url = getBackendApiURLModuleInstance() + AutomationConfig;
    return fetchFromApi(url, "GET");
}
export function setAutomationConfig(project, body) {
    let url = getBackendApiURLModuleInstance() + AutomationConfig;
    return fetchFromApi(url, "PUT", body);
}
/**
 * returns NetworkData with data-type: IFileDetails
 * @param path relative path to file
 * @param type Constants.FolderImage or Constants.FolderDocuments
 */
export function getFileAttributesSingle(path, type) {
    return __awaiter(this, void 0, void 0, function* () {
        path = path.replace(" ", "%20");
        path = replaceDoubleSlashesWithSingleSlash(path);
        let ret;
        const url = getBackendApiURLModuleInstance() + fileDetails;
        let fullPath = "";
        if (type == DirectoryType.FolderImage) {
            fullPath = BlobOriginalURL + path;
            let subUrl = url + "?type=image&fileUrl=" + fullPath;
            ret = yield fetchFromApi(subUrl, "GET");
            // if the image does not exist on original, try 1080px folder instead
            if (!ret.success && ret.message.includes("400")) {
                fullPath = Blob1080URL + path;
                subUrl = url + "?type=image&fileUrl=" + fullPath;
                ret = yield fetchFromApi(subUrl, "GET");
            }
        }
        else {
            fullPath = BlobDocuments + path;
            const subUrl = url + "?type=document&fileUrl=" + fullPath;
            ret = yield fetchFromApi(subUrl, "GET");
        }
        ret.data.requestedUrl = fullPath;
        ret.data.fileName = path;
        return ret;
    });
}
export function UploadFile(projname, directoryPath, file, containerType) {
    containerType = containerType.replace("Images", "image")
        .replace("Documents", "document")
        .replace("Secret Files", "secret");
    let url = getBackendApiURLModuleInstance() + Upload;
    let body = {
        path: directoryPath + file.name,
        type: containerType,
        project: projname
    };
    return fetchFromApi(url, "post", body, "application/json", file);
}
export function deleteFile(filePath, containerType) {
    containerType = containerType.replace("Images", "image")
        .replace("Documents", "document")
        .replace("Secret Files", "secret");
    const url = getBackendApiURLModuleInstance() + `files?filePath=${filePath}&type=${containerType}`;
    return fetchFromApi(url, "DELETE");
}
export function downloadFile(filePath) {
    const url = getBackendApiURLModuleInstance() + `downloadFile?filePath=${filePath}`;
    return fetchFromApi(url, "GET");
}
