var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import "@uib/css/themes/draeger-theme-bright.css";
import { HubFramework } from "HubFramework";
import { moduleContent } from "./moduleContent";
import { getConfigDataCall } from "./BackendFacade/apiCalls";
import { HubPathRouting } from "../../HubFramework/pathBuilder";
import HubFunctions from "../../HubFramework/hubFunctions";
export class Module extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            UserSettings: {},
            AuthLevelChanges: [],
            AppConfig: {},
            AuthLevels: [],
            projectID: HubPathRouting.currentProject.moduleInstanceId,
            moduleInstanceId: HubPathRouting.currentModule.moduleInstanceId,
        };
    }
    render() {
        return React.createElement(HubFramework, { hubContent: moduleContent, module: this });
    }
    /**
    * Get the config data from the backend
    */
    getConfigData() {
        return __awaiter(this, void 0, void 0, function* () {
            yield getConfigDataCall(this.state.projectID, this.state.moduleInstanceId).then((data) => {
                if (!data.success) {
                    HubFunctions.PopupCreateSingle("Error", data.message);
                }
                else {
                    let appConfig = data.data.value;
                    this.setState({
                        AppConfig: appConfig,
                        AuthLevelChanges: this.createCombinationOfAllAuthLevels(appConfig.authLevels.split(",")),
                        AuthLevels: appConfig.authLevels.split(","),
                    });
                }
            });
            // timeout to make sure the state is set before the next function is called
            yield new Promise(r => setTimeout(r, 1));
        });
    }
    /**
     * Create a list of all AuthLevelChangeuser-to combinations
     * @param authlevels
     */
    createCombinationOfAllAuthLevels(authlevels) {
        let authLevelsCombinations = ["AppDisplayName",
            "defaultAuth",
            "authLevels",
            "groupAuthLevels",
            "userTenant",
            "customDefaultAuthRules",
            "SignUpGroups",
            "SignUpGroups_OnPrem",
            "OverwriteWithGroups",
            "DisableTenantManagement",
            "SignUpWithExceptionAuth",
            "SignUpWithDefaultAuth",
            "SignUpEventAPI"];
        for (let i = 0; i < authlevels.length; i++) {
            for (let j = 0; j < authlevels.length; j++) {
                authLevelsCombinations.push("AuthLevelChange" + authlevels[i] + "To" + authlevels[j]);
            }
            authLevelsCombinations.push("AuthLevelChange" + authlevels[i] + "ToAny");
            authLevelsCombinations.push("AuthLevelChangeAnyTo" + authlevels[i]);
        }
        authLevelsCombinations.push("AuthLevelChangeAnyToAny");
        return authLevelsCombinations;
    }
}
