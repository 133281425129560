var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import "@uib/css/themes/draeger-theme-bright.css";
import { HubFramework } from "HubFramework";
import { moduleContent } from "./moduleContent";
import { HubPathRouting } from "../../HubFramework/pathBuilder";
import { getModuleInstanceInformation } from "../../BackendFacade/moduleCalls";
export class Module extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projectId: this.props.hubArgs.linkedId.projectId,
        };
    }
    /**
     * Get the project id of the current module
     */
    getProjectId() {
        return __awaiter(this, void 0, void 0, function* () {
            const projectId = HubPathRouting.currentProject.moduleInstanceId;
            const moduleId = HubPathRouting.currentModule.moduleInstanceId;
            return yield getModuleInstanceInformation(projectId, moduleId).then((data) => {
                if (data.success) {
                    let module = data.data.value;
                    return { success: true, projectId: module.linkedId.projectId };
                }
                else {
                    return { success: false, projectId: "", message: data.message };
                }
            });
        });
    }
    render() {
        return React.createElement(HubFramework, { hubContent: moduleContent, module: this });
    }
}
