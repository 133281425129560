var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { HubPathRouting } from "../../../HubFramework/pathBuilder";
import { listModuleInstances, listStaticModules } from "../../../BackendFacade/moduleCalls";
import { KafkaEventsPage } from "../../DAMS/Tabs/KafkaEvents";
import HubFunctions from "../../../HubFramework/hubFunctions";
export class Logs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            kafkaAvailable: false,
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            HubFunctions.showLoading();
            if (yield this.evaluateDamsAvailablity()) {
                this.setState({ kafkaAvailable: true });
            }
            HubFunctions.hideLoading();
        });
    }
    evaluateDamsAvailablity() {
        return __awaiter(this, void 0, void 0, function* () {
            // get all static modules for the id of eventLogTopicName
            let staticModules = [];
            let prom1 = listStaticModules().then((data) => {
                if (data.success) {
                    staticModules = data.data.value;
                }
            });
            // get all module instances for the linked id of kafkaTopic
            let moduleInstances = [];
            let prom2 = listModuleInstances(HubPathRouting.currentProject.moduleInstanceId).then((data) => {
                if (data.success) {
                    moduleInstances = data.data.value;
                }
            });
            yield Promise.all([prom1, prom2]);
            // find the intersection of both
            return moduleInstances.some((module) => {
                const staticModule = staticModules.find((staticModule) => staticModule.staticModuleId === module.staticModuleId);
                return !!staticModule.eventLogTopicName;
            });
        });
    }
    render() {
        return React.createElement(React.Fragment, null, this.state.kafkaAvailable ?
            React.createElement(KafkaEventsPage, { parentModule: undefined })
            :
                React.createElement("div", null, "no logs available"));
    }
}
