import * as React from "react";
import { ApiKeyManagement } from "../../Hub/Tabs/ApiKeyManagement";
export class ProjectApiKeyManagement extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return React.createElement(ApiKeyManagement, { parentModule: this.props.parentModule });
    }
}
