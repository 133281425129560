import { fetchFromApi, getBackendApiURL } from "../../../BackendFacade";
export function setProjectMeta(projectId, moduleInstanceId, bodyString) {
    let url = getBackendApiURL() + "projects/" + projectId + "/modules/" + moduleInstanceId + "/meta";
    return fetchFromApi(url, "POST", bodyString);
}
export function getProjectMeta(projectId, moduleInstanceId) {
    let url = getBackendApiURL() + "projects/" + projectId + "/modules/" + moduleInstanceId + "/meta";
    return fetchFromApi(url, "GET");
}
export function getProjectUsersData(projectId, moduleInstanceId) {
    let url = getBackendApiURL() + "projects/" + projectId + "/modules/" + moduleInstanceId + "/users";
    return fetchFromApi(url, "GET");
}
export function getProjectMetaDataAreas(projectId, moduleInstanceId) {
    let url = getBackendApiURL() + "projects/" + projectId + "/modules/" + moduleInstanceId + "/areas";
    return fetchFromApi(url, "GET");
}
export function sendUserData(user, projectName, moduleInstanceId) {
    let url = getBackendApiURL() + "projects/" + projectName + "/modules/" + moduleInstanceId + "/users";
    let bodyString = { usersToUpdate: [user] };
    return fetchFromApi(url, "POST", bodyString);
}
export function sendUserDelete(user, projectId, moduleInstanceId) {
    let url = getBackendApiURL() + "projects/" + projectId + "/modules/" + moduleInstanceId + "/users";
    let bodyString = { usersToDelete: [user] };
    return fetchFromApi(url, "DELETE", bodyString);
}
export function getOrderedItemsForProject(projectId, moduleInstanceId) {
    let url = getBackendApiURL() + "projects/" + projectId + "/modules/" + moduleInstanceId + "/orders";
    return fetchFromApi(url, "GET");
}
