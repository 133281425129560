import { moduleContent as DOOP } from "../Components/DOOP";
import { moduleContent as DOOPDev } from "../Components/DOOPDev";
import { moduleContent as DPAS } from "../Components/DPAS";
import { moduleContent as IDMS } from "../Components/IDMS";
import { moduleContent as IDMSDev } from "../Components/IDMSDev";
export const getListOfAllModules = function () {
    let context = require.context("Components/", true, /index\.ts$/);
    let obj = {};
    context.keys().forEach(function (key) {
        let name = context(key).moduleContent.moduleName;
        obj[name] = context(key);
    });
    return obj;
};
/**
 * Returns true if the module is not an internal module
 * @param module module instance
 */
export const isExternalModule = function (module) {
    const internalModules = [DOOP.moduleName, DPAS.moduleName, IDMS.moduleName, IDMSDev.moduleName, DOOPDev.moduleName];
    return !internalModules.includes(module.staticModuleId);
};
