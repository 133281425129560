// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tenantSystem-module__scopeLine___n9nBQ {
    margin-bottom: 10px;
}

.tenantSystem-module__scopeRoot___BDBD3{
    width: 73px !important;
}

.tenantSystem-module__tenantValue___ehki3 {
    width: 130px !important;
    flex: none !important;
}

.tenantSystem-module__fixHeight___ZfAIM {
    height: 33.5px;
    margin-top: 1px;
}

.tenantSystem-module__tenantValueGlobal___jAeEH {
    max-width: 169px;
}

.tenantSystem-module__warningButton___CF3nR {
    background-color: orange !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/IDMS/Utils/tenantSystem.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,uBAAuB;IACvB,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mCAAmC;AACvC","sourcesContent":[".scopeLine {\r\n    margin-bottom: 10px;\r\n}\r\n\r\n.scopeRoot{\r\n    width: 73px !important;\r\n}\r\n\r\n.tenantValue {\r\n    width: 130px !important;\r\n    flex: none !important;\r\n}\r\n\r\n.fixHeight {\r\n    height: 33.5px;\r\n    margin-top: 1px;\r\n}\r\n\r\n.tenantValueGlobal {\r\n    max-width: 169px;\r\n}\r\n\r\n.warningButton {\r\n    background-color: orange !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scopeLine": `tenantSystem-module__scopeLine___n9nBQ`,
	"scopeRoot": `tenantSystem-module__scopeRoot___BDBD3`,
	"tenantValue": `tenantSystem-module__tenantValue___ehki3`,
	"fixHeight": `tenantSystem-module__fixHeight___ZfAIM`,
	"tenantValueGlobal": `tenantSystem-module__tenantValueGlobal___jAeEH`,
	"warningButton": `tenantSystem-module__warningButton___CF3nR`
};
export default ___CSS_LOADER_EXPORT___;
