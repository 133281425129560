import * as React from "react";
import { getDocumentation } from "./BackendFacade/apiCalls";
import docuStyles from "./Documentation.module.css";
import ReactMarkdown from "react-markdown";
import { GlobalDictionary } from "../../Utils/globalDictionary";
import HubFunctions from "../../HubFramework/hubFunctions";
import remarkGfm from "remark-gfm";
export class Documentation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: "",
        };
    }
    componentDidMount() {
        this.getDocumentation();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.component !== this.props.component) {
            this.getDocumentation();
        }
    }
    getDocumentation() {
        if (!this.props.component) {
            return;
        }
        if (GlobalDictionary.get("Docu" + this.props.component)) {
            this.setState({ content: GlobalDictionary.get("Docu" + this.props.component) });
            return;
        }
        HubFunctions.showLoading();
        getDocumentation(this.props.component).then((response) => {
            if (response.success) {
                this.setState({ content: response.data.value });
                // if there is a & in the content, show it in console.log but with leading and trailing letters
                if (response.data.value.indexOf("&") !== -1) {
                    // show the 5 letters before the & and 5 after the & in console.log
                    console.log(response.data.value.substring(response.data.value.indexOf("&") - 5, response.data.value.indexOf("&") + 5));
                }
                GlobalDictionary.set("Docu" + this.props.component, response.data.value);
            }
            else {
                HubFunctions.PopupCreate("Error", response.message);
            }
            HubFunctions.hideLoading();
        });
    }
    customLink(href, children) {
        const url = href.replace(/&amp;/g, '&');
        return React.createElement("a", { href: url, target: "_blank" }, children);
    }
    render() {
        if (this.state.content.indexOf("&") !== -1) {
            // show the 5 letters before the & and 5 after the & in console.log
            console.log(this.state.content.substring(this.state.content.indexOf("&") - 5, this.state.content.indexOf("&") + 5));
        }
        return React.createElement(React.Fragment, null,
            React.createElement("div", { className: docuStyles.DocuText },
                React.createElement(ReactMarkdown, { children: this.state.content, remarkPlugins: [remarkGfm], components: { a: props => {
                            return this.customLink(props.href, props.children);
                        } } })));
    }
}
