var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { InputGroup, Table } from "react-bootstrap";
import { getListOfAllModules } from "../../../Utils/modules";
import { listModuleInstances, listProjects } from "../BackendFacade/apiCalls";
import { GlobalDictionary } from "../../../Utils/globalDictionary";
import HubFunctions from "../../../HubFramework/hubFunctions";
const LISTOFPROJECTS = "listOfProjects";
export class IdFinder extends React.Component {
    constructor(props) {
        super(props);
        this.listOfModules = getListOfAllModules();
        this.state = {
            listOfProjects: [],
            listOfModules: [],
            search: "",
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            HubFunctions.showLoading();
            // get all projects
            let allProjects;
            if (!GlobalDictionary.get(LISTOFPROJECTS)) {
                allProjects = yield this.getListOfAllProjects();
                if (allProjects.length === 0)
                    return;
            }
            else {
                allProjects = GlobalDictionary.get(LISTOFPROJECTS);
            }
            this.setState({ listOfProjects: allProjects });
            // get all modules to all projects
            yield this.gatherAllUsedModules(allProjects);
            HubFunctions.hideLoading();
        });
    }
    getListOfAllProjects() {
        return __awaiter(this, void 0, void 0, function* () {
            return listProjects().then((data) => {
                if (data.success) {
                    GlobalDictionary.set(LISTOFPROJECTS, data.data.value);
                    return data.data.value;
                }
                else {
                    HubFunctions.PopupCreate("Error", data.message);
                    return [];
                }
            });
        });
    }
    /**
     * Get all modules to a project
     * @param projectId
     */
    getProjectModules(projectId) {
        return __awaiter(this, void 0, void 0, function* () {
            return listModuleInstances(projectId).then((data) => {
                if (data.success) {
                    let ret = data.data.value;
                    // set the project id of every module
                    ret.forEach((module) => {
                        module.projectId = projectId;
                    });
                    return ret;
                }
                else {
                    HubFunctions.PopupCreate("Error", data.message);
                }
            });
        });
    }
    translateProjectIdToProjectName(projectId) {
        const projectList = GlobalDictionary.get(LISTOFPROJECTS);
        if (!projectList)
            return "";
        const project = projectList.find((i) => i.projectId === projectId);
        if (!project)
            return "";
        return project.displayName;
    }
    /**
     * Sort all modules to a table of modules and their usage
     * @param allProjects
     */
    gatherAllUsedModules(allProjects) {
        return __awaiter(this, void 0, void 0, function* () {
            let allModules = [];
            let listToLoad = [];
            allProjects.forEach((project) => {
                listToLoad.push(this.getProjectModules(project.projectId).then((modules) => {
                    allModules.push(...modules);
                }));
            });
            yield Promise.all(listToLoad);
            this.setState({ listOfModules: allModules });
        });
    }
    searchForId(id) {
        // search in project for id
        let answer = [];
        this.state.listOfProjects.filter((project) => project.projectId.includes(id)).forEach((project) => {
            answer.push({
                id: project.projectId,
                projectId: project.projectId,
                projectName: project.displayName,
                moduleName: "",
                moduleId: "",
                linkedId: "",
            });
        });
        // search in modules for id
        this.state.listOfModules.filter((module) => module.moduleInstanceId.includes(id)).forEach((module) => {
            answer.push({
                id: module.moduleInstanceId,
                projectId: module.projectId,
                projectName: this.translateProjectIdToProjectName(module.projectId),
                moduleName: this.listOfModules[module.staticModuleId].moduleContent.title,
                moduleId: module.moduleInstanceId,
                linkedId: "",
            });
        });
        // search in linked modules for id
        this.state.listOfModules.filter((module) => module.linkedId.projectId.includes(id)).forEach((module) => {
            answer.push({
                id: module.linkedId.projectId,
                projectId: module.projectId,
                projectName: this.translateProjectIdToProjectName(module.projectId),
                moduleName: this.listOfModules[module.staticModuleId].moduleContent.title,
                moduleId: module.moduleInstanceId,
                linkedId: module.linkedId.projectId,
            });
        });
        return answer;
    }
    matchedBy(answer) {
        if (answer.projectId === answer.id)
            return "Project id";
        if (answer.moduleId === answer.id)
            return "Module id";
        if (answer.linkedId === answer.id)
            return "Linked id";
        return "";
    }
    getResults() {
        if (this.state.search === "")
            return React.createElement(React.Fragment, null);
        return React.createElement(Table, { striped: true, bordered: true, hover: true },
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "Matched By"),
                    React.createElement("th", null, "Project name"),
                    React.createElement("th", null, "Module name"),
                    React.createElement("th", null, "Project id"),
                    React.createElement("th", null, "Module id"),
                    React.createElement("th", null, "Linked id"))),
            React.createElement("tbody", null, this.searchForId(this.state.search).map((answer, index) => {
                return React.createElement("tr", { key: index },
                    React.createElement("td", null, this.matchedBy(answer)),
                    React.createElement("td", null, answer.projectName),
                    React.createElement("td", null, answer.moduleName),
                    React.createElement("td", null, answer.projectId),
                    React.createElement("td", null, answer.moduleId),
                    React.createElement("td", null, answer.linkedId));
            })));
    }
    render() {
        return React.createElement(React.Fragment, null,
            React.createElement("p", null, " Search for any project id, module instance id and linked id."),
            React.createElement("br", null),
            React.createElement(InputGroup, { size: "lg" },
                React.createElement(InputGroup.Text, { id: "inputGroup-sizing-lg" }, "Search for Id"),
                React.createElement("input", { type: "text", className: "form-control", "aria-label": "Sizing example input", "aria-describedby": "inputGroup-sizing-lg", onChange: (e) => this.setState({ search: e.target.value }) })),
            React.createElement("br", null),
            this.getResults());
    }
}
