export var HubFunctionType;
(function (HubFunctionType) {
    HubFunctionType[HubFunctionType["PopupCreateSingle"] = 0] = "PopupCreateSingle";
    HubFunctionType[HubFunctionType["PopupCreate"] = 1] = "PopupCreate";
    HubFunctionType[HubFunctionType["PopupClose"] = 2] = "PopupClose";
    HubFunctionType[HubFunctionType["showLoading"] = 3] = "showLoading";
    HubFunctionType[HubFunctionType["hideLoading"] = 4] = "hideLoading";
    HubFunctionType[HubFunctionType["updateUrl"] = 5] = "updateUrl";
    HubFunctionType[HubFunctionType["switchModule"] = 6] = "switchModule";
})(HubFunctionType || (HubFunctionType = {}));
class HubFunctions {
    constructor() {
        //...
    }
    static get Instance() {
        return this._instance || (this._instance = new this());
    }
    registerFunction(func, functionType) {
        switch (functionType) {
            case HubFunctionType.PopupCreateSingle:
                this._popupCreateSingle = func;
                break;
            case HubFunctionType.PopupCreate:
                this._popupCreate = func;
                break;
            case HubFunctionType.PopupClose:
                this._popupClose = func;
                break;
            case HubFunctionType.showLoading:
                this._showLoading = func;
                break;
            case HubFunctionType.hideLoading:
                this._hideLoading = func;
                break;
            case HubFunctionType.updateUrl:
                this._updateUrl = func;
                break;
            case HubFunctionType.switchModule:
                this._switchModule = func;
                break;
        }
    }
    unregisterAllFunctions() {
        this._popupCreateSingle = undefined;
        this._popupCreate = undefined;
        this._popupClose = undefined;
        this._showLoading = undefined;
        this._hideLoading = undefined;
        this._updateUrl = undefined;
        this._switchModule = undefined;
    }
    PopupCreateSingle(header, content, submitFunction) {
        this._popupCreateSingle(header, content, submitFunction);
    }
    /**
     * Create a popup with a header and content. On the left side is a label "Message"
     * @param header headline of the popup
     * @param content content of the popup
     * @param submitFunction function to be called when the submit button is pressed
     * @constructor
     */
    PopupCreate(header, content, submitFunction) {
        this._popupCreate(header, content, submitFunction);
    }
    /**
     * Close the popup
     * @constructor
     */
    PopupClose() {
        this._popupClose();
    }
    /**
     * Show the loading spinner
     */
    showLoading() {
        this._showLoading();
    }
    /**
     * Hide the loading spinner
     */
    hideLoading() {
        this._hideLoading();
    }
    /**
     * Update the url of the browser
     */
    updateUrl() {
        this._updateUrl();
    }
    /**
     * Switch the module
     * @param moduleName
     * @param idName
     * @param displayName
     * @param page
     * @param linkedId
     */
    switchModule(moduleName, idName, displayName, page, linkedId) {
        this._switchModule(moduleName, idName, displayName, page, linkedId);
    }
}
export default HubFunctions.Instance;
