import makeAnimated from "react-select/animated";
export const animatedComponents = makeAnimated();
export function findIndexInContractorNames(list, item) {
    let i = 0;
    for (let element of list) {
        if (element.Name === item) {
            return i;
        }
        i++;
    }
    return -1;
}
export var SearchBy;
(function (SearchBy) {
    SearchBy[SearchBy["LastModified"] = 0] = "LastModified";
    SearchBy[SearchBy["AcceptanceDate"] = 1] = "AcceptanceDate";
})(SearchBy || (SearchBy = {}));
export function convertAreasToOptionsList(areas) {
    let options = [];
    if (areas == undefined) {
        return options;
    }
    areas.forEach((area) => {
        options.push({
            value: area.name,
            label: area.name
        });
    });
    return options;
}
export function convertListOfStringsToOptionsList(areas) {
    let options = [];
    if (areas == undefined) {
        return options;
    }
    areas.forEach((x) => {
        options.push({
            value: x,
            label: x
        });
    });
    return options;
}
export var Popup;
(function (Popup) {
    Popup[Popup["None"] = 0] = "None";
    Popup[Popup["Log"] = 1] = "Log";
    Popup[Popup["EditUser"] = 2] = "EditUser";
    Popup[Popup["DeleteUser"] = 3] = "DeleteUser";
    Popup[Popup["EditProject"] = 4] = "EditProject";
})(Popup || (Popup = {}));
