import { createRoot } from "react-dom/client";
export function say(any) {
    console.log(any);
}
// React 18
const container = document.getElementById("root");
const root = createRoot(container);
export function showRootComponent(component) {
    root.render(component);
}
