import * as React from "react";
export class Module extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        window.location.assign("https://keychangeraccessfuncs.azurewebsites.net/api/KeychangerTaskEventTrigger?code=lHbuueUoXccG6PnGEOBXDKauaxWu9UinBACnd92otVsMUbNZvcDZuA%3D%3D&group=true");
    }
    render() {
        return React.createElement(React.Fragment, null);
    }
}
