import { getUserRole } from "./authentication";
import { UserRoles } from "./IAuthorization";
import { say } from "./webHelper";
export function isUserroleSufficient(target = UserRoles.reader, project, module) {
    if (!getUserRole() || getUserRole()[0] !== "{") {
        say("isUserroleSufficient false (no user role)");
        return false;
    }
    let userRole = JSON.parse(getUserRole());
    let accepted = false;
    // check global role
    const globalRole = userRole["user"];
    if (UserRoles[globalRole] >= target) {
        accepted = true;
    }
    // check project role
    if (project && userRole["user/" + project]) {
        const projectRole = userRole["user/" + project];
        accepted = UserRoles[projectRole] >= target;
    }
    // check module role
    if (project && module && userRole["user/" + project + "/" + module]) {
        const moduleRole = userRole["user/" + project + "/" + module];
        accepted = UserRoles[moduleRole] >= target;
    }
    // check if user has module rights on project level
    const transitiveRights = Object.keys(userRole).filter(x => x.startsWith("user/" + project + "/"));
    if (project && !module && transitiveRights.length > 0) {
        accepted = UserRoles.reader >= target;
    }
    return accepted;
}
