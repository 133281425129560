export var Popup;
(function (Popup) {
    Popup[Popup["None"] = 0] = "None";
    Popup[Popup["Config_AddEdit"] = 1] = "Config_AddEdit";
    Popup[Popup["Config_delete"] = 2] = "Config_delete";
    Popup[Popup["Users_AddEdit"] = 3] = "Users_AddEdit";
})(Popup || (Popup = {}));
export var SearchBy;
(function (SearchBy) {
    SearchBy[SearchBy["Include"] = 0] = "Include";
    SearchBy[SearchBy["Exclude"] = 1] = "Exclude";
})(SearchBy || (SearchBy = {}));
export var UsersEditMode;
(function (UsersEditMode) {
    UsersEditMode[UsersEditMode["Add"] = 0] = "Add";
    UsersEditMode[UsersEditMode["Edit"] = 1] = "Edit";
    UsersEditMode[UsersEditMode["None"] = 2] = "None";
})(UsersEditMode || (UsersEditMode = {}));
