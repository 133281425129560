import { Start } from "./Tabs/Start";
import { Second } from "./Tabs/Second";
/**
 * The content of the module
 * This is mandatory for every module. It contains all the information about the module.
 */
export const moduleContent = {
    moduleName: "Template",
    title: "Template Title",
    pages: [
        {
            displayName: "Start",
            contentPage: Start
        },
        {
            displayName: "Second",
            contentPage: Second
        }
    ],
    defaultPage: "Start",
};
