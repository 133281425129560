import * as React from "react";
export class Module extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        window.location.assign("https://dpodfrontendprod.z6.web.core.windows.net/#/project/" + this.props.hubArgs.linkedId.projectId + "/stage/prod");
    }
    render() {
        return React.createElement(React.Fragment, null);
    }
}
