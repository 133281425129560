import { EditProject } from "../DOOP/Tabs/EditProject";
import { EditUsers } from "../DOOP/Tabs/EditUsers";
import { Overview } from "../DOOP/Tabs/Overview";
import { DOOPDocumentation } from "../DOOP/Tabs/DOOPDocumentation";
import { Logs } from "../DOOP/Tabs/Logs";
/**
 * The content of the module
 * This is mandatory for every module. It contains all the information about the module.
 * DOOPDEV: If you need different code from the prod version, copy the contents of prod folder to dev folder
 * and change the imports here and in index.tsx
 */
export const moduleContent = {
    moduleName: "doopDev",
    title: "DOOP (Dev)",
    pages: [
        {
            displayName: "Orders",
            contentPage: Overview
        },
        {
            displayName: "Configuration",
            contentPage: EditProject
        },
        {
            displayName: "User Management",
            contentPage: EditUsers
        },
        {
            displayName: "Logs",
            contentPage: Logs
        },
        {
            displayName: "Documentation",
            contentPage: DOOPDocumentation
        }
    ],
    creationProps: [
        {
            displayName: "Order Number",
            name: "DefaultOrderNumber",
            type: "string",
            description: "The default order number",
            value: "",
        },
        {
            displayName: "Default Price Model",
            name: "DefaultPriceModel",
            type: "string",
            description: "The default price model",
            value: "",
        },
    ],
    defaultPage: "Orders",
};
