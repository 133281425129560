var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { Popup } from "../Interfaces/IAllModules";
import { HubPathRouting } from "../../../HubFramework/pathBuilder";
import { moduleContent as ProjectContentModule } from "../moduleContent";
import styles from "../ProjectContent.module.css";
import { moduleIcons } from "../Icons/Icons";
import { AllModules_Edit } from "./Modals/AllModules_Edit";
import { isUserroleSufficient } from "../../../Utils/authorization";
import { UserRoles } from "../../../Utils";
import { listModuleInstances } from "../../../BackendFacade/moduleCalls";
import { isExternalModule } from "../../../Utils/modules";
import { GlobalDictionary } from "../../../Utils/globalDictionary";
import { IconEdit } from "../../../Assets/svgs";
import HubFunctions from "../../../HubFramework/hubFunctions";
export class AllModules extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groupModules: [],
            availableModules: [],
            newModule: undefined,
            popup: Popup.None,
            newModuleInfos: undefined,
            popupMessage: "",
            editModule: undefined,
            loading: true,
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.LoadModules();
        });
    }
    LoadModules() {
        return __awaiter(this, void 0, void 0, function* () {
            this.setState({ loading: true });
            // if the module instances are not loaded yet, load them
            // otherwise load the modules in the background
            let listToLoad = [];
            if (!GlobalDictionary.get(HubPathRouting.currentProject.moduleInstanceId + GlobalDictionary.MODULEINSTANCES)) {
                listToLoad.push(this.getExistingModules());
            }
            else {
                this.createModuleGroups();
                yield this.getExistingModules();
            }
            this.setState({ availableModules: yield GlobalDictionary.getStaticModules() });
            if (listToLoad.length > 0) {
                HubFunctions.showLoading();
            }
            yield Promise.all(listToLoad);
            HubFunctions.hideLoading();
            this.setState({ loading: false });
        });
    }
    /**
     * Gets the list of all module instances from the backend
     */
    getExistingModules() {
        return __awaiter(this, void 0, void 0, function* () {
            const id = this.props.parentModule.state.id;
            yield listModuleInstances(id).then((data) => {
                if (data.success) {
                    let modules = data.data.value;
                    GlobalDictionary.set(HubPathRouting.currentProject.moduleInstanceId + GlobalDictionary.MODULEINSTANCES, modules);
                    this.createModuleGroups();
                }
                else {
                    HubFunctions.PopupCreate("Error", data.message);
                }
            });
        });
    }
    /**
     * Creates the groups of modules and sets the state
     */
    createModuleGroups() {
        const modules = GlobalDictionary.get(HubPathRouting.currentProject.moduleInstanceId + GlobalDictionary.MODULEINSTANCES);
        // sort the list of modules into groups
        let groups = [];
        modules.forEach((module) => {
            let group = groups.find((group) => group.name === module.group);
            if (group) {
                group.modules.push(module);
            }
            else {
                if (module.group === undefined || module.group === "") {
                    module.group = "Other";
                }
                groups.push({
                    name: module.group,
                    modules: [module]
                });
            }
        });
        this.setState({ groupModules: groups });
    }
    switchToExistingModule(module) {
        HubFunctions.switchModule(module.staticModuleId, module.moduleInstanceId, module.displayName, undefined, module.linkedId);
    }
    /**
     * Creates an url for each module card
     * @param module
     */
    createCardUrl(module) {
        let parameters = module.linkedId ? "&linkedId=" + module.linkedId.projectId : null;
        return window.location.origin + "/#Hub/" +
            ProjectContentModule.moduleName + "/" + HubPathRouting.currentProject.moduleInstanceId + "/" +
            module.staticModuleId + "/" + module.moduleInstanceId +
            parameters;
    }
    EditModuleInstance_show(module) {
        this.setState({ popup: Popup.EditModule, editModule: module });
    }
    /**
     * Renders the existing modules
     */
    existingModuleCards() {
        let content = [];
        this.state.groupModules
            // sort the groups alphabetically but "other" always last
            .sort((a, b) => {
            if (a.name === "Other") {
                return 1;
            }
            if (b.name === "Other") {
                return -1;
            }
            return a.name.localeCompare(b.name);
        })
            .forEach((group, key) => {
            content.push(React.createElement("div", { key: key },
                React.createElement("div", { className: `${styles.GroupHeader}` }, group.name),
                React.createElement(Row, { lg: 3 }, group.modules.map((module, key2) => {
                    var _a, _b;
                    return React.createElement(Col, { className: "col-3", key: key2 },
                        React.createElement(Card, { key: module.moduleInstanceId, className: `${styles.ModuleCard}`, onClick: (event) => {
                                if (!isExternalModule(module))
                                    this.switchToExistingModule(module);
                            } },
                            React.createElement(Card.Body, null,
                                React.createElement("a", { href: this.createCardUrl(module), className: `${styles.HiddenLink}`, 
                                    // target blank if external module
                                    target: isExternalModule(module) ? "_blank" : undefined }),
                                isUserroleSufficient(UserRoles.editor, (_a = HubPathRouting.currentProject) === null || _a === void 0 ? void 0 : _a.moduleInstanceId) &&
                                    React.createElement(Button, { variant: "outline-secondary", id: module.displayName + "-" + group.name + "-edit", className: `${styles.OptionsButton}`, onClick: (event) => {
                                            event.stopPropagation();
                                            this.EditModuleInstance_show(module);
                                        } }, IconEdit),
                                React.createElement(Card.Title, null,
                                    moduleIcons[module.staticModuleId] ?
                                        React.createElement(Card.Img, { src: moduleIcons[module.staticModuleId], className: `${styles.ModuleIcon}` })
                                        :
                                            React.createElement(Card.Img, { src: moduleIcons["default"], className: `${styles.ModuleIcon}` }),
                                    module.displayName),
                                React.createElement(Card.Text, null, (_b = this.state.availableModules.find((mod) => mod.staticModuleId === module.staticModuleId)) === null || _b === void 0 ? void 0 : _b.description))));
                }))));
        });
        return React.createElement("div", { id: "allModulesList" }, content);
    }
    noExistingModules() {
        return React.createElement("div", null,
            React.createElement("div", { className: `${styles.GroupHeader}` }, "No modules instances available. Order new modules"));
    }
    closePopup() {
        this.setState({ popup: Popup.None, editModule: undefined });
    }
    render() {
        let popupContent = React.createElement("div", null);
        switch (this.state.popup) {
            case Popup.EditModule:
                popupContent = React.createElement(AllModules_Edit, { onSuccessFunction: this.LoadModules.bind(this), closeFunction: this.closePopup.bind(this), state: this.state.editModule, projectId: this.props.parentModule.state.id });
                break;
            default:
                break;
        }
        return React.createElement(React.Fragment, null,
            React.createElement(Modal, { size: "lg", show: this.state.popup !== Popup.None, onHide: () => this.setState({ popup: Popup.None }) }, popupContent),
            this.state.loading ? React.createElement(React.Fragment, null) :
                this.state.groupModules.length > 0 ? this.existingModuleCards() : this.noExistingModules());
    }
}
