import { UsersTab } from "../IDMS/Tabs/UsersTab";
import { Config } from "../IDMS/Tabs/Config";
import { AppDisplayName, authLevels, defaultAuth, DisableTenantManagement, userTenant } from "../IDMS/constants";
import { IDMSDocumentation } from "../IDMS/Tabs/IDMSDocumentation";
/**
 * The content of the module
 * This is mandatory for every module. It contains all the information about the module.
 * IDMSDEV: If you need different code from the prod version, copy the contents of prod folder to dev folder
 * and change the imports here and in index.tsx
 */
export const moduleContent = {
    moduleName: "idmsDev",
    title: "IDMS (Dev)",
    pages: [
        {
            displayName: "Config",
            contentPage: Config
        },
        {
            displayName: "User Management",
            contentPage: UsersTab
        },
        {
            displayName: "Documentation",
            contentPage: IDMSDocumentation
        }
    ],
    defaultPage: "Config",
    creationProps: [
        {
            name: "",
            type: "subProps",
            description: "",
            value: "",
            subProps: {
                value: [
                    {
                        name: AppDisplayName,
                        type: "string",
                        description: "Display Name",
                        value: "",
                    },
                    {
                        name: defaultAuth,
                        type: "string",
                        description: "Default Authlevel, must be in authLevels",
                        value: "admin",
                    },
                    {
                        name: authLevels,
                        type: "string[]",
                        description: "Authlevels",
                        value: ["admin", "user", "blocked"]
                    },
                    {
                        name: userTenant,
                        type: "select",
                        description: "User Tenant",
                        value: "draegerb2c",
                        options: ["draegerb2c", "draegerConnect"]
                    },
                    {
                        name: DisableTenantManagement,
                        type: "boolean",
                        displayName: "Disable Tenant Management",
                        description: "Activate this if you want to disable the tenant management",
                        value: false,
                    }
                ]
            }
        }
    ]
};
