import * as React from "react";
export class Module extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        window.location.assign("https://dev.azure.com/draeger/" + this.props.hubArgs.linkedId.projectId);
    }
    render() {
        return React.createElement(React.Fragment, null);
    }
}
