import * as React from "react";
import { AdminUserManagement } from "../../Hub/Tabs/UserManagement";
export class ProjectUserManagement extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return React.createElement(AdminUserManagement, { parentModule: this.props.parentModule });
    }
}
