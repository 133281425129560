var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Alert, Badge, Button, Col, Container, FormControl, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { HubPathRouting } from "../../../HubFramework/pathBuilder";
import { listModuleInstances } from "../../../BackendFacade/moduleCalls";
import { getEventLogs } from "../BackendFacade/apiCalls";
import { sortListByKey } from "../../../Utils/sorting";
import { convertListOfStringsToOptionsList } from "../../../Utils/transformer";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import styles from "../Dams.module.css";
import { GlobalDictionary } from "../../../Utils/globalDictionary";
import { calcTables, fixModuleName } from "../Utils/eventEvaluation";
import { GetAccessRoles } from "../../Hub/BackendFacade/apiCalls";
import { isUserroleSufficient } from "../../../Utils/authorization";
import { UserRoles } from "../../../Utils";
import HubFunctions from "../../../HubFramework/hubFunctions";
const animatedComponents = makeAnimated();
export class KafkaEventsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // From Date 14 days ago
            From: new Date(new Date().setDate(new Date().getDate() - 2)).toISOString().substring(0, 10),
            State: [],
            To: new Date().toISOString().substring(0, 10),
            Callback: null,
            layer: undefined,
            currentlyShownTableLayer: {},
            showTree: true,
            errors: [],
            maxShownEvents: {
                value: "1000",
                label: "1000"
            },
            noOfLoadingEvents: 0,
            finishedLoadingEvents: 0,
            excludedSources: [],
            kafkaTopics: [],
            FilterEdited: false,
            knownUsers: {},
            sortBy: "Time",
            sortDescend: true,
        };
        if (GlobalDictionary.get(this.getGlobalDictionaryId())) {
            this.state = GlobalDictionary.get(this.getGlobalDictionaryId());
        }
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.state.layer)
                yield GlobalDictionary.getProjects();
            yield this.getAllData();
        });
    }
    /**
     * Checks whether a project, module or hub is selected
     * Creates a unique id for the global dictionary
     */
    getGlobalDictionaryId() {
        let id = "kafkaEvents";
        if (HubPathRouting.currentProject) {
            id += HubPathRouting.currentProject.moduleInstanceId;
        }
        if (HubPathRouting.currentModule) {
            id += HubPathRouting.currentModule.moduleInstanceId;
        }
        return id;
    }
    /**
     * Gets all data for a project from the backend
     */
    getAllData() {
        return __awaiter(this, void 0, void 0, function* () {
            HubFunctions.showLoading();
            this.setState({ layer: undefined, currentlyShownTableLayer: {}, errors: [], finishedLoadingEvents: 0, noOfLoadingEvents: 0, FilterEdited: false });
            const kafkaInstances = yield this.prepareKafkaInfos();
            // filter the kafka instances with excluded sources
            const filteredKafkaInstances = kafkaInstances.filter((kafkaInstance) => !this.state.excludedSources.some((excludedSource) => excludedSource.value === kafkaInstance.eventLogTopicName));
            this.setState({ noOfLoadingEvents: filteredKafkaInstances.length, kafkaTopics: kafkaInstances.map((kafkaInstance) => kafkaInstance.eventLogTopicName) });
            // get all projects (IDMS, DOOP, Keychanger, DPAS)
            let promises = [];
            let events = [];
            let currentProjectId = HubPathRouting.currentProject ? HubPathRouting.currentProject.moduleInstanceId : "null";
            filteredKafkaInstances.forEach((kafkaInstance) => {
                promises.push(getEventLogs(currentProjectId, kafkaInstance.eventLogTopicName, kafkaInstance.linkedId, { startDay: this.convertDateToDayOfYear(this.state.From),
                    startYear: this.convertDateToYear(this.state.From),
                    endDay: this.convertDateToDayOfYear(this.state.To),
                    endYear: this.convertDateToYear(this.state.To),
                }).then((data) => {
                    if (data.success) {
                        events = events.concat(data.data.value);
                    }
                    else {
                        this.setState({ errors: [...this.state.errors, { kafkaTopic: kafkaInstance.eventLogTopicName, message: data.message }] });
                    }
                    this.setState({ finishedLoadingEvents: this.state.finishedLoadingEvents + 1 });
                }));
            });
            yield Promise.all(promises);
            debugger;
            let layers = calcTables(events);
            this.setState({ layer: layers, currentlyShownTableLayer: layers });
            yield new Promise(r => setTimeout(r, 1));
            // filter by filter bar
            this.calculateFilteredTable();
            // select first layer
            if (layers.layer.length > 0) {
                this.sortByKey(this.state.sortBy, layers.layer[0].mergedSources, this.state.sortDescend);
                this.setState({ currentlyShownTableLayer: layers.layer[0], layer: this.state.layer });
            }
            yield new Promise(r => setTimeout(r, 1));
            GlobalDictionary.set(this.getGlobalDictionaryId(), this.state);
            HubFunctions.hideLoading();
        });
    }
    getModuleAndKafkaInstances(staticModules) {
        return __awaiter(this, void 0, void 0, function* () {
            let moduleInstances;
            let kafkaInstances;
            // if project is set, get all module instances of the current project. Retrieve the linked id for the kafkaTopic
            // otherwise get all module instances of all projects
            if (HubPathRouting.currentProject) {
                // get all module instances of the current project. Retrieve the linked id for the kafkaTopic
                moduleInstances = yield GlobalDictionary.get(HubPathRouting.currentProject.moduleInstanceId + GlobalDictionary.MODULEINSTANCES);
                if (!moduleInstances) {
                    yield listModuleInstances(HubPathRouting.currentProject.moduleInstanceId).then((data) => {
                        GlobalDictionary.set(HubPathRouting.currentProject.moduleInstanceId + GlobalDictionary.MODULEINSTANCES, data.data.value);
                        moduleInstances = data.data.value;
                    });
                }
                // if the module instance is set, filter only for this one
                if (HubPathRouting.currentModule) {
                    moduleInstances = moduleInstances.filter((moduleInstance) => {
                        return moduleInstance.moduleInstanceId === HubPathRouting.currentModule.moduleInstanceId;
                    });
                }
                // find the intersection of both
                kafkaInstances = moduleInstances.map((moduleInstance) => {
                    let staticModule = staticModules.find((staticModule) => {
                        return staticModule.staticModuleId === moduleInstance.staticModuleId && staticModule.eventLogTopicName !== null;
                    });
                    if (staticModule === undefined)
                        return;
                    return {
                        linkedId: moduleInstance.linkedId.projectId,
                        eventLogTopicName: staticModule.eventLogTopicName
                    };
                }).filter((kafkaInstance) => {
                    return kafkaInstance !== undefined;
                });
            }
            else {
                // get all module instances of all projects
                let projects = yield GlobalDictionary.getProjects();
                moduleInstances = yield Promise.all(projects.map((project) => {
                    return listModuleInstances(project.projectId).then((data) => {
                        if (data.success) {
                            return data.data.value.map((x) => {
                                return Object.assign(Object.assign({}, x), { projectId: project.projectId });
                            });
                        }
                        else {
                            HubFunctions.PopupCreate("Error", data.message);
                            return [];
                        }
                    });
                }));
                // flatten the array
                moduleInstances = moduleInstances.flat();
                kafkaInstances = staticModules.map((staticModule) => {
                    if (staticModule.eventLogTopicName === null)
                        return;
                    return {
                        linkedId: null,
                        eventLogTopicName: staticModule.eventLogTopicName
                    };
                });
                // filter out undefined
                kafkaInstances = kafkaInstances.filter((kafkaInstance) => {
                    return kafkaInstance !== undefined;
                });
            }
            // save the module Instances in the global dictionary
            // to resolve the ids to the module instance display names
            GlobalDictionary.set("kafkaModuleInstances", moduleInstances);
            return kafkaInstances;
        });
    }
    /**
     * Collect the kafka topics. If a project is set, only collect the kafka topics of the project. On module
     * level, collect the kafka topics of the module. If no project is set, collect all kafka topics of all projects
     */
    prepareKafkaInfos() {
        return __awaiter(this, void 0, void 0, function* () {
            // get all static modules for the id of eventLogTopicName
            let staticModules = yield GlobalDictionary.getStaticModules();
            // get all users
            yield this.getAllUsers();
            let kafkaInstances = yield this.getModuleAndKafkaInstances(staticModules);
            // add the hub module to the list, if on hub level
            if (!HubPathRouting.currentModule) {
                // if "stage" is in current url, add the stage hub
                if (window.location.href.includes("stage") || window.location.href.includes("localhost")) {
                    kafkaInstances.push({
                        linkedId: HubPathRouting.currentProject ? HubPathRouting.currentProject.moduleInstanceId : null,
                        eventLogTopicName: "devsecops.hub-events.stage.v1.json"
                    });
                }
                else {
                    kafkaInstances.push({
                        linkedId: HubPathRouting.currentProject ? HubPathRouting.currentProject.moduleInstanceId : null,
                        eventLogTopicName: "devsecops.hub-events.prod.v1.json"
                    });
                }
            }
            return kafkaInstances;
        });
    }
    /**
     * Creates a dictionary with all users of the hub as far as the user has access to
     */
    getAllUsers() {
        return __awaiter(this, void 0, void 0, function* () {
            let users;
            // hub level or user is at least global reader
            if (!HubPathRouting.currentProject || isUserroleSufficient(UserRoles.reader)) {
                // get users if not already in global dictionary
                if (!GlobalDictionary.get("usersnull")) {
                    yield this.getUserData();
                }
                users = GlobalDictionary.get("usersnull");
            }
            else {
                // project level
                // get users if not already in global dictionary
                if (!GlobalDictionary.get("users" + HubPathRouting.currentProject.moduleInstanceId)) {
                    yield this.getUserData(HubPathRouting.currentProject.moduleInstanceId);
                }
                users = GlobalDictionary.get("users" + HubPathRouting.currentProject.moduleInstanceId);
            }
            users.forEach((key) => {
                this.state.knownUsers[key.userId] = key.mail;
            });
            this.setState({ knownUsers: this.state.knownUsers });
            yield new Promise(r => setTimeout(r, 1));
        });
    }
    getUserData(project = "null") {
        return __awaiter(this, void 0, void 0, function* () {
            yield GetAccessRoles(project).then((data) => {
                if (data.success) {
                    GlobalDictionary.set("users" + project, data.data.value);
                }
                else {
                    HubFunctions.PopupCreate("Error", "Error getting user data " + data.message);
                }
            });
        });
    }
    /**
     * Converts date YYYY-MM-DD to day of year
     * @param dateString
     */
    convertDateToDayOfYear(dateString) {
        let date = new Date(dateString);
        let start = new Date(date.getFullYear(), 0, 0);
        let diff = (date.getTime() - start.getTime()) + ((start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000);
        let oneDay = 1000 * 60 * 60 * 24;
        return Math.floor(diff / oneDay);
    }
    /**
     * Converts date YYYY-MM-DD to year
     * @param dateString
     */
    convertDateToYear(dateString) {
        let date = new Date(dateString);
        return date.getFullYear();
    }
    resetToolbar() {
        this.setState({ From: new Date(new Date().setDate(new Date().getDate() - 14)).toISOString().substring(0, 10),
            State: [],
            To: new Date().toISOString().substring(0, 10)
        });
    }
    displayEventCounters(layer) {
        let info = layer.numberOfInfoEvents > 0 ? React.createElement(React.Fragment, null,
            React.createElement(Badge, { pill: true, bg: "secondary" }, layer.numberOfInfoEvents)) : null;
        let warning = layer.numberOfWarningEvents > 0 ? React.createElement(React.Fragment, null,
            React.createElement(Badge, { pill: true, bg: "warning" }, layer.numberOfWarningEvents)) : null;
        let error = layer.numberOfErrorEvents > 0 ? React.createElement(React.Fragment, null,
            React.createElement(Badge, { pill: true, bg: "danger" }, layer.numberOfErrorEvents)) : null;
        return React.createElement(React.Fragment, null,
            info,
            " ",
            warning,
            " ",
            error);
    }
    sortByKey(key, source, descend = false) {
        if (!source)
            return;
        source.SortDescend = !source.SortDescend || descend;
        source.KafkaEvents = sortListByKey(source.KafkaEvents, key, source.SortDescend);
        source.SortbyKey = key;
        this.setState({ sortBy: key, sortDescend: descend });
    }
    showSortIcon(key, source) {
        if (key === source.SortbyKey) {
            return source.SortDescend ? React.createElement("i", { className: "uib-icon--arrow1-down uib-icon" }) : React.createElement("i", { className: "uib-icon--arrow1-up uib-icon" });
        }
    }
    calculateNumberOfEventsTree(layer) {
        layer.numberOfErrorEvents = 0;
        layer.numberOfWarningEvents = 0;
        layer.numberOfInfoEvents = 0;
        if (layer.source !== undefined) {
            layer.numberOfErrorEvents = layer.source.KafkaEvents.filter((event) => {
                return event.LogLevel === "Error" && event.show;
            }).length;
            layer.numberOfWarningEvents = layer.source.KafkaEvents.filter((event) => {
                return event.LogLevel === "Warning" && event.show;
            }).length;
            layer.numberOfInfoEvents = layer.source.KafkaEvents.filter((event) => {
                return event.LogLevel === "Info" && event.show;
            }).length;
        }
        layer.layer.forEach((child) => {
            this.calculateNumberOfEventsTree(child);
            layer.numberOfErrorEvents += child.numberOfErrorEvents;
            layer.numberOfWarningEvents += child.numberOfWarningEvents;
            layer.numberOfInfoEvents += child.numberOfInfoEvents;
        });
    }
    /**
     * Shows the content of a clicked source
     * @param layer
     */
    triggerShow(layer) {
        // layer.show = !layer.show
        this.sortByKey(this.state.sortBy, layer.mergedSources, this.state.sortDescend);
        this.setState({ layer: this.state.layer, currentlyShownTableLayer: layer });
    }
    triggerArrow(layer) {
        layer.show = !layer.show;
        this.setState({ layer: this.state.layer });
    }
    /**
     * Displays the correct arrow for the source
     * @param layer
     */
    showArrow(layer) {
        if (layer.layer.length > 0) {
            return layer.show ?
                React.createElement("div", { className: styles.arrowParent, onClick: (event) => {
                        event.stopPropagation();
                        this.triggerArrow(layer);
                    } },
                    React.createElement("i", { className: `uib-icon--arrow1-down uib-icon ${styles.arrow}` }))
                :
                    React.createElement("div", { className: styles.arrowParent, onClick: (event) => {
                            event.stopPropagation();
                            this.triggerArrow(layer);
                        } },
                        React.createElement("i", { className: `uib-icon--arrow1-right uib-icon ${styles.arrow}` }));
        }
        return React.createElement("i", { className: `uib-icon--arrow1-right uib-icon ${styles.arrow} ${styles.colorWhite}` });
    }
    ShowSourceTree(layer) {
        let s = [];
        // root node
        // s.push(
        //     <li className={layer === this.state.currentlyShownTableLayer ? styles.selectedSource : styles.clickableSource}
        //         onClick={() => {this.triggerShow(layer)}}>
        //         <div className={`${styles.sourceItem} row`}>
        //                 <span className={styles.sourceItemCol}>
        //                     {this.showArrow(layer)}
        //                     {layer.name}
        //                 </span>
        //             <span className={styles.sourceItemLoglevelNumbers}>{this.displayEventCounters(layer)}</span>
        //         </div>
        //     </li>
        // )
        this.showSourceFolders(layer, 0).forEach((element) => {
            s.push(element);
        });
        return s;
    }
    /**
     * Recursively adding the layers, create popups for the layer names
     * @param layer Sublayer
     * @param depth current depth of the layer
     */
    showSourceFolders(layer, depth = 0) {
        if (Object.keys(layer).length === 0) {
            return;
        }
        // intend empty space for every layer
        let emptySpace = [];
        for (let i = 0; i < depth * 2; i++) {
            emptySpace.push(React.createElement(React.Fragment, null, "\u2003"));
        }
        let ul = [];
        layer.layer.forEach((layer, key) => {
            // clickable layer name
            let li = React.createElement(OverlayTrigger, { placement: "right", delay: { show: 200, hide: 50 }, overlay: React.createElement(Tooltip, { id: "tooltip-key" }, layer.name) },
                React.createElement("li", { className: `${layer === this.state.currentlyShownTableLayer ? styles.selectedSource : styles.clickableSource}`, onClick: () => { this.triggerShow(layer); } },
                    React.createElement("div", { className: `${styles.sourceItem} row` },
                        React.createElement("span", { className: styles.sourceItemCol },
                            emptySpace,
                            this.showArrow(layer),
                            fixModuleName(layer.name)),
                        React.createElement("span", { className: styles.sourceItemLoglevelNumbers }, this.displayEventCounters(layer)))));
            ul.push(li);
            if (layer.layer.length > 0 && layer.show) {
                this.showSourceFolders(layer, depth + 1).forEach((element) => {
                    ul.push(element);
                });
            }
        });
        return ul;
    }
    showTableHeads(layer) {
        let theads = [];
        // merged sources
        if (layer.mergedSources !== undefined && layer.mergedSources.KafkaEvents.length !== 0) {
            layer.mergedSources.tableHeads.forEach((key, layer2) => {
                if (layer.mergedSources.hiddenColumns.indexOf(key) === -1) {
                    theads.push(React.createElement("th", { key: layer2 - 100, onClick: () => { this.sortByKey(key, layer.mergedSources); this.setState({ layer: this.state.layer }); } },
                        key.replace("Detail_", ""),
                        " ",
                        this.showSortIcon(key, layer.mergedSources)));
                }
            });
        }
        return React.createElement("thead", { className: `uib-table__header ${styles.tableHead}` },
            React.createElement("tr", { className: "uib-table__row uib-table__row--header" }, theads));
    }
    /**
     * Prepare the table body, considering settings of the toolbar
     * @param layer
     */
    showTableRows(layer) {
        let rows = [];
        // merged sources
        if (layer.mergedSources !== undefined) {
            layer.mergedSources.KafkaEvents.forEach((event, layer4) => {
                // apply filter
                if (!event.show || layer4 >= +this.state.maxShownEvents.value) {
                    return;
                }
                let row = [];
                layer.mergedSources.tableHeads.forEach((key, layer3) => {
                    if (layer.mergedSources.hiddenColumns.indexOf(key) === -1) {
                        row.push(React.createElement("td", { className: event.LogLevel === "Warning" ? styles.warning :
                                event.LogLevel === "Error" ? styles.danger : "", key: layer3 }, this.formatTableCell(key, event)));
                    }
                });
                rows.push(React.createElement("tr", { className: "uib-table__row", key: layer4 }, row));
            });
        }
        return rows;
    }
    /**
     * Filter the original data by the settings of the filter bar
     */
    calculateFilteredTable() {
        return __awaiter(this, void 0, void 0, function* () {
            yield new Promise(r => setTimeout(r, 1));
            if (!this.state.layer)
                return;
            this.filterTableRecursive(this.state.layer);
            this.calculateNumberOfEventsTree(this.state.layer);
            this.setState({ layer: this.state.layer });
        });
    }
    filterTableRecursive(layer) {
        // merge sources to mergedSource
        layer.layer.forEach((child) => {
            this.filterTableRecursive(child);
        });
        let To = new Date(this.state.To);
        To.setHours(23, 59, 59, 999);
        // merged sources
        if (layer.mergedSources !== undefined) {
            layer.mergedSources.KafkaEvents.forEach((event) => {
                if ((new Date(this.state.From) <= new Date(event.Time)
                    && new Date(To) >= new Date(event.Time)
                    // if no loglevel is selected or the correct loglevel is selected
                    && (this.state.State.length === 0 || this.state.State.filter((x) => x.label === event.LogLevel).length))) {
                    event.show = true;
                }
                else {
                    event.show = false;
                }
            });
        }
    }
    /**
     * Formats the table cell to a more readable format
     * @param key
     * @param event
     */
    formatTableCell(key, event) {
        switch (key) {
            case "Time":
                return new Date(event[key]).toLocaleString();
            case "Subject":
                // if an userid from known users is found, replace it with the name
                if (this.state.knownUsers && Object.keys(this.state.knownUsers).filter((x) => x === event[key]).length > 0) {
                    return this.state.knownUsers[event[key]];
                }
                return event[key];
            default:
                return event[key];
        }
    }
    filterbar() {
        return React.createElement("div", { className: `form-group row ${styles.noFlexWrap} ` },
            React.createElement("div", { className: `col col-md-auto ${styles.RButtons}` },
                React.createElement("label", { className: "col col-form-label" }),
                React.createElement("div", { className: "col " },
                    React.createElement(Button, { className: `${styles.TextButton} ${styles.ToolbarHeightElement} uib-button uib-button--primary`, id: "toggleTree", onClick: () => {
                            this.setState({ showTree: !this.state.showTree });
                        } }, this.state.showTree ? React.createElement(React.Fragment, null, "Hide Tree") : React.createElement(React.Fragment, null, "Show Tree")))),
            React.createElement("div", { className: "col col-md-auto" },
                React.createElement("label", { className: "col col-form-label" }, "From"),
                React.createElement("div", { className: "col" },
                    React.createElement(FormControl, { id: "dateFrom", type: "date", name: "dateFrom", max: "3000-12-31", value: this.state.From, min: "2021-01-01", className: `form-control datepicker ${styles.ToolbarHeightElement}`, onChange: (event) => {
                            this.setState({ From: event.target.value, FilterEdited: true });
                        } }))),
            React.createElement("div", { className: "col col-md-auto" },
                React.createElement("label", { className: "col col-form-label" }, "Until"),
                React.createElement("div", { className: "col" },
                    React.createElement(FormControl, { id: "dateUntil", type: "date", name: "dateUntil", max: "3000-12-31", value: this.state.To, min: "2021-01-01", className: `form-control datepicker ${styles.ToolbarHeightElement}`, onChange: (event) => {
                            this.setState({ To: event.target.value, FilterEdited: true });
                        } }))),
            React.createElement("div", { className: "col col-md-auto" },
                React.createElement("label", { className: "col col-form-label" }, "Exclude filter by Topic"),
                React.createElement("div", { className: "col" },
                    React.createElement(Select, { closeMenuOnSelect: false, className: styles.statePicker, components: animatedComponents, placeholder: "If empty, all are shown", isMulti: true, options: this.convertKafkaTopicsToOptionsList(this.state.kafkaTopics), onChange: (option) => {
                            this.setState({ excludedSources: option, FilterEdited: true });
                            this.calculateFilteredTable();
                        }, value: this.state.excludedSources, id: "excludeFilterByTopic" }))),
            React.createElement("div", { className: "col col-md-auto" },
                React.createElement("label", { className: "col col-form-label" }, "Filter by Loglevel"),
                React.createElement("div", { className: "col" },
                    React.createElement(Select, { closeMenuOnSelect: false, className: styles.statePicker, components: animatedComponents, placeholder: "If empty, all are selected", isMulti: true, options: convertListOfStringsToOptionsList(["Info", "Warning", "Error"]), onChange: (option) => {
                            this.setState({ State: option });
                            this.calculateFilteredTable();
                        }, value: this.state.State, id: "filterByLoglevel" }))),
            React.createElement("div", { className: `col col-md-auto ${styles.RButtons}` },
                React.createElement("label", { className: "col col-form-label" }),
                React.createElement("div", { className: "col " },
                    React.createElement(OverlayTrigger, { placement: "top", delay: { show: 150, hide: 200 }, overlay: React.createElement(Tooltip, { id: "tooltip-disabled" }, "Reset filters") },
                        React.createElement(Button, { className: `uib-button uib-button--primary uib-button--square  ${styles.resetButton} ${styles.ButtonLogo} ${styles.ToolbarHeightElement}`, id: "resetToolbar", onClick: () => {
                                this.resetToolbar();
                                this.calculateFilteredTable();
                            } },
                            React.createElement("i", { className: `uib-icon uib-icon--settings-leave ${styles.logo}` }))),
                    React.createElement(OverlayTrigger, { placement: "top", delay: { show: 150, hide: 200 }, overlay: React.createElement(Tooltip, { id: "tooltip-disabled" }, "Save the current date + filters and reload") },
                        React.createElement(Button, { className: `uib-button uib-button--primary uib-button--square  ${styles.ButtonLogo} ${styles.ToolbarHeightElement} ${this.state.FilterEdited ? styles.warning : ""}`, id: "saveAndRefresh", onClick: () => {
                                this.getAllData();
                            } },
                            React.createElement("i", { className: `uib-icon uib-icon--save ${styles.logo}` }))))),
            React.createElement("div", { className: "col col-md-auto" },
                React.createElement("label", { className: "col col-form-label" }, "Maximal shown events"),
                React.createElement("div", { className: "col" },
                    React.createElement(Select, { closeMenuOnSelect: true, className: styles.statePicker, components: animatedComponents, placeholder: "", id: "maxShownEvents", options: [{
                                value: "1000",
                                label: "1000"
                            },
                            {
                                value: "10000",
                                label: "10000"
                            },
                            {
                                value: "10000000",
                                label: "All"
                            }
                        ], onChange: (option) => {
                            this.setState({ maxShownEvents: option });
                        }, value: this.state.maxShownEvents }))));
    }
    convertKafkaTopicsToOptionsList(kafkaTopics) {
        let options = [];
        kafkaTopics.forEach((topic) => {
            // remove the prefix "devsecops."
            let label = topic.replace("devsecops.", "");
            label = label.replace(".v1.json", "");
            options.push({ value: topic, label: label });
        });
        return options;
    }
    showErrorMessages() {
        if (this.state.errors.length > 0) {
            return React.createElement(Alert, { variant: "danger", id: "alerts" }, this.state.errors.map((error, key) => {
                return React.createElement("div", { key: key },
                    error.kafkaTopic,
                    ": ",
                    error.message);
            }));
        }
        return React.createElement(React.Fragment, null);
    }
    render() {
        if (this.state.layer === undefined) {
            return React.createElement(React.Fragment, null,
                React.createElement("div", null,
                    "loading... ",
                    this.state.finishedLoadingEvents,
                    " of ",
                    this.state.noOfLoadingEvents,
                    " finished"),
                React.createElement("div", null, this.state.finishedLoadingEvents === this.state.noOfLoadingEvents && this.state.noOfLoadingEvents !== 0 ? "sorting" : ""));
        }
        return React.createElement("div", { className: styles.ScrollPage },
            this.showErrorMessages(),
            this.filterbar(),
            React.createElement(Container, { className: styles.DamsContainer, fluid: true },
                React.createElement(Row, { lg: 2, className: styles.DamsContainerInner },
                    this.state.showTree &&
                        React.createElement(Col, { md: "4", className: styles.SourceMenu },
                            React.createElement("ul", { className: `uib-list ${styles.sourceOverview}`, id: "treeSource" }, this.ShowSourceTree(this.state.layer))),
                    React.createElement("div", { className: this.state.showTree ? styles.DamsTableOuter : styles.DamsTableOuterWithoutTree },
                        React.createElement(Table, { className: `uib-table uib-table--striped ${styles.DamsTable}`, id: "logsTable" },
                            this.showTableHeads(this.state.currentlyShownTableLayer),
                            React.createElement("tbody", { className: "uib-table__body" }, this.showTableRows(this.state.currentlyShownTableLayer)))))));
    }
}
