import { AllModulePageInformation } from "./Tabs/AllModulePageInformation";
import { AllProjects } from "./Tabs/AllProjects";
import { NewProject } from "./Tabs/NewProject";
import { UserRoles } from "Utils";
import { AdminUserManagement } from "./Tabs/UserManagement";
import { HubDocumentation } from "./Tabs/HubDocumentation";
import { ModuleDocumentations } from "./Tabs/ModuleDocumentations";
import { Logs } from "./Tabs/Logs";
import { ModulesUsage } from "./Tabs/ModulesUsage";
import { ApiKeyManagement } from "./Tabs/ApiKeyManagement";
import { IdFinder } from "./Tabs/IdFinder";
/**
 * The content of the module
 * This is mandatory for every module. It contains all the information about the module.
 */
export const moduleContent = {
    title: "DevSecOps Hub",
    defaultPage: "All Projects",
    moduleName: "Hub",
    pages: [
        {
            displayName: "All Projects",
            contentPage: AllProjects,
            minimumRole: UserRoles.neutral
        },
        {
            displayName: "Modules Documentation",
            contentPage: ModuleDocumentations,
            minimumRole: UserRoles.neutral
        },
        {
            displayName: "Help and About",
            contentPage: HubDocumentation,
            minimumRole: UserRoles.neutral
        },
        {
            displayName: "",
            contentPage: null,
            separator: true,
            minimumRole: UserRoles.admin
        },
        {
            displayName: "Add New Project",
            contentPage: NewProject,
            minimumRole: UserRoles.admin
        },
        {
            displayName: "Users & Permissions",
            contentPage: AdminUserManagement,
            minimumRole: UserRoles.admin
        },
        {
            displayName: "Api Keys",
            contentPage: ApiKeyManagement,
            minimumRole: UserRoles.admin
        },
        {
            displayName: "Logs",
            contentPage: Logs,
            minimumRole: UserRoles.admin
        },
        {
            displayName: "Id Finder",
            contentPage: IdFinder,
            minimumRole: UserRoles.admin
        },
        {
            displayName: "Modules Usage",
            contentPage: ModulesUsage,
            minimumRole: UserRoles.admin
        },
        {
            displayName: "Modules Page Information",
            contentPage: AllModulePageInformation,
            minimumRole: UserRoles.admin
        }
    ],
};
