var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { FileType, Popup } from "../Interfaces/IFiles";
import { Breadcrumb, Button, Col, Container, Dropdown, DropdownButton, FormControl, FormGroup, Modal, ModalBody, ModalFooter, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { BlobDocuments, FolderDocument, FolderImage, FolderSecret } from "../constants";
import { HubPathRouting } from "../../../HubFramework/pathBuilder";
import { deleteFile, downloadFile, getDirContentNames, getFileAttributesSingle, UploadFile } from "../BackendFacade/apiCalls";
import { createDownloadObject, downloadListObjectAsCSV } from "../../../Utils/objectDownload";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { isUserroleSufficient } from "../../../Utils/authorization";
import { UserRoles } from "../../../Utils";
import { deepCopy } from "../../../Utils/transformer";
import { BlobURLBasic } from "../environmentVars";
import ModalHeader from "react-bootstrap/ModalHeader";
import Masonry from "react-responsive-masonry";
import styles from "../Dpas.module.css";
import HubFunctions from "../../../HubFramework/hubFunctions";
const animatedComponents = makeAnimated();
// TODO upload valid images types: jpg, jpeg, png, gif, bmp, tif, tiff, webp
/**
 * Management of directories: Since there are two types of directory types, the url splits up in different behaviours.
 * The url to be read is everything after the ?. It is structured as follows:
 * &[projectName]/[directoryType]/[directories]
 * The directoryType is not transmitted to the backend in the url but as type. It is set by state.directoryType
 */
export class Files extends React.Component {
    constructor(props) {
        super(props);
        let path = [this.props.parentModule.state.projectId];
        let projectId = this.props.parentModule.state.projectId;
        let directoryType = "";
        // evaluate parameters
        if (HubPathRouting.currentParameters && HubPathRouting.currentParameters.moduleInstanceId) {
            path = HubPathRouting.currentParameters.moduleInstanceId.split("/");
            projectId = path[0];
            if (path[1]) {
                // check if the directoryType is valid
                if (path[1] === FolderDocument || path[1] === FolderImage) {
                    directoryType = path[1];
                    if (path.length > 2) {
                        path = [].concat(path[0], path.slice(2));
                    }
                    else {
                        path = [path[0]];
                    }
                }
                else {
                    // only take the first element of the path
                    path = [path[0]];
                }
            }
            this.props.parentModule.setState({ projectId: projectId });
        }
        this.state = {
            projectId: projectId,
            Popup: Popup.None,
            PopupMessage: undefined,
            path: path,
            currentDirectory: undefined,
            toolbar_SearchField: "",
            toolbar_noResults: this.numberOfResults()[1],
            pictureDetail: "",
            fileDetails: undefined,
            uploadPath: "",
            fileSelectedForUpload: undefined,
            directoryType: directoryType,
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            let projectId = this.state.projectId;
            let openPath = this.state.path.join('/');
            HubFunctions.showLoading();
            let msg = yield this.props.parentModule.getProjectId();
            if (msg.success) {
                if (msg.projectId !== this.state.projectId) {
                    projectId = msg.projectId;
                    this.setState({ projectId: projectId });
                    this.props.parentModule.setState({ projectId: projectId });
                }
                // check if the path is already set and if the projectId is valid
                if (this.state.path === undefined || this.state.path.length === 0 || this.state.path[0] !== projectId) {
                    this.setState({ path: [projectId], directoryType: "" });
                    openPath = projectId;
                }
            }
            else {
                HubFunctions.PopupCreate("Error", msg.message);
            }
            this.openDirectory(openPath);
            HubFunctions.hideLoading();
            // wait for all states to be set
            yield new Promise(r => setTimeout(r, 1));
        });
    }
    /**
     * Opens a directory. If no directory is given, the root directory is opened.
     * @param directory takes full path as string
     */
    openDirectory(directory = "") {
        // on root level: display folders "image" and "documents" first
        let newpath;
        if (this.state.directoryType == "" || directory == "") {
            newpath = [this.state.projectId];
            this.setState({ currentDirectory: { directories: [], files: [], category: [FolderImage, FolderDocument, FolderSecret] },
                directoryType: "",
                path: []
            });
        }
        else {
            // display content of folder
            newpath = directory.split("/").filter(x => x !== "");
            this.setState({ path: newpath });
            HubFunctions.showLoading();
            getDirContentNames(directory, this.state.directoryType).then(data => {
                if (!data.success) {
                    HubFunctions.PopupCreate("Error", data.message);
                    return;
                }
                this.setState({ currentDirectory: data.data });
            }).finally(() => {
                HubFunctions.hideLoading();
            });
        }
        let tree = this.convertToTreePath(newpath, directory == "" ? "" : this.state.directoryType);
        this.setPathToURL(tree);
    }
    /**
     * Inserts the directoryType into the path to be displayed in the navigator
     * @param directories
     * @param directoryType
     */
    convertToTreePath(directories, directoryType) {
        return [].concat(directories[0], directoryType, directories.slice(1)).filter(x => x !== "");
    }
    setPathToURL(path) {
        HubPathRouting.currentParameters = { moduleInstanceId: path.join('/'), displayName: "", moduleName: "" };
        HubFunctions.updateUrl();
    }
    PopupClose() {
        this.setState({ Popup: Popup.None });
    }
    convertToMasonryItems(item) {
        let i = 0;
        let ret = [];
        // on type level, do not show UP symbol
        if (!item.category) {
            ret.push({
                id: i++,
                name: "Go up",
                type: FileType.Up,
                src: this.state.path.slice(0, -1).join('/')
            });
        }
        item.directories.forEach(dir => {
            ret.push({
                id: i++,
                name: dir,
                type: FileType.Directory,
                src: dir,
            });
        });
        item.files.forEach(file => {
            ret.push({
                id: i++,
                name: file.fileName,
                type: FileType.File,
                src: file.publicUrl,
            });
        });
        if (item.category) {
            item.category.forEach(cat => {
                ret.push({
                    id: i++,
                    name: cat,
                    type: FileType.Type,
                    src: cat
                });
            });
        }
        return ret;
    }
    cleanDirectoryName(dir) {
        return dir.split('/').filter(d => d !== "").pop();
    }
    cleanFileName(file, showExtension = false) {
        if (showExtension)
            return file.split('/').pop();
        return file.split('/').pop().split('.')[0];
    }
    card(data, index) {
        switch (data.data.type) {
            case FileType.Directory:
                return React.createElement("div", { className: styles.card, onClick: () => this.openDirectory(data.data.src), key: index },
                    React.createElement("div", { className: styles.outerDirectory },
                        React.createElement("div", { className: styles.innerDirectory },
                            React.createElement("i", { className: `uib-icon uib-icon--folder-open ${styles.folderLogo}` }))),
                    React.createElement(OverlayTrigger, { placement: "bottom", delay: { show: 200, hide: 200 }, overlay: React.createElement(Tooltip, { id: "tooltip-key" }, this.cleanDirectoryName(data.data.name)) },
                        React.createElement("span", { className: styles.cropText, children: this.cleanDirectoryName(data.data.name) })));
            case FileType.File:
                // type image
                let image = React.createElement("img", { className: styles.img, alt: "logo", src: data.data.src });
                let filename = React.createElement("span", { className: styles.cropText, children: this.cleanFileName(data.data.name) });
                // type document
                if (this.state.directoryType === FolderDocument || this.state.directoryType === FolderSecret) {
                    image = React.createElement("div", { className: styles.outerDocument },
                        React.createElement("div", { className: styles.innerDocument },
                            React.createElement("i", { className: `uib-icon uib-icon--attachment ${styles.folderLogo}` })));
                    filename = React.createElement("span", { className: styles.cropText, children: this.cleanFileName(data.data.name, true) });
                }
                return React.createElement("div", { className: styles.card, key: index, onClick: () => this.FileDetailOpen(data.data.name) },
                    image,
                    React.createElement(OverlayTrigger, { placement: "bottom", delay: { show: 200, hide: 200 }, overlay: React.createElement(Tooltip, { id: "tooltip-key" }, this.cleanFileName(data.data.name, this.state.directoryType == FolderDocument)) }, filename));
            case FileType.Up:
                return React.createElement("div", { className: styles.card, onClick: () => this.openDirectory(data.data.src), key: index },
                    React.createElement("div", { className: styles.outerDocument },
                        React.createElement("div", { className: styles.innerDocument },
                            React.createElement("i", { className: `uib-icon uib-icon--arrow1-up ${styles.folderLogo}` }))),
                    React.createElement("span", { className: styles.cropText, children: data.data.name }));
            case FileType.Type:
                return React.createElement("div", { className: styles.card, onClick: () => { this.setState({ directoryType: data.data.src }, () => this.openDirectory(this.state.projectId)); }, key: index },
                    React.createElement("div", { className: styles.outerDirectory },
                        React.createElement("div", { className: styles.innerDirectory },
                            React.createElement("i", { className: `uib-icon uib-icon--folder-open  ${styles.folderLogo}` }))),
                    React.createElement(OverlayTrigger, { placement: "bottom", delay: { show: 200, hide: 200 }, overlay: React.createElement(Tooltip, { id: "tooltip-key" }, this.cleanDirectoryName(data.data.name)) },
                        React.createElement("span", { className: styles.cropText, children: this.cleanDirectoryName(data.data.name) })));
        }
    }
    refreshDirectory() {
        this.openDirectory(this.state.path.join('/'));
    }
    numberOfResults() {
        let options = [];
        options.push({ value: "50", label: "50" });
        options.push({ value: "100", label: "100" });
        options.push({ value: "200", label: "200" });
        options.push({ value: "5000", label: "all" });
        return options;
    }
    download() {
        let files = this.filterCurrentView();
        // remove "go up"
        files = files.filter(x => x.id != 0);
        // remove directory name in all names
        files = files.map(x => {
            x.name = x.name.replace(this.state.path.join('/') + "/", "");
            return x;
        });
        downloadListObjectAsCSV(files, this.state.projectId);
    }
    navigationBar() {
        return React.createElement("div", { className: styles.navigationBar },
            React.createElement(Breadcrumb, null,
                React.createElement(Breadcrumb.Item, { onClick: () => this.openDirectory(""), active: this.state.path.length == 0 }, HubPathRouting.currentModule.displayName),
                this.state.path.map((dir, index) => {
                    // do not show project name, instead show directory type
                    if (index == 0)
                        dir = this.state.directoryType;
                    return React.createElement(Breadcrumb.Item, { onClick: () => this.openDirectory(this.state.path.slice(0, index + 1).join('/')), active: this.state.path.length == index + 1, key: index }, dir);
                })));
    }
    toolbar() {
        var _a, _b;
        let countAllItems = ((_b = (_a = this.state.currentDirectory) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b.length) || 1;
        if (this.state.path.length > 0 && this.state.directoryType !== "") {
            return React.createElement("div", { className: styles.toolbar },
                React.createElement("div", { className: "form-group row" },
                    React.createElement("div", { className: "col col-md-auto" },
                        React.createElement("label", { className: "col col-form-label" }, "Filter by"),
                        React.createElement(FormControl, { type: "text", placeholder: "Filename or Displayname", className: styles.FilterInput, onChange: (option) => { this.setState({ toolbar_SearchField: option.target.value }); }, value: this.state.toolbar_SearchField })),
                    React.createElement("div", { className: "col col-md-auto" },
                        React.createElement("label", { className: "col col-form-label" }, "Number of shown items"),
                        React.createElement("div", { className: "col" },
                            React.createElement(Select, { closeMenuOnSelect: true, className: styles.zindex, components: animatedComponents, options: this.numberOfResults(), onChange: (option) => { this.setState({ toolbar_noResults: option }); }, value: this.state.toolbar_noResults }))),
                    React.createElement("div", { className: `col col-md-auto ${styles.RButtons}` },
                        React.createElement(OverlayTrigger, { placement: "top", delay: { show: 150, hide: 200 }, overlay: React.createElement(Tooltip, { id: "tooltip-disabled" }, "Reset filters") },
                            React.createElement(Button, { className: `uib-button uib-button--primary uib-button--square  ${styles.resetButton} ${styles.ButtonLogo}`, onClick: () => this.searchField_Reset() },
                                React.createElement("i", { className: `uib-icon uib-icon--settings-leave ${styles.logo}` })))),
                    isUserroleSufficient(UserRoles.editor, HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId) ?
                        React.createElement("div", { className: `col col-md-auto ${styles.RButtons}` },
                            React.createElement(OverlayTrigger, { placement: "top", delay: { show: 150, hide: 200 }, overlay: React.createElement(Tooltip, { id: "tooltip-disabled" }, "Upload File") },
                                React.createElement(Button, { className: `uib-button uib-button--primary uib-button--square ${styles.resetButton} ${styles.ButtonLogo}`, onClick: () => this.UploadFileShow() },
                                    React.createElement("i", { className: `uib-icon uib-icon--upload ${styles.logo}` }))))
                        : React.createElement(React.Fragment, null),
                    React.createElement("div", { className: `col col-md-auto ${styles.RButtons}` },
                        React.createElement(OverlayTrigger, { placement: "top", delay: { show: 150, hide: 200 }, overlay: React.createElement(Tooltip, { id: "tooltip-disabled" }, "Download Current View as CSV") },
                            React.createElement(Button, { className: `uib-button uib-button--primary uib-button--square ${styles.ButtonLogo}`, onClick: () => this.download() },
                                React.createElement("i", { className: `uib-icon uib-icon--download ${styles.logo}` })))),
                    React.createElement("div", { className: `col col-md-auto ${styles.numberOfShownItems}` },
                        "Count: ",
                        countAllItems - 1)));
        }
        else {
            return React.createElement("div", { className: styles.noToolbar });
        }
    }
    /**
     * Filter the view by the settings of the filter bar
     */
    filterCurrentView() {
        if (this.state.currentDirectory === undefined) {
            return [];
        }
        // prepare current Directory
        let filteredCurrentDirectory = deepCopy(this.state.currentDirectory);
        // filter by searchfield
        if (this.state.toolbar_SearchField !== "" && this.state.toolbar_SearchField.length >= 3) {
            filteredCurrentDirectory.directories = filteredCurrentDirectory.directories.filter(dir => dir.toLowerCase().includes(this.state.toolbar_SearchField.toLowerCase()));
            filteredCurrentDirectory.files = filteredCurrentDirectory.files.filter(file => file.fileName.toLowerCase().includes(this.state.toolbar_SearchField.toLowerCase()));
        }
        // remove empty files
        filteredCurrentDirectory.files = filteredCurrentDirectory.files.filter(file => file.fileName !== "");
        // sort them by name. if the name is a number, sort by number. the filename contains file extension
        filteredCurrentDirectory.files.sort((a, b) => {
            const aName = a.fileName.split('/').pop().split('.')[0];
            const bName = b.fileName.split('/').pop().split('.')[0];
            const aIsNumber = !isNaN(Number(aName));
            const bIsNumber = !isNaN(Number(bName));
            if (aIsNumber && bIsNumber) {
                return Number(aName) - Number(bName);
            }
            else if (aIsNumber) {
                return -1;
            }
            else if (bIsNumber) {
                return 1;
            }
            else {
                return 0;
            }
        });
        return this.convertToMasonryItems(filteredCurrentDirectory).slice(0, +this.state.toolbar_noResults.value + 1);
    }
    searchField_Reset() {
        this.setState({ toolbar_SearchField: "", toolbar_noResults: this.numberOfResults()[1] });
    }
    FileDetailOpen(file) {
        HubFunctions.showLoading();
        getFileAttributesSingle(file, this.state.directoryType).then((data) => {
            if (data.success) {
                data.data.length = data.data.properties.length;
                this.setState({ fileDetails: data.data, pictureDetail: file, Popup: Popup.PictureDetail });
            }
            else {
                HubFunctions.PopupCreate("Error", data.message);
            }
            HubFunctions.hideLoading();
        });
    }
    deleteFile(path, directoryType) {
        // split the path with / and remove the first element, which is the project name
        let splittedPath = path.split('/').filter(x => x !== "");
        splittedPath = splittedPath.slice(1);
        // join the path again
        path = splittedPath.join('/');
        HubFunctions.PopupCreate("Delete" + directoryType, "Are you sure, " + path + " shall be deleted? There is no undo!", this.handleDeleteFile.bind(this, path, directoryType));
    }
    handleDeleteFile(path, directoryType) {
        HubFunctions.showLoading();
        deleteFile(path, directoryType).then((data) => {
            if (!data.success) {
                HubFunctions.PopupCreate("Error", data.message);
            }
            else {
                this.PopupClose();
                this.refreshDirectory();
                HubFunctions.PopupClose();
            }
            HubFunctions.hideLoading();
        });
    }
    handleDownloadFile(path) {
        HubFunctions.showLoading();
        downloadFile(path).then((data) => {
            if (!data.success) {
                HubFunctions.PopupCreate("Error", data.message);
            }
            else {
                createDownloadObject(data.data, path.split('/').pop());
            }
            HubFunctions.hideLoading();
        });
    }
    FileDetail() {
        var _a;
        let Infos;
        let downloadButtons;
        let deleteButton = React.createElement(Button, { className: `uib-button uib-button--primary ${styles.spaceRight} ${styles.errorButton}`, onClick: () => {
                this.deleteFile(this.state.fileDetails.fileName, this.state.directoryType);
            } }, "Delete");
        if (this.state.directoryType == FolderImage) {
            Infos = React.createElement(React.Fragment, null,
                React.createElement(Row, { className: styles.popupSpaceBetween },
                    React.createElement(Col, { md: 3, className: "align-self-center" }, "Picture"),
                    React.createElement(Col, null,
                        React.createElement("img", { className: styles.imgDetails, src: this.state.fileDetails.requestedUrl, alt: "picture" }))),
                React.createElement(Row, { className: styles.popupSpaceBetween },
                    React.createElement(Col, { md: 3, className: "align-self-center" }, "Available Solutions"),
                    React.createElement(Col, null, this.state.fileDetails.availableResolutions.join(', '))));
            downloadButtons = React.createElement(React.Fragment, null,
                React.createElement(DropdownButton, { className: `${styles.dropdown} ${styles.spaceRight}`, id: "dropdown-basic-button", title: "Download resolution" }, this.state.fileDetails.availableResolutions.map((res, index) => {
                    return React.createElement(Dropdown.Item, { key: index, onClick: () => downloadFile(res + '/' + this.state.fileDetails.fileName) }, res);
                })),
                React.createElement(DropdownButton, { className: `${styles.dropdown}`, id: "dropdown-basic-button", title: "Copy link" }, this.state.fileDetails.availableResolutions.map((res, index) => {
                    return React.createElement(Dropdown.Item, { key: index, onClick: () => navigator.clipboard.writeText(BlobURLBasic + res + '/' + this.state.fileDetails.fileName) }, res);
                })));
        }
        else {
            Infos = React.createElement(Row, { className: styles.popupSpaceBetween },
                React.createElement(Col, { md: 3, className: "align-self-center" }, "File size"),
                React.createElement(Col, null, // round to 2 decimal places
                    Math.round((this.state.fileDetails.length / 1024 / 1024) * 100) / 100,
                    " MB"));
            downloadButtons = React.createElement(React.Fragment, null,
                React.createElement(Button, { className: `uib-button uib-button--primary ${styles.spaceRight}`, onClick: () => {
                        this.handleDownloadFile("documents/" + this.state.fileDetails.fileName);
                    } }, "Download"),
                React.createElement(Button, { className: `uib-button uib-button--primary ${styles.spaceRight}`, onClick: () => {
                        navigator.clipboard.writeText(BlobDocuments + this.state.fileDetails.fileName);
                    } }, "Copy Link"));
        }
        return React.createElement("div", null,
            React.createElement(ModalHeader, null, "Details"),
            React.createElement(ModalBody, null,
                React.createElement(Container, null,
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 3, className: "align-self-center" }, "File Name"),
                        React.createElement(Col, null, (_a = this.state.fileDetails.fileName) === null || _a === void 0 ? void 0 : _a.toString().split('/').pop())),
                    Infos,
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 3, className: "align-self-center" }, "Created"),
                        React.createElement(Col, null, this.state.fileDetails.properties.created)),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 3, className: "align-self-center" }, "Last Modified"),
                        React.createElement(Col, null, this.state.fileDetails.properties.lastModified)))),
            React.createElement(ModalFooter, null,
                React.createElement("div", { className: styles.flexgrow },
                    isUserroleSufficient(UserRoles.editor, HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId) ? deleteButton : React.createElement(React.Fragment, null),
                    downloadButtons),
                React.createElement(Button, { className: `uib-button uib-button--secondary ${styles.spaceRight}`, onClick: () => { this.PopupClose(); } }, "Close")));
    }
    UploadFileShow() {
        this.setState({ Popup: Popup.UploadFile, uploadPath: this.state.path.slice(1).join('/') + '/', fileSelectedForUpload: undefined });
    }
    UploadFIleContent() {
        let submitButton = React.createElement(Button, { className: "uib-button uib-button--primary", onClick: () => { this.UploadFileSubmit(); } }, "Submit");
        // check if all fields are filled
        if (this.state.uploadPath == "" || this.state.fileSelectedForUpload == undefined) {
            submitButton = React.createElement(OverlayTrigger, { placement: "top", delay: { show: 10, hide: 200 }, overlay: React.createElement(Tooltip, { id: "tooltip-disabled" }, "Please make sure all fields are filled.") },
                React.createElement(Button, { className: `uib-button uib-button--primary ${styles.warningButton}` }, "Submit"));
        }
        return React.createElement(React.Fragment, null,
            React.createElement(ModalHeader, null, "Upload File"),
            React.createElement(ModalBody, null,
                React.createElement(Container, null,
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 3, className: "align-self-center" }, "Path"),
                        React.createElement(Col, null,
                            React.createElement(FormGroup, { className: "mb-3", controlId: "exampleForm.ControlInput1" },
                                React.createElement(FormControl, { type: "text", value: this.state.uploadPath, onChange: (event) => this.setState({ uploadPath: event.target.value }) })),
                            React.createElement("div", null, "You can type new paths, if you want to create a new directory. For example /dir, this will result in /dir/file.txt"))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 3, className: "align-self-center" }, "Select File"),
                        React.createElement(Col, null,
                            React.createElement(FormGroup, { controlId: "formFile", className: "mb-3" },
                                React.createElement(FormControl, { type: "file", onChange: (event) => { this.setState({ fileSelectedForUpload: event.target.files[0] }); } })))))),
            React.createElement(ModalFooter, null,
                React.createElement(Button, { className: `uib-button uib-button--secondary ${styles.spaceRight}`, onClick: () => { this.PopupClose(); } }, "Close"),
                submitButton));
    }
    UploadFileSubmit() {
        HubFunctions.showLoading();
        UploadFile(this.state.projectId, this.cleanPath(this.state.uploadPath), this.state.fileSelectedForUpload, this.state.directoryType).then((data) => {
            if (!data.success) {
                HubFunctions.PopupCreate("Error", data.message);
            }
            else {
                this.setState({ Popup: Popup.None, fileSelectedForUpload: undefined, uploadPath: "" });
                this.refreshDirectory();
            }
            HubFunctions.hideLoading();
        });
    }
    cleanPath(path) {
        // if the path exceeds 150 characters, crop the rest
        if (path.length > 150) {
            path = path.substring(0, 150);
        }
        // clean prescending slash
        let ret = path.split('/').filter(x => x !== "").join('/');
        if (ret !== "") {
            ret += '/';
        }
        return ret;
    }
    render() {
        let popupContent = React.createElement("div", null);
        switch (this.state.Popup) {
            case Popup.PictureDetail:
                popupContent = this.FileDetail();
                break;
            case Popup.UploadFile:
                popupContent = this.UploadFIleContent();
                break;
            default:
                break;
        }
        let filteredView = this.filterCurrentView();
        return React.createElement("div", null,
            React.createElement(Modal, { size: "lg", show: this.state.Popup !== Popup.None, onHide: () => this.setState({ Popup: Popup.None }) }, popupContent),
            React.createElement("div", { className: styles.Projectlist },
                this.navigationBar(),
                this.toolbar(),
                React.createElement("div", { className: styles.Masony },
                    React.createElement(Masonry, { columnsCount: 8, gutter: "8px" }, filteredView.map((item, index) => this.card({ data: item }, index))))));
    }
}
