var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import styles from "./tenantSystem.module.css";
import { Button, Form, FormControl, InputGroup } from "react-bootstrap";
import * as React from "react";
import { replaceGuidWithDisplayName } from "../../../Utils/transformer";
import { IconTrash } from "../../../Assets/svgs";
import { HubPathRouting } from "../../../HubFramework/pathBuilder";
import { GlobalDictionary } from "../../../Utils/globalDictionary";
import { listModuleInstances } from "../../../BackendFacade/moduleCalls";
import HubFunctions from "../../../HubFramework/hubFunctions";
import { listProjects } from "../../Hub/BackendFacade/apiCalls";
export function convertAuthlevelToTenantsystem(authlevel, currentProject) {
    let scopes = [];
    Object.keys(authlevel).forEach((key) => {
        scopes.push({ id: scopes.length, scopes: key.split('/'), value: authlevel[key], tmp: "", fullscope: key });
    });
    // add global scope if it does not exist and on hub level
    if (scopes.find(x => x.scopes[0] === "user" && x.scopes.length === 1) === undefined && currentProject === "null") {
        scopes.push({ id: scopes.length, scopes: ["user"], value: "", tmp: "", fullscope: "user" });
    }
    return scopes;
}
/**
 * Used as details popup. Shows all scopes of a user
 * @param tenantSystem
 * @constructor
 */
export const TenantSystemContent = ({ tenantSystem, global }) => {
    const ret = tenantSystem.map((item, index) => {
        // skip global
        if (item.scopes.length === 1 && item.scopes[0] === 'user')
            return null;
        return (React.createElement(InputGroup, { id: 'scopeEntry' + index, key: index, className: styles.scopeLine },
            React.createElement(Button, { disabled: true, className: `btn btn-outline-secondary ${styles.scopeRoot}` }, "User"),
            item.scopes.map((scope, index) => {
                if (scope === 'user' && index === 0)
                    return null;
                return (React.createElement(Button, { disabled: true, key: index, variant: "outline-secondary", id: replaceGuidWithDisplayName(item.scopes, index, global), className: "btn btn-outline-dark region" }, replaceGuidWithDisplayName(item.scopes, index, global)));
            }),
            React.createElement(FormControl, { disabled: true, placeholder: "", value: item.tmp }),
            React.createElement(Button, { disabled: true, className: "btn btn-outline-secondary" }, "Value"),
            React.createElement(FormControl, { disabled: true, placeholder: "undefined", className: styles.tenantValue, id: 'scopeValue' + index, value: item.value })));
    });
    return React.createElement(React.Fragment, null, ret);
};
export const GlobalTenantSystemContent = ({ tenantSystem, globalId, }) => {
    return (React.createElement(InputGroup, { className: styles.scopeLine },
        React.createElement(Button, { disabled: true, className: `btn btn-outline-secondary ${styles.scopeRoot}` }, "Global"),
        React.createElement(FormControl, { disabled: true, placeholder: "Global overwrites config of subscopes" }),
        React.createElement(Button, { disabled: true, className: "btn btn-outline-secondary" }, "Value"),
        React.createElement(FormControl, { disabled: true, placeholder: "undefined", id: 'globalValue', className: styles.tenantValue, value: tenantSystem.find((x) => x.id === globalId).value })));
};
export const SetGlobalTenantSystemContent = ({ tenantSystem, globalId, authLevels, defaultAuth, onEditFunction, }) => {
    return (React.createElement(InputGroup, { className: styles.scopeLine },
        React.createElement(Button, { disabled: true, className: `btn btn-outline-secondary ${styles.scopeRoot}` }, "Global"),
        React.createElement(FormControl, { disabled: true, placeholder: "Global overwrites config of subscopes", className: styles.fixHeight }),
        React.createElement(Button, { disabled: true, className: "btn btn-outline-secondary" }, "Value"),
        React.createElement(Form.Select, { className: `${styles.tenantValueGlobal} ${styles.fixHeight}`, onChange: (event) => {
                onEditFunction(globalId, event.target.value);
            }, id: 'globalId', value: tenantSystem.find((x) => x.id === globalId).value === ''
                ? defaultAuth
                : tenantSystem.find((x) => x.id === globalId).value }, authLevels.map((item, nkey) => {
            return (React.createElement("option", { value: item, key: nkey }, item));
        }))));
};
export class UserTenantManagement extends React.Component {
    constructor(props) {
        super(props);
        this.staticModules = [];
        this.state = {
            possibleScopes: [],
            wait: this.props.listOfIDMSSubscopes.length === 0
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            // non IDMS
            if (this.props.listOfIDMSSubscopes.length === 0) {
                yield this.loadAllPossibleScopes(this.props.global);
            }
        });
    }
    loadAllPossibleScopes(global) {
        return __awaiter(this, void 0, void 0, function* () {
            let wait = false;
            if (GlobalDictionary.get(GlobalDictionary.PROJECTS) === undefined) {
                wait = true;
            }
            else if (!global && GlobalDictionary.get(HubPathRouting.currentProject.moduleInstanceId + GlobalDictionary.MODULEINSTANCES) === undefined) {
                wait = true;
            }
            else {
                this.setState({ wait: false });
            }
            let promises = [];
            // all projects
            promises.push(listProjects().then((data) => {
                if (data.success) {
                    GlobalDictionary.set(GlobalDictionary.PROJECTS, data.data.value);
                }
                else {
                    HubFunctions.PopupCreate("Error", data.message);
                }
            }));
            // all modules of the project
            if (!global) {
                promises.push(listModuleInstances(HubPathRouting.currentProject.moduleInstanceId).then((data) => {
                    if (data.success) {
                        let modules = data.data.value;
                        GlobalDictionary.set(HubPathRouting.currentProject.moduleInstanceId + GlobalDictionary.MODULEINSTANCES, modules);
                    }
                    else {
                        HubFunctions.PopupCreate("Error", data.message);
                    }
                }));
            }
            else {
                if (GlobalDictionary.get(GlobalDictionary.PROJECTS) === undefined) {
                    yield Promise.all(promises);
                }
                wait = true;
                promises = [];
                GlobalDictionary.get(GlobalDictionary.PROJECTS).forEach((project) => {
                    promises.push(listModuleInstances(project.projectId).then((data) => {
                        if (data.success) {
                            let modules = data.data.value;
                            GlobalDictionary.set(project.projectId + GlobalDictionary.MODULEINSTANCES, modules);
                        }
                        else {
                            HubFunctions.PopupCreate("Error", data.message);
                        }
                    }));
                });
            }
            this.staticModules = yield GlobalDictionary.getStaticModules();
            if (wait) {
                yield Promise.all(promises);
                this.setState({ wait: false });
            }
        });
    }
    /**
     * removes part of the scope from the config
     * @param id Id of the scope line
     * @param scope scope value
     */
    deleteScopePart(id, scope) {
        let tenants = this.props.tenantSystem;
        let tenantIndex = tenants.findIndex(x => x.id === id);
        // Ensure that the tenant with the given ID was found.
        if (tenantIndex !== -1) {
            let scopes = tenants[tenantIndex].scopes;
            // Find the index of the last occurrence of the scope.
            let lastScopeIndex = scopes.lastIndexOf(scope);
            // Ensure that the scope was found.
            if (lastScopeIndex !== -1) {
                // Remove the last occurrence of the scope from the scopes array.
                scopes.splice(lastScopeIndex, 1);
                tenants[tenantIndex].scopes = scopes;
            }
        }
        this.props.setTenantSystemFunction(tenants);
        // let tenants = this.props.tenantSystem;
        // debugger
        // tenants.find(x => x.id === id).scopes = tenants.find(x => x.id === id).scopes.filter((items) => items !== scope)
        // this.props.setTenantSystemFunction(tenants)
    }
    EditScopeName(id, value) {
        let tenantLines = this.props.tenantSystem;
        tenantLines.find(x => x.id === id).tmp = value;
        this.props.setTenantSystemFunction(tenantLines);
    }
    /**
     * returns a list of all possible scopes with display name which are used so far
     * @param scope
     * @param tenantId used for non IDMS. id == 1 -> project level, id == 2 -> module level
     */
    returnListOfPossibleScopes(scope, tenantId) {
        var _a;
        if (this.state.wait) {
            return [];
        }
        // non IDMS
        if (this.props.listOfIDMSSubscopes.length === 0) {
            const tenant = this.props.tenantSystem.find(x => x.id === tenantId);
            // project level scope
            if (tenant.scopes.length === 1) {
                const instances = GlobalDictionary.get(GlobalDictionary.PROJECTS);
                return instances.filter(x => x.displayName.toLowerCase().indexOf(scope.toLowerCase()) !== -1).map(x => {
                    return { id: x.projectId, displayName: x.displayName };
                });
            }
            // module level scope
            if (tenant.scopes.length === 2) {
                let instances = [];
                if (this.props.global) {
                    instances = GlobalDictionary.get(tenant.scopes[1] + GlobalDictionary.MODULEINSTANCES);
                }
                else {
                    instances = GlobalDictionary.get(HubPathRouting.currentProject.moduleInstanceId + GlobalDictionary.MODULEINSTANCES);
                }
                return instances === null || instances === void 0 ? void 0 : instances.filter(x => x.displayName.toLowerCase().indexOf(scope.toLowerCase()) !== -1).map(x => {
                    return { id: x.moduleInstanceId, displayName: x.displayName };
                });
            }
            // scope inside module
            if (tenant.scopes.length === 3) {
                // find the current module
                let instances = [];
                if (this.props.global) {
                    instances = GlobalDictionary.get(tenant.scopes[1] + GlobalDictionary.MODULEINSTANCES);
                }
                else {
                    instances = GlobalDictionary.get(HubPathRouting.currentProject.moduleInstanceId + GlobalDictionary.MODULEINSTANCES);
                }
                const currentModule = instances === null || instances === void 0 ? void 0 : instances.find(x => x.moduleInstanceId === tenant.scopes[2]).staticModuleId;
                const scopes = (_a = this.staticModules.find(x => x.staticModuleId === currentModule)) === null || _a === void 0 ? void 0 : _a.endpoints;
                if (scopes === undefined || scopes === null) {
                    return [];
                }
                return Object.keys(scopes).map(x => {
                    return { id: x, displayName: x };
                });
            }
        }
        // IDMS
        else {
            return this.props.listOfIDMSSubscopes.filter(x => x.toLowerCase().indexOf(scope.toLowerCase()) !== -1).map(x => {
                return { id: x, displayName: x };
            });
        }
        return [];
    }
    /**
     * Add a new part to a scope
     * @param id Id of the scope line
     * @param scope scope value
     */
    addScopePart(id, scope) {
        if (scope === "") {
            return;
        }
        let tenants = this.props.tenantSystem;
        // if scope does not exist in config, create it
        if (tenants.find(x => x.id === id).scopes.length === 0)
            tenants.find(x => x.id === id).scopes.push("user");
        tenants.find(x => x.id === id).scopes.push(scope);
        tenants.find(x => x.id === id).tmp = "";
        tenants.find(x => x.id === id).fullscope = tenants.find(x => x.id === id).scopes.join("/");
        this.props.setTenantSystemFunction(tenants);
    }
    /**
     * removes a complete scope from the config
     * @param id id of the scope line
     */
    deleteScopeFull(id) {
        let tenants = this.props.tenantSystem;
        tenants = tenants.filter((items) => items.id !== id);
        this.props.setTenantSystemFunction(tenants);
    }
    /**
     * returns all possible auth levels
     */
    authLevelsOptions() {
        return this.props.authLevels.map((item, nkey) => {
            if (HubPathRouting.currentProject && item === "neutral") {
                return;
            }
            return React.createElement("option", { value: item, key: nkey }, item);
        });
    }
    /**
     * returns all possible scopes
     */
    scopeOptions(id) {
        // First, get the list of possible scopes as an array of option elements
        const scopeOptions = this.returnListOfPossibleScopes("", id).map((item, nkey) => {
            return React.createElement("option", { value: item.id, key: nkey }, item.displayName);
        });
        // Next, create a placeholder option element
        const placeholderOption = React.createElement("option", { value: "", key: "placeholder" }, "---");
        // Finally, add the placeholder to the beginning of the options array
        return [placeholderOption, ...scopeOptions];
    }
    /**
     * Edit the value of a User scope
     * @param id id of the scope line
     * @param value value
     */
    EditScopeValue(id, value) {
        let tenantLines = this.props.tenantSystem;
        tenantLines.find(x => x.id === id).value = value;
        this.props.setTenantSystemFunction(tenantLines);
    }
    render() {
        return React.createElement(React.Fragment, null, this.props.tenantSystem.map((item, index) => {
            // skip global
            if (item.scopes.length === 1 && item.scopes[0] === "user" && item.fullscope === "user") {
                return null;
            }
            let placeholder = "Scope";
            if (item.scopes.length === 1) {
                placeholder = "Project";
            }
            else if (item.scopes.length === 2) {
                placeholder = "Module";
            }
            return React.createElement(InputGroup, { key: index, className: styles.scopeLine },
                React.createElement(Button, { disabled: true, className: `btn btn-outline-secondary ${styles.scopeRoot}` }, "User"),
                item.scopes.map((scope, index2) => {
                    // skip global level
                    if (scope === "user" && index2 === 0) {
                        return null;
                    }
                    // if called from within project, project scope cannot be deleted
                    if (scope === this.props.projectId && index2 === 1) {
                        return React.createElement(Button, { key: index2, variant: "outline-secondary", className: "btn btn-outline-secondary region" }, replaceGuidWithDisplayName(item.scopes, 1, this.props.global));
                    }
                    // only the last element in item.scopes shall have the delete button
                    if (index2 === item.scopes.length - 1) {
                        return (React.createElement(Button, { key: index2, variant: "outline-secondary", className: "btn btn-outline-secondary region", id: "delete" + item.id, onClick: () => this.deleteScopePart(item.id, scope) },
                            replaceGuidWithDisplayName(item.scopes, index2, this.props.global),
                            " ",
                            IconTrash));
                    }
                    else {
                        return (React.createElement(Button, { key: index2, variant: "outline-secondary", className: "btn btn-outline-secondary region" }, replaceGuidWithDisplayName(item.scopes, index2, this.props.global)));
                    }
                }),
                this.props.useSelectOptions ?
                    React.createElement(Form.Select, { className: ` ${styles.fixHeight}`, onChange: (event) => {
                            this.EditScopeName(item.id, event.target.value);
                        }, id: "scopeProjectValue" + item.id, value: item.tmp }, this.scopeOptions(item.id))
                    :
                        React.createElement(React.Fragment, null,
                            React.createElement(FormControl, { className: styles.fixHeight, placeholder: placeholder, value: item.tmp, onChange: (event) => {
                                    this.EditScopeName(item.id, event.target.value);
                                    this.setState({ possibleScopes: this.returnListOfPossibleScopes(event.target.value, item.id) });
                                }, onKeyPress: event => {
                                    if (event.key === 'Enter') {
                                        this.addScopePart(item.id, item.tmp);
                                    }
                                }, list: "id" + item.id, id: "scope" + item.id }), // possible projects/modules/scopes
                            this.state.possibleScopes.length > 0 &&
                                React.createElement("datalist", { id: "id" + item.id }, this.state.possibleScopes.map((scope, index) => {
                                    return React.createElement("option", { key: index, value: scope.id }, scope.displayName);
                                }))),
                item.tmp === "" ?
                    React.createElement(Button, { variant: "outline-secondary", className: "btn btn-outline-secondary" }, "Add") :
                    React.createElement(Button, { variant: "outline-primary", className: `btn btn-outline-primary ${styles.warningButton}`, id: "add" + item.id, onClick: () => this.addScopePart(item.id, item.tmp) }, "Add"),
                React.createElement(Form.Select, { className: `${styles.tenantValue} ${styles.fixHeight}`, onChange: (event) => {
                        this.EditScopeValue(item.id, event.target.value);
                    }, id: "scopeValue" + item.id, value: this.props.tenantSystem.find(x => x.id === item.id).value }, this.authLevelsOptions()),
                React.createElement(Button, { variant: "outline-secondary", className: "btn btn-outline-secondary region", onClick: () => this.deleteScopeFull(item.id) }, IconTrash));
        }));
    }
}
