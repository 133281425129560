import * as React from "react";
import { Table } from "react-bootstrap";
import { getListOfAllModules } from "../../../Utils/modules";
export class AllModulePageInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    tableOfModules() {
        let mods = getListOfAllModules();
        return React.createElement(React.Fragment, null,
            React.createElement(Table, { striped: true, bordered: true, hover: true },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "Title"),
                        React.createElement("th", null, "Module Name"),
                        React.createElement("th", null, "Pages"),
                        React.createElement("th", null, "Default Page"))),
                React.createElement("tbody", null, Object.keys(mods).map((key, index) => {
                    return React.createElement("tr", { key: key },
                        React.createElement("td", null, mods[key].moduleContent.title),
                        React.createElement("td", null, mods[key].moduleContent.moduleName),
                        React.createElement("td", null, mods[key].moduleContent.pages.filter((i) => i.displayName !== "").map((i) => i.displayName).join(', ')),
                        React.createElement("td", null, mods[key].moduleContent.defaultPage));
                }))));
    }
    render() {
        return React.createElement(React.Fragment, null,
            React.createElement("p", null, " This page contains infos about all modules in DevSecOps Hub."),
            React.createElement("br", null),
            this.tableOfModules());
    }
}
