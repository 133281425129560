import { useState } from 'react';
import styles from './ApiKeyField.module.css';
import { Button, FormControl } from "react-bootstrap";
import * as React from 'react';
const ApiKeyField = ({ keyString }) => {
    const [hidden, setHidden] = useState(true);
    const [copied, setCopied] = useState(false);
    const handleCopy = () => {
        navigator.clipboard.writeText(keyString);
        setCopied(true);
    };
    const handleReveal = () => {
        setHidden(!hidden);
    };
    return (React.createElement("div", { className: styles.inputContainer },
        React.createElement(FormControl, { type: hidden ? "password" : "text", value: keyString, readOnly: true, className: styles.keyInput }),
        React.createElement(Button, { onClick: handleCopy, className: `uib-button uib-button--primary ${styles.spaceLeft} ` }, copied ? 'Copied' : 'Copy'),
        React.createElement(Button, { onClick: handleReveal, className: `uib-button uib-button--primary ${styles.spaceLeft}` }, hidden ? 'Reveal' : 'Hide')));
};
export default ApiKeyField;
