var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getProject } from "../BackendFacade/apiCalls";
import { listModuleInstances } from "../../../BackendFacade/moduleCalls";
import HubFunctions from "../../../HubFramework/hubFunctions";
export function getDisplayNameByModuleInstanceId(moduleInstanceId) {
    return __awaiter(this, void 0, void 0, function* () {
        return getProject(moduleInstanceId).then((response) => {
            if (response.success === false) {
                return "";
            }
            return response.data.value.displayName;
        });
    });
}
export function getModuleInstanceDisplayName(projectId, moduleInstanceId) {
    return __awaiter(this, void 0, void 0, function* () {
        return listModuleInstances(projectId).then((response) => {
            if (response.success === false) {
                return "";
            }
            let modules = response.data.value;
            let module = modules.find((module) => module.moduleInstanceId === moduleInstanceId);
            return module.displayName;
        });
    });
}
export function getLinkedId(projectId, moduleInstanceId) {
    return __awaiter(this, void 0, void 0, function* () {
        return listModuleInstances(projectId).then((response) => {
            if (response.success === false) {
                HubFunctions.PopupCreate("Error", response.message);
                return {};
            }
            let modules = response.data.value;
            let module = modules.find((module) => module.moduleInstanceId === moduleInstanceId);
            return module.linkedId;
        });
    });
}
