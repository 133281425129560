import * as React from "react";
export const DisableTenantManagement = "DisableTenantManagement";
export const authLevels = "authLevels";
export const defaultAuth = "defaultAuth";
export const AppDisplayName = "AppDisplayName";
// all config keys
export const SignUpWithExceptionAuth = "SignUpWithExceptionAuth";
export const SignUpWithDefaultAuth = "SignUpWithDefaultAuth";
export const SignUpEventAPI = "SignUpEventAPI";
export const userTenant = "userTenant";
// icons
export const IconEdit = React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", fill: "currentColor", className: "bi bi-pencil-square", viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" }),
    React.createElement("path", { fillRule: "evenodd", d: "M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" }));
export const IconDelete = React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", fill: "currentColor", className: "bi bi-trash", viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" }),
    React.createElement("path", { fillRule: "evenodd", d: "M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" }));
export const IconTrash = React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", fill: "currentColor", className: "bi bi-trash", viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" }),
    React.createElement("path", { fillRule: "evenodd", d: "M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" }));
export const configDescriptionsTexts = {
    "AppDisplayName": ["Readable display-name of the app. Has no impact on functionality."],
    "defaultAuth": ["The initial default user-level that new users have when first using the app."],
    "authLevels": ["The available authLevels / user-roles for this app.", "Comma-separated list."],
    "groupAuthLevels": ["Roles for members of a given Azure AD group", "Semicolon-separated key=value pair list, where key is groupID and value is the members new user role. Explicit user role for a certain user has priority if present."],
    "customDefaultAuthRules": ["Exceptions for the default user role for certain types of users", "Semicolon-separated key=value pair list, where key is a RegEx expression for the users eMail and value is the new default role for fitting users."],
    "SignUpWithExceptionAuth": ["Event: New user signs in to the app and received a custom default role", "See customDefaultAuthRules."],
    "SignUpWithDefaultAuth": ["Event: New user signs in to the app and received no custom default role", "See customDefaultAuthRules."],
    "SignUpEventAPI": ["Event: New user signs in to the app."],
    "SignUpGroups": ["Add users to certain AD groups upon their first login", "Semicolon-separated key=value pair list, where key is role (or *) and value is the groupID that the user will be added to upon first login"],
    "SignUpGroups_OnPrem": ["Add users to certain ON-PREM AD groups upon their first login", "Semicolon-separated key=value pair list, where key is role (or *) and value is the groupID that the user will be added to upon first login"],
    "OverwriteWithGroups": ["Do not use base role system. Instead, always return the AD groups of the user in the authLevel JWT claim."],
    "userTenant": ["Azure tenant name of the app and its userbase. Default is draegerConnect for development, draegerB2C for prod."],
    "DisableTenantManagement": ["Set to true to use the old user rights management."],
    "clientId": ["The client Id is used for the login. This ID does not exactly match the original B2C-ID, as dashes and starting characters might be different. See the Documentation for more information."]
};
export const ScopeAny = "any";
export const ValueAny = "any";
