var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Button, Col, Container, ListGroup, Modal, ModalBody, ModalFooter, Row, Tab, TabContent, } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { CircleLoader } from "react-spinners";
import "@uib/css/themes/draeger-theme-bright.css";
import "@uib/icons/css/uib-iconfont.css";
import { evaluateTokens, logoutButton, openLoginPage, showUserAccount, validateToken } from "../Utils/authentication";
import { Popup } from "./IHub";
import { Breadcrumbs, HubPathRouting, switchModule } from "./pathBuilder";
import { isUserroleSufficient } from "../Utils/authorization";
import { cleansePageName } from "../Utils/transformer";
import styles from './HubFramework.module.css';
import { createBrowserHistory } from 'history';
import { handleRouting } from "../Utils/routing";
import { HubFunctionType } from "./hubFunctions";
import HubFunctions from "./hubFunctions";
export class HubFramework extends React.Component {
    constructor(props) {
        super(props);
        this.history = createBrowserHistory();
        this.handleLocationChange = (update) => {
            const { location, action } = update;
            if (action === 'PUSH') {
                this.setState({ locationKeys: [location.key] });
            }
            if (action === 'POP') {
                if (this.state.locationKeys[1] === location.key) {
                    this.setState((prevState) => ({
                        locationKeys: prevState.locationKeys.slice(1),
                    }));
                    // Handle forward event
                }
                else {
                    this.setState((prevState) => ({
                        locationKeys: [location.key, ...prevState.locationKeys],
                    }));
                    // Handle back event
                    handleRouting();
                    let tab = HubPathRouting.currentPage.displayName;
                    let newPage = this.props.hubContent.pages.filter((p) => p.displayName === tab || p.url === tab)[0];
                    this.setState({ currentPage: newPage });
                }
            }
        };
        // wrapper for hub functions
        this.hubFunctions = {
            // uses full width of the popup
            PopupCreateSingle: (header, content, submitFunction) => this.PopupCreateSingle(header, content, submitFunction),
            // contains a "Message" label on the left side
            PopupCreate: (header, content, submitFunction) => this.PopupCreate(header, content, submitFunction),
            PopupClose: () => this.PopupClose(),
            showLoading: () => this.showLoading(),
            hideLoading: () => this.hideLoading(),
            switchModule: (moduleName, idName, displayName, page, linkedId) => switchModule(moduleName, {
                moduleInstanceId: idName,
                MiiDisplayname: displayName,
                page: page,
                linkedId: linkedId
            }),
            updateUrl: () => this.updateUrl(),
        };
        // if the module is called with a given page, set the current page to that page
        // otherwise set the current page to the default page
        let currentPage = props.hubContent.pages.filter((p) => p.displayName === props.hubContent.defaultPage)[0];
        if (props.module.props.hubArgs
            && props.module.props.hubArgs.page
            && props.hubContent.pages.find((p) => (p.displayName) === (props.module.props.hubArgs.page))) {
            currentPage = props.hubContent.pages.filter((p) => (p.displayName) === (props.module.props.hubArgs.page))[0];
        }
        // if default page is undefined (not found), set it to the first page
        if (!currentPage) {
            currentPage = props.hubContent.pages[0];
        }
        this.state = {
            Popup: Popup.None,
            Loading: false,
            currentPage: currentPage,
            PopupHeader: "",
            PopupMessage: "",
            hubContent: props.hubContent,
            title: props.hubContent.title,
            defaultPage: props.hubContent.defaultPage,
            module: props.module,
            PopupSubmitFunction: () => { },
            loggedIn: false,
            locationKeys: [],
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            // check login
            yield this.checkLogin();
            // register hub functions to be globally available
            this.registerFunctions();
            // switch to the current page
            yield this.setTabState(this.state.currentPage.displayName);
            this.unlisten = this.history.listen(this.handleLocationChange);
        });
    }
    componentWillUnmount() {
        if (this.unlisten) {
            this.unlisten();
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        // check if a page is set
        if (!HubPathRouting.currentPage) {
            this.setTabState(this.state.defaultPage);
        }
    }
    /**
     * Login process
     */
    checkLogin() {
        return __awaiter(this, void 0, void 0, function* () {
            let validateState;
            let evaluateState;
            // evaluate tokens and check for new tokens in url
            yield evaluateTokens().then((e) => { evaluateState = e; });
            // validate existing tokens. Tokens might be set in evaluateTokens, so await this first
            validateState = validateToken();
            if (evaluateState.error !== "" || validateState.error !== "") {
                this.setState({ Popup: Popup.Error, PopupMessage: evaluateState.error + validateState.error });
            }
            else if (!evaluateState.success || !validateState.success) {
                openLoginPage();
                this.setState({ loggedIn: false });
            }
            else {
                this.setState({ loggedIn: true });
            }
        });
    }
    setTabState(tab) {
        return __awaiter(this, void 0, void 0, function* () {
            let newPage = this.props.hubContent.pages.filter((p) => p.displayName === tab || p.url === tab)[0];
            if (!newPage) {
                this.setState({ Popup: Popup.Error, PopupMessage: "Tab not found. Please look into module config." });
                return;
            }
            HubPathRouting.currentPage = { moduleName: this.state.hubContent.moduleName, displayName: newPage.displayName };
            this.updateUrl();
            // set the new page
            this.setState({ currentPage: newPage });
            yield new Promise(r => setTimeout(r, 1));
        });
    }
    /**
     * Update the url to the current page
     */
    updateUrl() {
        // on push on history if it is not the last element
        if ('/' + this.history.location.hash !== HubPathRouting.getPathAsUrl()) {
            this.history.push(HubPathRouting.getPathAsUrl());
        }
    }
    /**
     * Create a popup with a header and content. On the left side is a label "Message"
     * @param header headline of the popup
     * @param content content of the popup
     * @param submitFunction function to be called when the submit button is pressed
     * @constructor
     */
    PopupCreate(header, content, submitFunction) {
        this.setState({ Popup: Popup.Other, PopupHeader: header, PopupMessage: content, PopupSubmitFunction: submitFunction });
    }
    /**
     * Create a popup with a header and content. The content will be full width
     * @param header headline of the popup
     * @param content content of the popup
     * @param submitFunction function to be called when the submit button is pressed
     * @constructor
     */
    PopupCreateSingle(header, content, submitFunction) {
        this.setState({ Popup: Popup.OtherFull, PopupHeader: header, PopupMessage: content, PopupSubmitFunction: submitFunction });
    }
    /**
     * Create a popup with a header and content
     * @param header headline of the popup
     * @param content content of the popup
     * @param submitFunction function to be called when the submit button is pressed
     * @param single if true, the popup will be full width, otherwise it will be half width
     * @constructor
     */
    PopupContent(header, content, submitFunction, single = false) {
        return React.createElement("div", { id: "Popup" },
            React.createElement(ModalHeader, { id: "PopupHeader", closeButton: true }, header),
            React.createElement(ModalBody, { className: styles.popupBody },
                React.createElement(Container, null,
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        !single && React.createElement(Col, { md: 3, className: "align-self-center" }, "Message"),
                        React.createElement(Col, null,
                            React.createElement(Row, { id: "PopupContent" }, content))))),
            React.createElement(ModalFooter, null,
                React.createElement(Button, { className: `uib-button uib-button--secondary ${styles.spaceRight}`, onClick: () => this.PopupClose() }, "Close"),
                submitFunction != null && React.createElement(Button, { className: "uib-button uib-button--primary", onClick: () => { submitFunction(); this.PopupClose(); } }, "Submit")));
    }
    showLoading() {
        return __awaiter(this, void 0, void 0, function* () {
            this.setState({ Loading: true });
            // timeout to make sure the state is set before the next function is called
            yield new Promise(r => setTimeout(r, 1));
        });
    }
    hideLoading() {
        return __awaiter(this, void 0, void 0, function* () {
            this.setState({ Loading: false });
            // timeout to make sure the state is set before the next function is called
            yield new Promise(r => setTimeout(r, 1));
        });
    }
    registerFunctions() {
        HubFunctions.registerFunction(this.hubFunctions.PopupCreateSingle, HubFunctionType.PopupCreateSingle);
        HubFunctions.registerFunction(this.hubFunctions.PopupCreate, HubFunctionType.PopupCreate);
        HubFunctions.registerFunction(this.hubFunctions.PopupClose, HubFunctionType.PopupClose);
        HubFunctions.registerFunction(this.hubFunctions.showLoading, HubFunctionType.showLoading);
        HubFunctions.registerFunction(this.hubFunctions.hideLoading, HubFunctionType.hideLoading);
        HubFunctions.registerFunction(this.hubFunctions.updateUrl, HubFunctionType.updateUrl);
        HubFunctions.registerFunction(this.hubFunctions.switchModule, HubFunctionType.switchModule);
    }
    PopupClose() {
        this.setState({ Popup: Popup.None });
    }
    createMenuLinkUrl(page) {
        return window.location.origin + HubPathRouting.getPathForNavigation() + '/' + cleansePageName(page.displayName);
    }
    render() {
        // ---- Popup -----
        let popupContent = React.createElement("div", null);
        switch (this.state.Popup) {
            case Popup.Error:
                popupContent = this.PopupContent("Error", this.state.PopupMessage);
                break;
            case Popup.Other:
                popupContent = this.PopupContent(this.state.PopupHeader, this.state.PopupMessage, this.state.PopupSubmitFunction);
                break;
            case Popup.OtherFull:
                popupContent = this.PopupContent(this.state.PopupHeader, this.state.PopupMessage, this.state.PopupSubmitFunction, true);
                break;
            default:
                break;
        }
        let loading = React.createElement("div", null);
        if (this.state.Loading) {
            loading = React.createElement("div", { className: styles.loading, id: "loadingSpinner" },
                React.createElement("div", { className: styles.spinner },
                    React.createElement(CircleLoader, { size: 120, color: "#0074ff", loading: true })));
        }
        return React.createElement(Tab.Container, { id: styles.main, activeKey: this.state.currentPage.displayName, onSelect: (key) => this.setTabState(key), unmountOnExit: true },
            React.createElement("div", { id: styles.header, className: `no-gutters row ${styles["flex-row"]} ${styles["flex-grow"]}` },
                React.createElement("span", { id: `${styles.logo}`, className: `uib-logo ${styles["flex-row"]}` }),
                React.createElement("span", { id: styles.path, className: `${styles["flex-row"]} ${styles["flex-grow"]}` },
                    React.createElement(Breadcrumbs, null)),
                React.createElement("span", { id: styles.username, className: `${styles["flex-row"]}` }, showUserAccount()),
                React.createElement("span", { id: styles.logoutHeader, className: `${styles["flex-row"]}` }, logoutButton())),
            React.createElement(Modal, { size: "lg", show: this.state.Popup !== Popup.None, onHide: () => this.PopupClose() }, popupContent),
            loading,
            React.createElement(Row, { className: styles.mainScreen },
                React.createElement(Col, { lg: 2, className: styles.newleftSideBarMenu },
                    React.createElement(ListGroup, { className: styles.listGroup }, this.state.hubContent.pages.map((page) => {
                        var _a;
                        if (isUserroleSufficient(page.minimumRole, (_a = HubPathRouting.currentProject) === null || _a === void 0 ? void 0 : _a.moduleInstanceId))
                            if (page.separator) {
                                return React.createElement(ListGroup.Item, { key: page.displayName, className: styles.navbarSeparator });
                            }
                            else {
                                return React.createElement(ListGroup.Item, { action: true, href: this.createMenuLinkUrl(page), key: page.displayName, eventKey: page.displayName, className: styles.listGroupItem, onClick: (event) => {
                                        event.preventDefault();
                                        this.setTabState(page.displayName);
                                    } }, page.displayName);
                            }
                        else {
                            return null;
                        }
                    }))),
                React.createElement(Col, null, this.state.loggedIn &&
                    React.createElement(TabContent, { className: styles.ScrollPage }, this.state.hubContent.pages.map((page, key) => {
                        var _a;
                        // extra check, so not selected tabs are not rendered
                        if (page.displayName === this.state.currentPage.displayName && isUserroleSufficient(page.minimumRole, (_a = HubPathRouting.currentProject) === null || _a === void 0 ? void 0 : _a.moduleInstanceId)) {
                            return React.createElement(Tab.Pane, { eventKey: page.displayName, key: key, className: styles.Tabpane },
                                React.createElement(page.contentPage, { hubFunctions: this.hubFunctions, parentModule: this.state.module }));
                        }
                        else {
                            return null;
                        }
                    })))));
    }
}
