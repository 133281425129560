export var Popup;
(function (Popup) {
    Popup[Popup["None"] = 0] = "None";
    Popup[Popup["AddModule"] = 1] = "AddModule";
})(Popup || (Popup = {}));
export var PopupMessage;
(function (PopupMessage) {
    PopupMessage["Alert"] = "danger";
    PopupMessage["Info"] = "info";
})(PopupMessage || (PopupMessage = {}));
