var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getRefreshToken, getToken, openLoginPage, setTokensFromHeader } from "Utils/authentication";
import { backendApiURL } from "Config/environmentVars";
import * as Sentry from "@sentry/react";
import { HubPathRouting } from "../HubFramework/pathBuilder";
export function getBackendApiURL() {
    if (backendApiURL.indexOf('{') !== -1) {
        //return "https://devsecopshub-prod-func.azurewebsites.net/api/"
        return window.location.origin + "/api/";
    }
    return backendApiURL;
}
/**
 * Returns the backend api url for the current project and module instance.
 * Useful for calling the backend api from a module.
 */
export function getBackendApiURLModuleInstance() {
    return getBackendApiURL() + "projects/" + HubPathRouting.currentProject.moduleInstanceId + "/modules/" + HubPathRouting.currentModule.moduleInstanceId + "/";
}
/**
 * Main backend facade function to call the backend api. Returns a promise with the response, success and message.
 * @param url The url to call
 * @param method HTTP method: GET, POST, PUT, DELETE, PATCH
 * @param headerAdditions Additional data to be added as "body" to the request's header
 * @param contentType Content-Type of the request's header
 * @param File File to be uploaded
 */
export function fetchFromApi(url, method = "POST" || "GET" || "PUT" || "DELETE" || "PATCH", headerAdditions = null, contentType = "application/json", File = null) {
    return __awaiter(this, void 0, void 0, function* () {
        let headers = {
            method: method,
            headers: {
                "Accept": "application/json",
                "Content-Type": contentType,
                "id_token": getToken(),
                "refresh_token": getRefreshToken(),
                "x-frontend-url": window.location.origin
            }
        };
        if (headerAdditions && contentType === "application/json" && method === "DELETE") {
            // add each element of headerAdditions as a header to the request
            Object.keys(headerAdditions).forEach((data) => {
                headers["headers"][data] = headerAdditions[data];
            });
        }
        if (headerAdditions && contentType === "application/json") {
            headers["body"] = JSON.stringify(headerAdditions);
        }
        if (headerAdditions && contentType === "application/x-www-form-urlencoded") {
            headers["body"] = headerAdditions;
        }
        if (File) {
            headers["headers"] = {
                "id_token": getToken(),
                "refresh_token": getRefreshToken(),
            };
            let formData = new FormData();
            formData.append('File', File, File.name);
            if (headers["body"]) {
                Object.keys(headerAdditions).forEach((data) => {
                    let value = headerAdditions[data];
                    formData.append(data, value);
                });
            }
            headers["body"] = formData;
        }
        try {
            return fetch(url, headers).then(function (response) {
                return __awaiter(this, void 0, void 0, function* () {
                    let ret = { data: { value: undefined }, message: "", success: true };
                    // case 200, success
                    if (response.status === 200) {
                        ret.data = yield response.json();
                        setTokensFromHeader(response);
                        return ret;
                    }
                    // error or warning case, define success and message
                    ret.success = false;
                    yield response.text().then((item) => ret.message = response.status + ":" + item.toString());
                    // if the keywords 'Error refreshing token' are in the error message, revoke the token
                    // send the user to the login page
                    if (ret.message.indexOf("Error refreshing token") !== -1) {
                        openLoginPage();
                        return ret;
                    }
                    // base level for non 500 error -> Warning level for sentry
                    let level = "warning";
                    // case 5xx, backend error -> Error level for sentry
                    if (response.status >= 500) {
                        level = "error";
                    }
                    // log message and level to sentry
                    Sentry.captureMessage("Message: " + ret.message, level);
                    return ret;
                });
            })
                // return finalised NetworkData Object
                .then(function (response) {
                Sentry.captureMessage("Message: " + response, "info");
                return response;
            })
                // unexpected error, return empty NetworkData Object
                .catch(function (err) {
                let ret = { message: err, success: false, data: { value: undefined } };
                // Log to sentry
                Sentry.captureMessage("Message: " + err, "error");
                console.log("inner catch");
                return ret;
            });
        }
        catch (e) {
            let ret = { message: e, success: false, data: { value: undefined } };
            Sentry.captureMessage("fatal Message: " + e, "error");
            console.log("outer catch");
            return ret;
        }
    });
}
export function accessModuleInstanceData(moduleInstanceId, projectId, endpoint, method) {
    const url = getBackendApiURL() + "projects/" + projectId + "/modules/" + moduleInstanceId + "/" + endpoint;
    return fetchFromApi(url, method);
}
