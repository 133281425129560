/**
 * Sorts the array by the given key.
 * @param list
 * @param key
 * @param reverse
 * @returns
 */
export function sortListByKey(list, key, reverse = false) {
    if (list.length == 0) {
        return list;
    }
    // if listitem is a number
    if (!isNaN(list[0][key])) {
        list.sort((a, b) => {
            if (a[key] < b[key]) {
                return -1;
            }
            if (a[key] > b[key]) {
                return 1;
            }
            return 0;
        });
    }
    else {
        list.sort((a, b) => {
            if (b[key] == undefined) {
                return 1;
            }
            if (a[key] == undefined || a[key].toLowerCase() < b[key].toLowerCase()) {
                return -1;
            }
            if (a[key].toLowerCase() > b[key].toLowerCase()) {
                return 1;
            }
            return 0;
        });
    }
    if (reverse) {
        list.reverse();
    }
    return list;
}
