var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import docuStyles from "../../Documentation/Documentation.module.css";
import { Card, Col, Row } from "react-bootstrap";
import { moduleIcons } from "../../ProjectContent/Icons/Icons";
import styles from "../../ProjectContent/ProjectContent.module.css";
import { GlobalDictionary } from "../../../Utils/globalDictionary";
import { Documentation } from "../../Documentation/Documentation";
import { getDocumentation } from "../../Documentation/BackendFacade/apiCalls";
import HubFunctions from "../../../HubFramework/hubFunctions";
import { CircleLoader } from "react-spinners";
export class ModuleDocumentations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            availableModules: [],
            backgroundLoading: true,
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.LoadModules();
            this.prepareAllModules();
        });
    }
    LoadModules() {
        return __awaiter(this, void 0, void 0, function* () {
            HubFunctions.showLoading();
            this.setState({ availableModules: yield GlobalDictionary.getStaticModules() });
            HubFunctions.hideLoading();
            yield new Promise(r => setTimeout(r, 1));
        });
    }
    prepareAllModules() {
        let listOfPromises = [];
        this.state.availableModules.forEach((module) => {
            if (!GlobalDictionary.get("Docu" + module.staticModuleId)) {
                this.setState({ backgroundLoading: true });
                listOfPromises.push(this.getDocumentation(module.staticModuleId));
            }
        });
        Promise.all(listOfPromises).then(() => {
            this.setState({ backgroundLoading: false });
        });
    }
    getDocumentation(moduleId) {
        return __awaiter(this, void 0, void 0, function* () {
            yield getDocumentation(moduleId).then((response) => {
                if (response.success) {
                    GlobalDictionary.set("Docu" + moduleId, response.data.value);
                }
                else {
                    HubFunctions.PopupCreate("Error", response.message);
                }
            });
        });
    }
    waitForDocumentation(staticModuleId) {
        HubFunctions.showLoading();
        return new Promise((resolve) => {
            const checkDocumentation = () => {
                if (GlobalDictionary.get("Docu" + staticModuleId)) {
                    resolve();
                    HubFunctions.hideLoading();
                }
                else {
                    setTimeout(checkDocumentation, 100);
                }
            };
            checkDocumentation();
        });
    }
    newModuleCards() {
        let cards = [];
        this.state.availableModules
            // sort the groups alphabetically but "other" always last
            .sort((a, b) => {
            return a.displayName.localeCompare(b.displayName);
        })
            .forEach((module, key) => {
            cards.push(React.createElement(Col, { className: "col-3", key: key },
                React.createElement(Card, { className: `${docuStyles.ModuleCard} `, onClick: () => this.activateModule(module), id: module.staticModuleId },
                    React.createElement(Card.Body, null,
                        React.createElement(Card.Title, null,
                            moduleIcons[module.staticModuleId] ?
                                React.createElement(Card.Img, { src: moduleIcons[module.staticModuleId], className: `${docuStyles.ModuleIcon}` }) :
                                React.createElement(Card.Img, { src: moduleIcons["default"], className: `${styles.ModuleIcon}` }),
                            module.displayName),
                        React.createElement(Card.Text, null, module.description)))));
        });
        return React.createElement(React.Fragment, null,
            React.createElement(Row, { lg: 3 }, cards));
    }
    activateModule(module) {
        return __awaiter(this, void 0, void 0, function* () {
            // Wait for the documentation to be loaded
            yield this.waitForDocumentation(module.staticModuleId);
            const header = React.createElement("span", null,
                moduleIcons[module.staticModuleId] ?
                    React.createElement(Card.Img, { src: moduleIcons[module.staticModuleId], className: `${docuStyles.ModuleIcon}` }) :
                    React.createElement(Card.Img, { src: moduleIcons["default"], className: `${styles.ModuleIcon}` }),
                " ",
                module.displayName,
                " ");
            HubFunctions.PopupCreateSingle(header, React.createElement(Documentation, { component: module.staticModuleId }));
        });
    }
    render() {
        let loading = React.createElement(React.Fragment, null);
        if (this.state.backgroundLoading)
            loading = React.createElement("div", { className: docuStyles.DocuHeadlineLoading },
                React.createElement(CircleLoader, { size: 20, color: "#0074ff", loading: true }));
        return React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement("div", { className: docuStyles.DocuHeadline },
                    "Module Documentations",
                    loading),
                "This is the documentation for the modules. Click a module to get a closer look.",
                React.createElement("br", null),
                this.newModuleCards()));
    }
}
