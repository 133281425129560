var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Popup, animatedComponents, convertListOfStringsToOptionsList, } from "../Interfaces/IDOOP";
import { Button, Col, Container, Modal, ModalBody, ModalFooter, Row, Table, } from "react-bootstrap";
import Select from "react-select";
import { sendUserDelete, sendUserData } from "../BackendFacade/apiCalls";
import ModalHeader from "react-bootstrap/ModalHeader";
import styles from './../Doop.module.css';
import HubFunctions from "../../../HubFramework/hubFunctions";
import { isUserroleSufficient } from "../../../Utils/authorization";
import { UserRoles } from "../../../Utils";
import { HubPathRouting } from "../../../HubFramework/pathBuilder";
export class EditUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Popup: Popup.None,
            PopupMessage: "",
            NU_Email: "",
            NU_Name: "",
            NU_canAcceptOrders: false,
            NU_CanInitiateProcess: false,
            NU_isContractor: false,
            NU_isOrderInformed: false,
            NU_Areas: [],
            DU_Name: "",
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            HubFunctions.showLoading();
            let prom1 = this.getProjectMetaData();
            yield Promise.all([prom1]);
            HubFunctions.hideLoading();
        });
    }
    getProjectMetaData() {
        return __awaiter(this, void 0, void 0, function* () {
            let prom1 = this.props.parentModule.getProjectData().then(data => {
                if (!data.success) {
                    HubFunctions.PopupCreate("Error", data.message);
                }
            });
            yield Promise.all([prom1]);
        });
    }
    PopupClose() {
        this.setState({ Popup: Popup.None, PopupMessage: "" });
    }
    EditUserShow(name = "", email = "", canAccept = false, canInit = false, isContractor = false, isOrderInformed = false, areas = []) {
        if (name === "") {
            this.setState({ Popup: Popup.EditUser, NU_Name: "", NU_Email: "", NU_canAcceptOrders: false,
                NU_CanInitiateProcess: false, NU_isContractor: false, NU_isOrderInformed: false, NU_Areas: [] });
        }
        else {
            this.setState({ Popup: Popup.EditUser, NU_Name: name, NU_Email: email, NU_canAcceptOrders: canAccept,
                NU_CanInitiateProcess: canInit, NU_isContractor: isContractor, NU_isOrderInformed: isOrderInformed,
                NU_Areas: convertListOfStringsToOptionsList(areas)
            });
        }
    }
    performPopupAndClose(data) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!data.success) {
                HubFunctions.PopupCreate("Error", data.message);
            }
            else {
                this.PopupClose();
                yield this.props.parentModule.getProjectData();
            }
        });
    }
    submitUser() {
        if (this.state.NU_Name === "" || this.state.NU_Email === "") {
            HubFunctions.PopupCreate("Error", "Name and Email are required");
            return;
        }
        let data = {
            name: this.state.NU_Name,
            email: this.state.NU_Email,
            canAcceptOrders: this.state.NU_canAcceptOrders,
            canInitiateProcess: this.state.NU_CanInitiateProcess,
            isContractor: this.state.NU_isContractor,
            isOrderInformed: this.state.NU_isOrderInformed,
            areas: this.state.NU_Areas.map(x => x.value),
        };
        HubFunctions.showLoading();
        sendUserData(data, this.props.parentModule.state.projectId, this.props.parentModule.state.moduleInstanceId).then(data2 => {
            this.performPopupAndClose(data2).then(() => {
                HubFunctions.hideLoading();
            });
        });
    }
    deleteUserConfirm(name) {
        sendUserDelete(name, this.props.parentModule.state.projectId, this.props.parentModule.state.moduleInstanceId).then(data2 => {
            this.performPopupAndClose(data2).then(() => {
                HubFunctions.hideLoading();
            });
        });
    }
    deleteUserShow(name) {
        this.setState({ Popup: Popup.DeleteUser, DU_Name: name });
    }
    // TODO
    deleteUserContent() {
        return React.createElement("div", null,
            React.createElement(ModalHeader, null, "Delete User"),
            React.createElement(ModalBody, null,
                "Are you sure you want to delete the user ",
                this.state.DU_Name,
                "?"),
            React.createElement(ModalFooter, null,
                React.createElement("button", { className: `uib-button uib-button--secondary ${styles.mr10}`, onClick: () => this.PopupClose() }, "Cancel"),
                React.createElement("button", { className: "uib-button uib-button--primary", onClick: () => this.deleteUserConfirm(this.state.DU_Name) }, "Delete")));
    }
    EditUserContent() {
        return React.createElement("div", null,
            React.createElement(ModalHeader, { closeButton: true }, "User Configuration"),
            React.createElement(ModalBody, null,
                React.createElement(Container, null,
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 3, className: "align-self-center" }, "Name"),
                        React.createElement(Col, null,
                            React.createElement(Container, null,
                                React.createElement(Row, { md: 2 },
                                    React.createElement("div", { className: "uib-form-field__control uib-textfield" },
                                        React.createElement("input", { type: "text", className: "uib-textfield__input", value: this.state.NU_Name, onChange: (e) => this.setState({ NU_Name: e.target.value }) }),
                                        React.createElement("span", { className: "uib-textfield__validation-icon" })))))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 3, className: "align-self-center" }, "Email"),
                        React.createElement(Col, null,
                            React.createElement(Container, null,
                                React.createElement(Row, { md: 2 },
                                    React.createElement("div", { className: "uib-form-field__control uib-textfield" },
                                        React.createElement("input", { type: "text", className: "uib-textfield__input", value: this.state.NU_Email, onChange: (e) => this.setState({ NU_Email: e.target.value }) }),
                                        React.createElement("span", { className: "uib-textfield__validation-icon" })))))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 3, className: "align-self-center" }, "Accessible Areas"),
                        React.createElement(Col, null,
                            React.createElement(Container, null,
                                React.createElement(Row, { md: 2 },
                                    React.createElement("div", { className: "uib-form-field__control uib-textfield" },
                                        React.createElement(Select, { closeMenuOnSelect: false, components: animatedComponents, placeholder: "Select areas. If empty, all are selected", isMulti: true, options: convertListOfStringsToOptionsList(this.props.parentModule.state.Meta.doopAreas), onChange: (option) => {
                                                this.setState({ NU_Areas: option });
                                            }, value: this.state.NU_Areas })))))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 3, className: "align-self-center" }, "Is PO/PM"),
                        React.createElement(Col, null,
                            React.createElement(Container, null,
                                React.createElement(Row, { md: 2 },
                                    React.createElement("label", { className: "uib-checkbox" },
                                        React.createElement("input", { type: "checkbox", className: "uib-checkbox__input", checked: this.state.NU_CanInitiateProcess, onChange: (e) => this.setState({ NU_CanInitiateProcess: e.target.checked }) }),
                                        React.createElement("span", { className: "uib-checkbox__checkmark" })))))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 3, className: "align-self-center" }, "Is Service Manager"),
                        React.createElement(Col, null,
                            React.createElement(Container, null,
                                React.createElement(Row, { md: 2 },
                                    React.createElement("label", { className: "uib-checkbox" },
                                        React.createElement("input", { type: "checkbox", className: "uib-checkbox__input", checked: this.state.NU_canAcceptOrders, onChange: (e) => this.setState({ NU_canAcceptOrders: e.target.checked }) }),
                                        React.createElement("span", { className: "uib-checkbox__checkmark" })))))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 3, className: "align-self-center" }, "Is Contractor"),
                        React.createElement(Col, null,
                            React.createElement(Container, null,
                                React.createElement(Row, { md: 2 },
                                    React.createElement("label", { className: "uib-checkbox" },
                                        React.createElement("input", { type: "checkbox", className: "uib-checkbox__input", checked: this.state.NU_isContractor, onChange: (e) => this.setState({ NU_isContractor: e.target.checked }) }),
                                        React.createElement("span", { className: "uib-checkbox__checkmark" })))))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 3, className: "align-self-center" }, "Is Ext. Service Manager"),
                        React.createElement(Col, null,
                            React.createElement(Container, null,
                                React.createElement(Row, { md: 2 },
                                    React.createElement("label", { className: "uib-checkbox" },
                                        React.createElement("input", { type: "checkbox", className: "uib-checkbox__input", checked: this.state.NU_isOrderInformed, onChange: (e) => this.setState({ NU_isOrderInformed: e.target.checked }) }),
                                        React.createElement("span", { className: "uib-checkbox__checkmark" })))))))),
            React.createElement(ModalFooter, null,
                React.createElement("button", { className: `uib-button uib-button--secondary ${styles.mr10}`, onClick: () => this.setState({ Popup: Popup.None }) }, "Cancel"),
                React.createElement("button", { className: "uib-button uib-button--primary", onClick: () => {
                        this.submitUser();
                    } }, "Save")));
    }
    Users_AreasColumn(areas) {
        // if areas is empty or areas contains the same elements as All areas
        if (areas.length === 0) {
            return React.createElement("div", { className: styles.green }, "All");
        }
        return React.createElement("div", null, areas.join(", "));
    }
    render() {
        var _a, _b;
        let popupContent = React.createElement("div", null);
        switch (this.state.Popup) {
            case Popup.EditUser:
                popupContent = this.EditUserContent();
                break;
            case Popup.DeleteUser:
                popupContent = this.deleteUserContent();
                break;
            default:
                break;
        }
        return React.createElement(React.Fragment, null,
            React.createElement(Modal, { size: "lg", show: this.state.Popup !== Popup.None, onHide: () => this.setState({ Popup: Popup.None }) }, popupContent),
            React.createElement("div", { className: styles.mainScreenHeadline }, "Edit Users"),
            React.createElement(Table, { striped: true, className: styles.maintable },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "Email"),
                        React.createElement("th", null, "Name"),
                        React.createElement("th", null, "Areas"),
                        React.createElement("th", null, "PO/PM"),
                        React.createElement("th", null, "Service Manager"),
                        React.createElement("th", null, "Contractor"),
                        React.createElement("th", null, "Ext. Service Manager"),
                        isUserroleSufficient(UserRoles.editor, (_a = HubPathRouting.currentProject) === null || _a === void 0 ? void 0 : _a.moduleInstanceId, (_b = HubPathRouting.currentModule) === null || _b === void 0 ? void 0 : _b.moduleInstanceId) && React.createElement(React.Fragment, null,
                            React.createElement("th", { className: styles.alignCenter }, "Edit"),
                            React.createElement("th", { className: styles.alignCenter }, "Delete")))),
                React.createElement("tbody", null, this.props.parentModule.state.DoopUser.map((col, index) => {
                    var _a, _b;
                    return React.createElement("tr", { key: index },
                        React.createElement("td", { className: styles.formatedTabletext }, col.email),
                        React.createElement("td", { className: styles.formatedTabletext }, col.name),
                        React.createElement("td", { className: styles.formatedTabletext }, this.Users_AreasColumn(col.areas)),
                        React.createElement("td", { className: `${styles.alignCenter} ${styles.biggerIcons}` }, col.canInitiateProcess ? React.createElement("i", { className: `uib-icon uib-icon--ok ${styles.green}` }) : React.createElement("i", { className: `uib-icon uib-icon--close ${styles.red}` })),
                        React.createElement("td", { className: `${styles.alignCenter} ${styles.biggerIcons}` }, col.canAcceptOrders ? React.createElement("i", { className: `uib-icon uib-icon--ok ${styles.green}` }) : React.createElement("i", { className: `uib-icon uib-icon--close ${styles.red}` })),
                        React.createElement("td", { className: `${styles.alignCenter} ${styles.biggerIcons}` }, col.isContractor ? React.createElement("i", { className: `uib-icon uib-icon--ok ${styles.green}` }) : React.createElement("i", { className: `uib-icon uib-icon--close ${styles.red}` })),
                        React.createElement("td", { className: `${styles.alignCenter} ${styles.biggerIcons}` }, col.isOrderInformed ? React.createElement("i", { className: `uib-icon uib-icon--ok ${styles.green}` }) : React.createElement("i", { className: `uib-icon uib-icon--close ${styles.red}` })),
                        isUserroleSufficient(UserRoles.editor, (_a = HubPathRouting.currentProject) === null || _a === void 0 ? void 0 : _a.moduleInstanceId, (_b = HubPathRouting.currentModule) === null || _b === void 0 ? void 0 : _b.moduleInstanceId) && React.createElement(React.Fragment, null,
                            React.createElement("td", { className: styles.alignCenter },
                                React.createElement("button", { className: `${styles.biggerIcons} ${styles.noShadow}`, onClick: () => { this.EditUserShow(col.name, col.email, col.canAcceptOrders, col.canInitiateProcess, col.isContractor, col.isOrderInformed, col.areas); } },
                                    React.createElement("i", { className: "uib-icon uib-icon--edit " }))),
                            React.createElement("td", { className: styles.alignCenter },
                                React.createElement("button", { className: `${styles.biggerIcons} ${styles.noShadow}`, onClick: () => { this.deleteUserShow(col.email); } },
                                    React.createElement("i", { className: "uib-icon uib-icon--delete " })))));
                }))),
            React.createElement(Button, { onClick: () => { this.EditUserShow(); }, className: "uib-button uib-button--primary" }, "Add"));
    }
}
