import * as React from "react";
import { Button, Container, ModalBody, ModalFooter, Row, } from "react-bootstrap";
import styles from "../../ProjectContent.module.css";
import ModalHeader from "react-bootstrap/ModalHeader";
import { deleteModuleInstance } from "../../BackendFacade/apiCalls";
import HubFunctions from "../../../../HubFramework/hubFunctions";
export class AllModules_Delete extends React.Component {
    constructor() {
        super(...arguments);
        this.projectId = this.props.projectId;
        this.moduleInstanceId = this.props.moduleInstanceId;
    }
    /**
     * Applies the changes to the project
     */
    applyProject() {
        HubFunctions.showLoading();
        deleteModuleInstance(this.projectId, this.moduleInstanceId).then((data) => {
            if (data.success) {
                this.props.onSuccessFunction();
                this.props.closeFunction();
            }
            else {
                HubFunctions.PopupCreate("Error", data.message);
            }
        });
        HubFunctions.hideLoading();
    }
    render() {
        return React.createElement("div", null,
            React.createElement(ModalHeader, { closeButton: true }, "Delete module instance"),
            React.createElement(ModalBody, null,
                React.createElement(Container, null,
                    React.createElement(Row, { className: styles.popupSpaceBetween }, "Sure you want to delete this module instance?"))),
            React.createElement(ModalFooter, null,
                React.createElement(Button, { className: "uib-button uib-button--secondary", variant: "secondary", onClick: () => this.props.closeFunction() }, "Cancel"),
                React.createElement(Button, { className: `uib-button uib-button--primary ${styles.applyButton}`, onClick: () => { this.applyProject(); } }, "Delete")));
    }
}
