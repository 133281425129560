import { getReleaseIdSentry, releaseId, sentryDsn } from "../Config/environmentVars";
import * as Sentry from "@sentry/react";
import { feedbackIntegration } from "@sentry/react";
// no sentry calls in localhost dev
if ("{RELEASEID}" !== releaseId) {
    Sentry.init({
        release: getReleaseIdSentry,
        dsn: sentryDsn,
        integrations: [new Sentry.BrowserTracing(),
            feedbackIntegration({
                colorScheme: "light",
                isEmailRequired: false,
                showEmail: false,
            })
        ],
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}
