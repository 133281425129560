var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Button, Col, Container, FormControl, ModalBody, ModalFooter, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { isValidEmail } from "../../../../Utils/checker";
import ModalHeader from "react-bootstrap/ModalHeader";
import { IUsersEditMode } from "../../Interfaces/IHubAddEditModal";
import { say } from "../../../../Utils/webHelper";
import { UpdateAccessRoles } from "../../BackendFacade/apiCalls";
import { IconTrash } from "../../../../Assets/svgs";
import { FileUploaderUserMails } from "../../../IDMS/components";
import styles from './../../Hub.module.css';
import { deepCopy } from "../../../../Utils/transformer";
import { HubPathRouting } from "../../../../HubFramework/pathBuilder";
import { AuthLevels, defaultAuth } from "../../constants";
import { SetGlobalTenantSystemContent, UserTenantManagement } from "../../../IDMS/Utils/tenantSystem";
import HubFunctions from "../../../../HubFramework/hubFunctions";
export class Hub_AddEditModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = deepCopy(this.props.state);
    }
    componentDidMount() {
        // on project level, if the user has no scopes in this project, add an empty one
        if (this.state.tenantSystem.length <= 1 &&
            HubPathRouting.currentProject &&
            this.state.tenantSystem[0].fullscope.indexOf(HubPathRouting.currentProject.moduleInstanceId) === -1) {
            this.addGlobalScope();
        }
    }
    /**
     * Edit the value of a User scope
     * @param id id of the scope line
     * @param value value
     */
    EditScopeValue(id, value) {
        let tenantLines = this.state.tenantSystem;
        tenantLines.find(x => x.id === id).value = value;
        this.setState({ tenantSystem: tenantLines });
    }
    /**
     * Add a full scope to the tenant system to the webpage backend
     */
    addGlobalScope() {
        let scopes = this.state.tenantSystem;
        let newLine = { id: scopes.length, scopes: ["user"], value: defaultAuth, tmp: "", fullscope: "" };
        if (this.props.projectId !== "null") {
            newLine.scopes.push(this.props.projectId);
        }
        scopes.push(newLine);
        this.setState({ tenantSystem: scopes });
    }
    /**
     * Saves the current user config
     */
    saveUser() {
        return __awaiter(this, void 0, void 0, function* () {
            // if the key already exists and the user wants to ADD a user, interrupt and show an error message
            if ((this.state.OldSelected_key === "" && this.state.Selected_Key !== "" && Object.keys(this.state.userData).indexOf(this.state.Selected_Key) !== -1) ||
                // if the user is new
                (this.state.Selected_Key === "" && this.state.Selected_Value.mail !== "" &&
                    // exists by usermail
                    (Object.keys(this.state.userData).filter(x => this.state.userData[x].mail === this.state.Selected_Value.mail).length > 0 ||
                        // exists by usermail in key
                        Object.keys(this.state.userData).indexOf(this.state.Selected_Key) !== -1))) {
                HubFunctions.PopupCreate("Error", "The user already exists. Please choose another key or email.");
                return;
            }
            // if a user of the email list already exists, interrupt and show an error message
            if (this.state.emailList.length >= 1 && Object.keys(this.state.userData).filter(x => this.state.emailList.indexOf(this.state.userData[x].mail) !== -1).length > 0) {
                HubFunctions.PopupCreate("Error", "At least one user of the email list already exists. Please choose another email.");
                return;
            }
            let user = {
                key: this.state.Selected_Key,
                mail: this.state.Selected_Value.mail,
                lastLogin: this.state.Selected_Value.lastLogin,
                tenantData: {}
            };
            // evaluate tenant data
            for (let line of this.state.tenantSystem) {
                // skip empty value and empty scopes
                if (line.value === "-" || line.scopes.length === 0) {
                    continue;
                }
                user.tenantData[line.scopes.join('/')] = line.value;
            }
            // use the key if already given
            if (this.state.Selected_Key !== "") {
                user.userId = this.state.Selected_Key;
                // if the email field contains a valid email as well, add it too
            }
            else if (isValidEmail(this.state.Selected_Value.mail) && this.state.emailList.indexOf(this.state.Selected_Value.mail) === -1) {
                user.userId = this.state.Selected_Value.mail;
            }
            else {
                say("Invalid operation");
            }
            // add the user to the list of users
            if (this.state.emailList.find(x => x === user.userId) === undefined && user.userId !== undefined) {
                this.state.emailList.push(user.userId);
            }
            // on project level, remove the global scope
            if (HubPathRouting.currentProject) {
                delete user.tenantData["user"];
            }
            let errors = [];
            let promises = [];
            this.state.emailList.forEach((email) => {
                user.userId = email;
                promises.push(UpdateAccessRoles(user, this.props.projectId).then((data) => __awaiter(this, void 0, void 0, function* () {
                    if (!data.success) {
                        errors.push(email + ": " + data.message);
                    }
                })));
            });
            // wait for all promises
            yield Promise.all(promises);
            if (errors.length > 0) {
                HubFunctions.PopupCreate("Error", "Error while saving user: " + errors.join(", "));
            }
            else {
                this.props.onSuccessFunction();
            }
        });
    }
    /**
     * displays the user config for the individual scopes
     */
    getAuthLevel_NewTenantManagement() {
        let globalScope = React.createElement(React.Fragment, null);
        // hub level configuration
        if (this.props.projectId === "null") {
            // id of the global scope
            let globalid = this.state.tenantSystem.find(x => x.scopes.length === 1 && x.scopes.every((value, index) => value === ["user"][index])).id;
            // set global to default auth if not set
            if (this.state.tenantSystem.find(x => x.id === globalid).value === "") {
                this.EditScopeValue(globalid, defaultAuth);
            }
            // if called from within project, don't show global scope
            globalScope = React.createElement(SetGlobalTenantSystemContent, { tenantSystem: this.state.tenantSystem, authLevels: AuthLevels, defaultAuth: defaultAuth, globalId: globalid, onEditFunction: this.EditScopeValue.bind(this) });
        }
        return React.createElement(Row, null,
            React.createElement(Col, null,
                globalScope,
                React.createElement(UserTenantManagement, { tenantSystem: this.state.tenantSystem, setTenantSystemFunction: this.setTenantSystemFunction.bind(this), projectId: this.props.projectId, authLevels: AuthLevels, listOfIDMSSubscopes: [], global: this.props.projectId === "null", useSelectOptions: true }),
                React.createElement("p", null, "Please remember to click \"Add\" for each new scope."),
                React.createElement("br", null),
                React.createElement(Button, { className: "uib-button uib-button--primary", id: "addScope", onClick: () => this.addGlobalScope() }, "Add Scope")));
    }
    setTenantSystemFunction(tenantSystem) {
        this.setState({ tenantSystem: tenantSystem });
    }
    emailListDeleteItem(item) {
        let emails = this.state.emailList;
        emails.splice(emails.indexOf(item), 1);
        this.setState({ emailList: emails });
    }
    addMailsToList(maillist) {
        let errors = [];
        maillist.forEach(item => {
            let error = this.addMailToList(item);
            if (error !== undefined) {
                errors.push(error);
            }
        });
        if (errors.length > 0) {
            HubFunctions.PopupCreate("Error", "Errors during adding " + errors.join(", ") + ". Probably wrong or duplicate email address.");
        }
    }
    /**
     * Add mail to emaillist
     */
    addMailToList(newMail = "") {
        let newmailtmp;
        if (newMail !== "") {
            newmailtmp = newMail;
        }
        else if (this.state.Selected_Value.mail !== "") {
            newmailtmp = this.state.Selected_Value.mail;
        }
        if (isValidEmail(newmailtmp)) {
            if (this.state.emailList.indexOf(newmailtmp) !== -1) {
                HubFunctions.PopupCreate("Error", "Email already exists");
                return newmailtmp;
            }
            let user = this.state.Selected_Value;
            user.mail = "";
            this.setState({ emailList: [...this.state.emailList, newmailtmp], Selected_Value: user });
            return;
        }
        else {
            HubFunctions.PopupCreate("Error", "Please enter a valid email address");
            return newmailtmp;
        }
    }
    render() {
        let authLevel;
        authLevel = this.getAuthLevel_NewTenantManagement();
        let applyButton = React.createElement("div", null);
        if (isValidEmail(this.state.Selected_Value.mail) ||
            (this.state.Selected_Value.mail === "" && this.state.emailList.length > 0)) {
            // check if all tmp values of users_ tenent system are empty
            if (this.state.tenantSystem.every(x => x.tmp === "")) {
                applyButton = React.createElement(Button, { className: `uib-button uib-button--primary ${styles.addUserButton}`, id: "applyButton", onClick: () => { this.saveUser(); } }, "Apply");
            }
            else {
                // show a warning if there are still values in the tenant-system
                applyButton = React.createElement(OverlayTrigger, { placement: "top", delay: { show: 50, hide: 200 }, overlay: React.createElement(Tooltip, { id: "tooltip-disabled" }, "Please make sure all Scopes are added by clicking \"Add\".") },
                    React.createElement(Button, { className: `uib-button uib-button--primary ${styles.addUserButton} ${styles.warningButton}`, id: "applyButton", onClick: () => { this.saveUser(); } }, "Apply"));
            }
        }
        return React.createElement("div", null,
            React.createElement(ModalHeader, { closeButton: true },
                this.state.editMode === IUsersEditMode.Add ? "Add" : "Edit",
                " User"),
            React.createElement(ModalBody, null,
                React.createElement(Container, null,
                    this.state.emailList.length > 0 && this.state.editMode === IUsersEditMode.Add ?
                        React.createElement(Row, { className: styles.popupSpaceBetween },
                            React.createElement(Col, { md: 2, className: "align-self-center" }, "Emails"),
                            React.createElement(Col, null, this.state.emailList.map((item, index) => {
                                return React.createElement(Button, { className: `${styles.spaceRight} ${styles.spaceButtom}`, variant: "primary", key: index, id: "emailList" + index, onClick: () => this.emailListDeleteItem(item) },
                                    item,
                                    " ",
                                    IconTrash);
                            })))
                        : React.createElement("div", null),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 2, className: "align-self-center" }, "Email"),
                        React.createElement(Col, null,
                            React.createElement(FormControl, { placeholder: "Email adress", value: this.state.Selected_Value.mail, id: "users_email", onChange: (event) => {
                                    let user = this.state.Selected_Value;
                                    user.mail = event.target.value;
                                    this.setState({ Selected_Value: user });
                                } })),
                        this.state.editMode === IUsersEditMode.Add ?
                            React.createElement(React.Fragment, null,
                                React.createElement(Col, { md: "auto" },
                                    React.createElement(Button, { className: "uib-button uib-button--primary", variant: "primary", id: "addButton", onClick: () => this.addMailToList() }, "Add")),
                                React.createElement(Col, { md: "auto" },
                                    React.createElement(FileUploaderUserMails, { handleFile: (e) => this.addMailsToList(e) })))
                            : React.createElement("div", null)),
                    !isValidEmail(this.state.Selected_Key) &&
                        React.createElement(Row, { className: styles.popupSpaceBetween },
                            React.createElement(Col, { md: 2, className: "align-self-center" }, "Key"),
                            React.createElement(Col, null, this.state.Selected_Key)),
                    authLevel)),
            React.createElement(ModalFooter, null,
                React.createElement(Button, { className: "uib-button uib-button--secondary", variant: "secondary", onClick: () => this.props.closeFunction(), id: "cancelButton" }, "Cancel"),
                applyButton));
    }
}
