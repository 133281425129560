export var Popup;
(function (Popup) {
    Popup[Popup["None"] = 0] = "None";
    Popup[Popup["Config_Edit"] = 1] = "Config_Edit";
    Popup[Popup["Config_Info"] = 2] = "Config_Info";
    Popup[Popup["Config_delete"] = 3] = "Config_delete";
    Popup[Popup["Login"] = 4] = "Login";
    Popup[Popup["Info"] = 5] = "Info";
    Popup[Popup["App_delete"] = 6] = "App_delete";
    Popup[Popup["AutomationConfig_Edit"] = 7] = "AutomationConfig_Edit";
    Popup[Popup["AutomationConfig_details"] = 8] = "AutomationConfig_details";
    Popup[Popup["PictureDetail"] = 9] = "PictureDetail";
    Popup[Popup["UploadFile"] = 10] = "UploadFile";
})(Popup || (Popup = {}));
export var FileType;
(function (FileType) {
    FileType[FileType["Directory"] = 0] = "Directory";
    FileType[FileType["File"] = 1] = "File";
    FileType[FileType["Up"] = 2] = "Up";
    FileType[FileType["Type"] = 3] = "Type";
})(FileType || (FileType = {}));
export var DirectoryType;
(function (DirectoryType) {
    DirectoryType["FolderImage"] = "Images";
    DirectoryType["FolderDocument"] = "Documents";
})(DirectoryType || (DirectoryType = {}));
