import { getListOfAllModules } from "./modules";
import { showRootComponent } from "./webHelper";
import { HubPathRouting, switchModule } from "../HubFramework/pathBuilder";
import { Module as HubModule } from "../Components/Hub";
import * as React from "react";
import { moduleContent as ProjectContentModule } from "../Components/ProjectContent";
export function handleRouting() {
    var _a;
    let url = window.location.href;
    // Remove the base URL and split the remaining path
    const fullPath = url.split("#")[1];
    const parts = fullPath === null || fullPath === void 0 ? void 0 : fullPath.split("&", 2);
    const path = parts ? (_a = parts[0]) === null || _a === void 0 ? void 0 : _a.split("/") : null;
    const parameters = parts ? parts[1] : null;
    // Set default values for moduleName, moduleInstanceId, and page
    let moduleName = "Hub";
    let moduleInstanceId = null;
    let page = null;
    // Get the list of available modules
    const availableModules = getListOfAllModules();
    const availableModuleNames = Object.keys(availableModules);
    // If the path is empty or only contains the empty string, call switchModule with the default values
    if (!path || path.length === 0 || (path.length === 1 && path[0] === "")) {
        showRootComponent(React.createElement(HubModule, null));
        return;
    }
    // Iterate through the path and update moduleName, moduleInstanceId, and page accordingly
    for (let i = 0; i < path.length; i++) {
        // set project name if available
        if (path[i] === ProjectContentModule.moduleName) {
            if (i + 1 < path.length) {
                let project = path[i + 1];
                HubPathRouting.currentProject = {
                    moduleInstanceId: project,
                    moduleName: ProjectContentModule.moduleName,
                };
            }
        }
        if (path[i] === "Hub") {
            moduleName = "Hub";
            if (i + 1 < path.length) {
                page = path[i + 1];
            }
            // update moduleInstanceId if the current part is a valid moduleInstanceId
        }
        else if (availableModuleNames.includes(path[i])) {
            moduleName = path[i];
            moduleInstanceId = path[i + 1] || null;
            page = path[i + 2] || null;
        }
        else {
            // If an invalid part is found in the URL, ignore it and continue iterating
            // continue;
        }
    }
    let linkedId = null;
    if (parameters) {
        let items = parameters.split("&");
        items.forEach(item => {
            // if "linkedId=" is found, extract the linkedId
            if (item.includes("linkedId=")) {
                linkedId = { projectId: item.split("linkedId=")[1] };
            }
            else {
                HubPathRouting.currentParameters = { moduleInstanceId: parameters, moduleName: null, displayName: null };
            }
        });
    }
    // Call switchModule with the extracted values
    switchModule(moduleName, {
        moduleInstanceId: moduleInstanceId,
        page: page,
        linkedId: linkedId
    });
}
