import { fetchFromApi, getBackendApiURL } from "BackendFacade";
export function getConfigDataCall(projectId, moduleInstanceId) {
    let url = getBackendApiURL() + "projects/" + projectId + "/modules/" + moduleInstanceId + "/config";
    return fetchFromApi(url, "GET");
}
export function sendConfigEditDataCall(projectId, moduleInstanceId, value) {
    let url = getBackendApiURL() + "projects/" + projectId + "/modules/" + moduleInstanceId + "/config";
    return fetchFromApi(url, "POST", value);
}
export function getUserDataCall(projectId, moduleInstanceId) {
    let url = getBackendApiURL() + "projects/" + projectId + "/modules/" + moduleInstanceId + "/user";
    return fetchFromApi(url, "GET");
}
export function sendUsersEditDataCall(projectId, moduleInstanceId, value) {
    let url = getBackendApiURL() + "projects/" + projectId + "/modules/" + moduleInstanceId + "/user";
    return fetchFromApi(url, "POST", value);
}
