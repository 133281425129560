// noinspection DuplicatedCode
import * as React from "react";
import { Alert, Button, Col, Container, FormControl, ModalBody, ModalFooter, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import { IconDelete } from "../../../../Assets/svgs";
import styles from './../../ProjectContent.module.css';
import { deepCopy } from "../../../../Utils/transformer";
import { updateProject } from "../../BackendFacade/apiCalls";
import { useState } from "react";
import HubFunctions from "../../../../HubFramework/hubFunctions";
export class ProjectInformation_Edit extends React.Component {
    constructor(props) {
        super(props);
        this.handleImageSelect = (base64Image) => {
            this.setState({ customProjectIcon: base64Image });
        };
        this.state = deepCopy(this.props.state);
    }
    /**
     * Adds the newContact to the list of contacts, resets the newContact
     */
    addContactToList() {
        if (this.state.newContact.mail === "" || this.state.newContact.job === "") {
            return;
        }
        let contacts = this.state.contacts;
        contacts.push(this.state.newContact);
        this.setState({ contacts: contacts, newContact: { mail: "", job: "" } });
    }
    /**
     * Applies the changes to the project
     */
    applyProject() {
        HubFunctions.showLoading();
        updateProject(this.state).then((data) => {
            if (data.success) {
                this.props.onSuccessFunction();
                this.props.closeFunction();
            }
            else {
                HubFunctions.PopupCreate("Error", data.message);
            }
        });
        HubFunctions.hideLoading();
    }
    render() {
        let applyButton = React.createElement(OverlayTrigger, { placement: "top", delay: { show: 1, hide: 200 }, overlay: React.createElement(Tooltip, { id: "tooltip-disabled" }, "Please make sure all fields except for the description are filled.") },
            React.createElement(Button, { className: `uib-button uib-button--primary ${styles.applyButton} ${styles.errorButton}` }, "Apply"));
        // check if every field is filled, activate apply button
        if (this.state.displayName !== ""
            && this.state.contacts.length > 0
            && this.state.costCenter >= 1000
            && this.state.department !== ""
            && this.state.product !== "") {
            applyButton = React.createElement(Button, { className: `uib-button uib-button--primary ${styles.applyButton}`, onClick: () => { this.applyProject(); } }, "Apply");
        }
        return React.createElement("div", null,
            React.createElement(ModalHeader, { closeButton: true }, "Edit Project"),
            React.createElement(ModalBody, null,
                React.createElement(Container, null,
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 2, className: "align-self-center" }, "Display name"),
                        React.createElement(Col, null,
                            React.createElement(FormControl, { placeholder: "Display name", value: this.state.displayName, id: "displayname", onChange: (event) => {
                                    this.setState({ displayName: event.target.value });
                                } }))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 2, className: "align-self-center" }, "Description"),
                        React.createElement(Col, null,
                            React.createElement(FormControl, { placeholder: "Description (optional)", value: this.state.description, id: "description", onChange: (event) => {
                                    this.setState({ description: event.target.value });
                                } }))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 2, className: "align-self-center" }, "Contacts"),
                        React.createElement(Col, null,
                            React.createElement(Table, null,
                                React.createElement("thead", null,
                                    React.createElement("tr", null,
                                        React.createElement("th", null, "Mail"),
                                        React.createElement("th", null, "Job"),
                                        React.createElement("th", null, "Delete"))),
                                React.createElement("tbody", null, this.state.contacts.map((contact, index) => {
                                    return React.createElement(TableRow, { key: index, index: index, contact: contact, onDelete: (index) => {
                                            let contacts = this.state.contacts;
                                            contacts.splice(index, 1);
                                            this.setState({ contacts: contacts });
                                        }, onUpdate: (index, field, value) => {
                                            const newContacts = this.state.contacts.map((c, i) => {
                                                if (i === index) {
                                                    return Object.assign(Object.assign({}, c), { [field]: value });
                                                }
                                                return c;
                                            });
                                            this.setState({ contacts: newContacts });
                                        } });
                                }))),
                            React.createElement("span", { className: `${styles.addEmail}` },
                                React.createElement(FormControl, { className: styles.addEmailElement, placeholder: "Email address", value: this.state.newContact.mail, id: "email", onChange: (event) => {
                                        let user = this.state.newContact;
                                        user.mail = event.target.value;
                                        this.setState({ newContact: user });
                                    } }),
                                React.createElement(FormControl, { className: styles.addEmailElement, placeholder: "Job", value: this.state.newContact.job, id: "job", onChange: (event) => {
                                        let user = this.state.newContact;
                                        user.job = event.target.value;
                                        this.setState({ newContact: user });
                                    } }),
                                React.createElement(Button, { className: "uib-button uib-button--primary", variant: "primary", onClick: () => this.addContactToList() }, " Add ")))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 2, className: "align-self-center" }, "Cost center"),
                        React.createElement(Col, null,
                            React.createElement(FormControl, { placeholder: "Costcenter (>= 1000)", value: this.state.costCenter, id: "costcenter", onChange: (event) => {
                                    // check if the input is a number
                                    if (isNaN(Number(event.target.value))) {
                                        return;
                                    }
                                    this.setState({ costCenter: +event.target.value });
                                } }))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 2, className: "align-self-center" }, "Internal Order Number"),
                        React.createElement(Col, null,
                            React.createElement(FormControl, { placeholder: "Internal Order Number", value: this.state.internalOrderNumber, id: "costcenter", onChange: (event) => {
                                    // check if the input is a number
                                    if (isNaN(Number(event.target.value))) {
                                        return;
                                    }
                                    this.setState({ internalOrderNumber: +event.target.value });
                                } }))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 2, className: "align-self-center" }, "Department"),
                        React.createElement(Col, null,
                            React.createElement(FormControl, { placeholder: "Department", value: this.state.department, id: "department", onChange: (event) => {
                                    this.setState({ department: event.target.value });
                                } }))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 2, className: "align-self-center" }, "Product"),
                        React.createElement(Col, null,
                            React.createElement(FormControl, { placeholder: "Product", value: this.state.product, id: "product", onChange: (event) => {
                                    this.setState({ product: event.target.value });
                                } }))),
                    React.createElement(ImageSelector, { onImageSelected: this.handleImageSelect, initialImage: this.state.customProjectIcon }))),
            React.createElement(ModalFooter, null,
                React.createElement(Button, { className: "uib-button uib-button--secondary", variant: "secondary", onClick: () => this.props.closeFunction() }, "Cancel"),
                applyButton));
    }
}
class TableRow extends React.Component {
    constructor(props) {
        super(props);
        this.toggleFormControl = (field) => {
            this.setState((prevState) => {
                const updatedState = Object.assign(Object.assign({}, prevState.showFormControl), { mail: false, job: false, [field]: !prevState.showFormControl[field] });
                return {
                    showFormControl: updatedState,
                };
            });
        };
        this.state = {
            showFormControl: {
                mail: false,
                job: false,
            },
        };
    }
    render() {
        const { contact, index, onDelete } = this.props;
        const { showFormControl } = this.state;
        return (React.createElement("tr", { key: index },
            React.createElement("td", { onClick: () => this.toggleFormControl("mail") },
                React.createElement("div", null, showFormControl.mail ? (React.createElement(FormControl, { className: styles.addEmailElement, placeholder: "Email address", value: contact.mail, id: "email", onClick: (event) => event.stopPropagation(), onChange: (event) => this.props.onUpdate(index, 'mail', event.target.value), autoFocus: true })) : (contact.mail))),
            React.createElement("td", { onClick: () => this.toggleFormControl("job") },
                React.createElement("div", null, showFormControl.job ? (React.createElement(FormControl, { className: styles.addEmailElement, placeholder: "Job", value: contact.job, id: "job", onClick: (event) => event.stopPropagation(), onChange: (event) => this.props.onUpdate(index, 'job', event.target.value), autoFocus: true })) : (contact.job))),
            React.createElement("td", null,
                React.createElement(Button, { variant: "outline-primary", className: styles.ButtonLogo, onClick: () => onDelete(index) }, IconDelete))));
    }
}
const ImageSelector = ({ onImageSelected, initialImage }) => {
    const [customProjectIcon, setCustomProjectIcon] = useState(initialImage || '');
    const [error, setError] = useState('');
    const handleImageSelect = () => {
        let fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';
        fileInput.onchange = () => {
            var _a;
            let file = (_a = fileInput.files) === null || _a === void 0 ? void 0 : _a.item(0);
            if (file === null || file === undefined) {
                return;
            }
            let reader = new FileReader();
            reader.onload = (event) => {
                const base64Image = event.target.result;
                if (base64Image.length > 64 * 1024) {
                    setError('The selected image is larger than 64KB. Please choose a smaller image.');
                    setCustomProjectIcon('');
                    onImageSelected('');
                }
                else {
                    setError('');
                    onImageSelected(base64Image);
                    setCustomProjectIcon(base64Image);
                }
            };
            reader.readAsDataURL(file);
        };
        fileInput.click();
    };
    const deleteImage = () => {
        setCustomProjectIcon('');
        onImageSelected('');
    };
    return (React.createElement(Row, { className: styles.popupSpaceBetween },
        React.createElement(Col, { md: 2, className: "align-self-center" }, "Project Icon"),
        React.createElement(Col, null,
            customProjectIcon && (React.createElement("div", null,
                React.createElement("img", { src: customProjectIcon, alt: "Selected project icon", style: { maxWidth: '150px', maxHeight: '150px', marginRight: '10px' } }))),
            React.createElement(Button, { className: `uib-button uib-button--primary ${styles.spaceRight}`, onClick: handleImageSelect }, "Select Image"),
            customProjectIcon && React.createElement(Button, { className: `uib-button uib-button--primary ${styles.errorButton}`, onClick: deleteImage }, "Delete Image"),
            error && (React.createElement(Col, { md: 12 },
                React.createElement(Alert, { className: "mt-3" }, error))))));
};
