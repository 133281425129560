var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Table } from "react-bootstrap";
import { getListOfAllModules } from "../../../Utils/modules";
import { listModuleInstances, listProjects } from "../BackendFacade/apiCalls";
import { GlobalDictionary } from "../../../Utils/globalDictionary";
import styles from "./../Hub.module.css";
import HubFunctions from "../../../HubFramework/hubFunctions";
const LISTOFPROJECTS = "listOfProjects";
export class ModulesUsage extends React.Component {
    constructor(props) {
        super(props);
        this.listOfModules = getListOfAllModules();
        this.state = {
            listOfProjects: [],
            usageModules: [],
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            // get all projects
            let allProjects;
            if (!GlobalDictionary.get(LISTOFPROJECTS)) {
                allProjects = yield this.getListOfAllProjects();
                if (allProjects.length === 0)
                    return;
            }
            else {
                allProjects = GlobalDictionary.get(LISTOFPROJECTS);
            }
            // get all modules to all projects
            yield this.gatherAllUsedModules(allProjects);
        });
    }
    getListOfAllProjects() {
        return __awaiter(this, void 0, void 0, function* () {
            return listProjects().then((data) => {
                if (data.success) {
                    GlobalDictionary.set(LISTOFPROJECTS, data.data.value);
                    return data.data.value;
                }
                else {
                    HubFunctions.PopupCreate("Error", data.message);
                    return [];
                }
            });
        });
    }
    /**
     * Get all modules to a project
     * @param projectId
     */
    getProjectModules(projectId) {
        return __awaiter(this, void 0, void 0, function* () {
            return listModuleInstances(projectId).then((data) => {
                if (data.success) {
                    let ret = data.data.value;
                    // set the project id of every module
                    ret.forEach((module) => {
                        module.projectId = projectId;
                    });
                    return ret;
                }
                else {
                    HubFunctions.PopupCreate("Error", data.message);
                }
            });
        });
    }
    translateProjectIdToProjectName(projectId) {
        const projectList = GlobalDictionary.get(LISTOFPROJECTS);
        if (!projectList)
            return "";
        const project = projectList.find((i) => i.projectId === projectId);
        if (!project)
            return "";
        return project.displayName;
    }
    /**
     * Sort all modules to a table of modules and their usage
     * @param allProjects
     */
    gatherAllUsedModules(allProjects) {
        return __awaiter(this, void 0, void 0, function* () {
            let allModules = [];
            let listToLoad = [];
            allProjects.forEach((project) => {
                listToLoad.push(this.getProjectModules(project.projectId).then((modules) => {
                    allModules.push(...modules);
                }));
            });
            yield Promise.all(listToLoad);
            const allStaticModules = getListOfAllModules();
            let usageTable = [];
            allModules.forEach((module) => {
                // find module in usageTable
                let moduleInUsageTable = usageTable.find((i) => i.moduleName === module.staticModuleId);
                if (!moduleInUsageTable) {
                    moduleInUsageTable = {
                        moduleName: module.staticModuleId,
                        projects: [this.translateProjectIdToProjectName(module.projectId)],
                        count: 1,
                    };
                    usageTable.push(moduleInUsageTable);
                }
                else {
                    moduleInUsageTable.count++;
                    if (!moduleInUsageTable.projects.find((i) => i === this.translateProjectIdToProjectName(module.projectId)))
                        moduleInUsageTable.projects.push(this.translateProjectIdToProjectName(module.projectId));
                }
            });
            // add static modules that are not used
            Object.keys(allStaticModules).forEach((key) => {
                if (!usageTable.find((i) => i.moduleName === key)) {
                    usageTable.push({
                        moduleName: key,
                        projects: [],
                        count: 0,
                    });
                }
            });
            // sort by module name
            usageTable.sort((a, b) => {
                if (a.moduleName < b.moduleName)
                    return -1;
                if (a.moduleName > b.moduleName)
                    return 1;
                return 0;
            });
            this.setState({ usageModules: usageTable });
        });
    }
    sortByModuleName() {
        const usageModules = this.state.usageModules;
        usageModules.sort((a, b) => {
            if (a.moduleName < b.moduleName)
                return -1;
            if (a.moduleName > b.moduleName)
                return 1;
            return 0;
        });
        this.setState({ usageModules: usageModules });
    }
    sortByCount() {
        const usageModules = this.state.usageModules;
        usageModules.sort((a, b) => {
            if (a.count < b.count)
                return 1;
            if (a.count > b.count)
                return -1;
            return 0;
        });
        this.setState({ usageModules: usageModules });
    }
    render() {
        return React.createElement(React.Fragment, null,
            React.createElement("p", null, " This page contains infos about all modules in DevSecOps Hub."),
            React.createElement("br", null),
            React.createElement(Table, { striped: true, bordered: true, hover: true },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { onClick: () => this.sortByModuleName() }, "Title"),
                        React.createElement("th", null, "Used in "),
                        React.createElement("th", { className: styles.alignCenter, onClick: () => this.sortByCount() }, "No. of instances"))),
                React.createElement("tbody", null, this.state.usageModules && this.state.usageModules.map((module) => {
                    return React.createElement("tr", { key: module.moduleName },
                        React.createElement("td", null, this.listOfModules[Object.keys(this.listOfModules).find(x => x === module.moduleName)].moduleContent.title),
                        React.createElement("td", null, module.projects.join(", ")),
                        React.createElement("td", { className: styles.alignCenter }, module.count));
                }))));
    }
}
