var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Popup } from "../Interfaces/IFiles";
import { Button, Col, Container, Form, FormControl, FormGroup, Modal, ModalBody, ModalFooter, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import { AutomationConfig, invalidCSV } from "../constants";
import { getAutomationConfig, setAutomationConfig } from "../BackendFacade/apiCalls";
import { IconEdit, IconTrash } from "../../../Assets/svgs";
import { HubPathRouting } from "../../../HubFramework/pathBuilder";
import { isUserroleSufficient } from "../../../Utils/authorization";
import { UserRoles } from "../../../Utils";
import styles from "../Dpas.module.css";
import HubFunctions from "../../../HubFramework/hubFunctions";
export class Config extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            automationConfigCSV: "",
            automationConfigCurrentEmail: "",
            automationConfigEmails: [],
            automationConfigProjectName: "",
            automationConfigFileName: "",
            csvInvalidMessage: "",
            Popup: Popup.None,
            PopupMessage: undefined,
            projectId: this.props.parentModule.state.projectId,
            // config: {},
            editConfig: "",
            firstTime: false,
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            // check for projectId, if DPAS is called directly
            let projectId = this.state.projectId;
            if (projectId === undefined) {
                HubFunctions.showLoading();
                let msg = yield this.props.parentModule.getProjectId();
                if (msg.success) {
                    this.setState({ projectId: msg.projectId });
                    this.props.parentModule.setState({ projectId: msg.projectId });
                }
                else {
                    HubFunctions.PopupCreate("Error", msg.message);
                }
                HubFunctions.hideLoading();
            }
        });
    }
    PopupClose() {
        this.setState({ Popup: Popup.None });
    }
    EditConfig(config) {
        HubFunctions.showLoading();
        switch (config) {
            case AutomationConfig:
                getAutomationConfig(this.props.parentModule.state.projectId).then((data) => {
                    if (!data.success) {
                        HubFunctions.PopupCreate("Error", data.message);
                        HubFunctions.hideLoading();
                        return;
                    }
                    let automationConfig = data.data;
                    if (automationConfig.config == undefined) {
                        automationConfig.config = { csvFileName: "", projectName: "", contactMails: [] };
                    }
                    // if the csv config is empty, prefill it with headers
                    if (automationConfig.csvString === "" || automationConfig.csvString === undefined) {
                        automationConfig.csvString = "SearchName;DisplayName";
                    }
                    this.setState({
                        Popup: Popup.AutomationConfig_Edit,
                        automationConfigEmails: automationConfig.config.contactMails,
                        automationConfigCSV: automationConfig.csvString,
                        automationConfigProjectName: automationConfig.config.projectName,
                        automationConfigFileName: automationConfig.config.csvFileName,
                        firstTime: automationConfig.config.projectName === "",
                    });
                });
                break;
            default:
                this.setState({ Popup: Popup.Config_Edit, editConfig: config });
        }
        HubFunctions.hideLoading();
    }
    AutomationConfigAddEmail() {
        if (this.state.automationConfigEmails.indexOf(this.state.automationConfigCurrentEmail) === -1) {
            this.setState({ automationConfigEmails: this.state.automationConfigEmails.concat(this.state.automationConfigCurrentEmail), automationConfigCurrentEmail: "" });
        }
    }
    AutomationConfigRemoveEmail(email) {
        this.setState({ automationConfigEmails: this.state.automationConfigEmails.filter((x) => x !== email) });
    }
    AutomationConfigContent() {
        let submitButton = React.createElement(Button, { className: "uib-button uib-button--primary", onClick: () => { this.AutomationConfigSubmit(); } }, "Submit");
        // check if all fields are filled
        if (this.state.automationConfigEmails.length == 0 || this.state.automationConfigProjectName == "" || this.state.automationConfigCSV == "") {
            submitButton = React.createElement(OverlayTrigger, { placement: "top", delay: { show: 10, hide: 200 }, overlay: React.createElement(Tooltip, { id: "tooltip-disabled" }, "Please make sure all fields are filled.") },
                React.createElement(Button, { className: `uib-button uib-button--primary ${styles.warningButton}` }, "Submit"));
        }
        let CSVErrorMessage = React.createElement(React.Fragment, null);
        // Provide error message only if csv field content is flagged as invalid
        if (this.state.csvInvalidMessage == invalidCSV) {
            CSVErrorMessage = React.createElement(Row, { className: styles.popupSpaceBetween },
                React.createElement(Col, { md: 3, className: "align-self-center" }, "Message:"),
                React.createElement(Col, null,
                    "This CSV is invalid, please follow this format: ",
                    React.createElement("br", null),
                    "SearchName;DisplayName",
                    React.createElement("br", null),
                    "AnyName;AnyDisplayName",
                    React.createElement("br", null),
                    "..."));
        }
        return React.createElement("div", null,
            React.createElement(ModalHeader, null, "Edit the Automation Config"),
            React.createElement(ModalBody, null,
                React.createElement(Container, null,
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 3, className: "align-self-center" }, "Project Name"),
                        React.createElement(Col, null,
                            React.createElement(FormGroup, { className: "mb-3", controlId: "exampleForm.ControlInput1" },
                                React.createElement(FormControl, { type: "text", value: this.state.automationConfigProjectName, onChange: (event) => this.setState({ automationConfigProjectName: event.target.value }), disabled: !this.state.firstTime, placeholder: "Relevant for the url. You may want to use the same name as the project name." })))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 3, className: "align-self-center" }, "Email addresses"),
                        React.createElement(Col, null,
                            React.createElement(FormGroup, { className: styles.addNewApp, controlId: "NewUserRole" },
                                React.createElement("div", null, this.state.automationConfigEmails.map((item, index) => {
                                    return React.createElement(Button, { id: item + "_delete", className: styles.spaceRight, key: index, onClick: () => this.AutomationConfigRemoveEmail(item) },
                                        item,
                                        " ",
                                        IconTrash);
                                })),
                                React.createElement("div", { className: styles.addUserRole },
                                    React.createElement(FormControl, { type: "text", placeholder: 'add email address (click Add)', value: this.state.automationConfigCurrentEmail, onChange: (event) => this.setState({ automationConfigCurrentEmail: event.target.value }), onKeyPress: event => { if (event.key == 'Enter') {
                                            this.AutomationConfigAddEmail();
                                        } } }),
                                    React.createElement(Button, { id: "Add", className: `${styles.addUserButton} uib-button uib-button--primary`, onClick: () => this.AutomationConfigAddEmail() }, "Add"))))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 3, className: "align-self-center" }, "CSV Content"),
                        React.createElement(Col, null,
                            React.createElement(FormGroup, { className: "mb-3", controlId: "exampleForm.ControlTextarea1" },
                                React.createElement(FormControl, { as: "textarea", rows: 10, value: this.state.automationConfigCSV, onChange: (event) => { this.setState({ automationConfigCSV: event.target.value }, () => this.ValidateCSV()); } })))),
                    CSVErrorMessage)),
            React.createElement(ModalFooter, null,
                React.createElement("div", { className: styles.flexgrow },
                    React.createElement(Button, { className: `uib-button uib-button--secondary ${styles.spaceRight}`, onClick: () => { this.UploadConfigCSV(); } }, "Import from file")),
                React.createElement(Button, { className: `uib-button uib-button--secondary ${styles.spaceRight}`, onClick: () => { this.PopupClose(); this.ResetAutomationConfigVariables(); } }, "Close"),
                submitButton));
    }
    UploadConfigCSV() {
        // Open file dialog and accept only .csv files
        let input = document.createElement('input');
        input.type = 'file';
        input.accept = ".csv";
        // Read .csv file to string and insert into csv config text box
        input.onchange = e => {
            let file = e.target.files[0];
            let reader = new FileReader();
            reader.readAsText(file, 'UTF-8');
            reader.onload = readerEvent => {
                let content = readerEvent.target.result;
                this.setState({ automationConfigCSV: content.toString() }, () => this.ValidateCSV());
            };
        };
        input.click();
    }
    /**
     * Check if csv text box content is valid.
     * First line matches: "SearchName;DisplayName"
     * An all following lines match: "AnyString;AnyString"
     * @constructor
     */
    ValidateCSV() {
        let csvLinesSplitToArray = this.state.automationConfigCSV.split(/\r?\n/);
        if ((csvLinesSplitToArray[0].indexOf("DisplayName") !== -1)
            && (csvLinesSplitToArray[0].indexOf("SearchName") !== -1)
            && csvLinesSplitToArray.every(function (element) {
                return ((element.match(/.;/g) || []).length) >= 1;
            })) {
            this.setState({ csvInvalidMessage: "" });
        }
        else {
            this.setState({ csvInvalidMessage: invalidCSV });
        }
    }
    ;
    ResetAutomationConfigVariables() {
        this.setState({ automationConfigFileName: "", automationConfigCSV: "", automationConfigProjectName: "", automationConfigEmails: [], automationConfigCurrentEmail: "" });
    }
    AutomationConfigSubmit() {
        let filename = this.state.automationConfigFileName == "" ? this.state.automationConfigProjectName + ".csv" : this.state.automationConfigFileName;
        let body = { csvString: this.state.automationConfigCSV,
            config: { csvFileName: filename,
                contactMails: this.state.automationConfigEmails,
                projectName: this.state.automationConfigProjectName } };
        HubFunctions.showLoading();
        setAutomationConfig(this.state.projectId, body).then((data) => {
            if (data.success) {
                this.setState({ Popup: Popup.None });
            }
            else {
                HubFunctions.PopupCreate("Error", data.message);
            }
            HubFunctions.hideLoading();
        });
    }
    AutomationConfigDetailsContent() {
        return React.createElement("div", null,
            React.createElement(ModalHeader, null, "Edit the Automation Config"),
            React.createElement(ModalBody, null,
                React.createElement(Container, null,
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 3, className: "align-self-center" }, "Project Name"),
                        React.createElement(Col, null,
                            React.createElement(FormGroup, { className: "mb-3", controlId: "exampleForm.ControlInput1" },
                                React.createElement(FormControl, { type: "text", value: this.state.automationConfigProjectName, disabled: true })))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 3, className: "align-self-center" }, "Email addresses"),
                        React.createElement(Col, null,
                            React.createElement(FormGroup, { className: styles.addNewApp, controlId: "NewUserRole" },
                                React.createElement("div", null, this.state.automationConfigEmails.map((item, index) => {
                                    return React.createElement(Button, { id: item + "_delete", className: styles.spaceRight, key: index }, item);
                                }))))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 3, className: "align-self-center" }, "CSV Content"),
                        React.createElement(Col, null,
                            React.createElement("table", { className: styles.configTable },
                                React.createElement("tbody", null, this.state.automationConfigCSV.split(/\r?\n/).map((item, key) => {
                                    return React.createElement("tr", { key: key, className: styles.configTableContent }, item.split(";").map((subitem, key2) => {
                                        return React.createElement("td", { className: styles.configTableContent, key: key2 }, subitem);
                                    }));
                                }))))))),
            React.createElement(ModalFooter, null,
                React.createElement(Button, { className: `uib-button uib-button--secondary ${styles.spaceRight}`, onClick: () => { this.PopupClose(); this.ResetAutomationConfigVariables(); } }, "Close")));
    }
    ShowDetails() {
        HubFunctions.showLoading();
        getAutomationConfig(this.state.projectId).then((data) => {
            if (!data.success) {
                HubFunctions.PopupCreate("Error", data.message);
                HubFunctions.hideLoading();
                return;
            }
            let automationConfig = data.data;
            if (automationConfig.config == undefined || (automationConfig.message && automationConfig.message.includes("No config found"))) {
                automationConfig.config = { csvFileName: "", projectName: "", contactMails: [] };
                automationConfig.csvString = "";
            }
            this.setState({
                Popup: Popup.AutomationConfig_details,
                automationConfigEmails: automationConfig.config.contactMails,
                automationConfigCSV: automationConfig.csvString,
                automationConfigProjectName: automationConfig.config.projectName,
                automationConfigFileName: automationConfig.config.csvFileName
            });
            HubFunctions.hideLoading();
        });
    }
    render() {
        let popupContent = React.createElement("div", null);
        switch (this.state.Popup) {
            case Popup.AutomationConfig_Edit:
                popupContent = this.AutomationConfigContent();
                break;
            case Popup.AutomationConfig_details:
                popupContent = this.AutomationConfigDetailsContent();
                break;
            default:
                break;
        }
        let AutomationConfigEditButton = null;
        if (isUserroleSufficient(UserRoles.editor, HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId)) {
            AutomationConfigEditButton = React.createElement("td", { className: "alignCenter" },
                React.createElement(Button, { variant: "outline-primary", className: styles.ButtonLogo, onClick: () => this.EditConfig(AutomationConfig) }, IconEdit));
        }
        return React.createElement("div", { className: styles.ScrollPage },
            React.createElement(Modal, { size: "lg", show: this.state.Popup !== Popup.None, onHide: () => this.PopupClose() }, popupContent),
            React.createElement(Form, null,
                React.createElement(Table, { striped: true, className: styles.maintable },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", null, "Key"),
                            React.createElement("th", { className: "alignCenter" }, "Details"),
                            isUserroleSufficient(UserRoles.editor, HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId) &&
                                React.createElement("th", { className: "alignCenter" }, "Edit"))),
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", null, "Automation Config"),
                            React.createElement("td", { className: "alignCenter" },
                                React.createElement(Button, { variant: "outline-primary", className: `${styles.ButtonLogo} ${styles.OuterIconLogo}`, onClick: () => this.ShowDetails() },
                                    React.createElement("i", { className: `uib-icon uib-icon--view ${styles.IconLogo}` }))),
                            AutomationConfigEditButton)))));
    }
}
