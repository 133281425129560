var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Popup } from "./Interfaces/IDOOP";
import { getProjectMeta, getProjectMetaDataAreas, getProjectUsersData, } from "./BackendFacade/apiCalls";
import "@uib/css/themes/draeger-theme-bright.css";
import * as Sentry from "@sentry/react";
import { HubFramework } from "../../HubFramework";
import { moduleContent } from "./moduleContent";
import { HubPathRouting } from "../../HubFramework/pathBuilder";
export class Module extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Popup: Popup.None,
            DoopUser: [],
            CurrentLog: "",
            Meta: null,
            AllAreas: null,
            getProjectData: this.getProjectData,
            Error: "",
            projectId: HubPathRouting.currentProject.moduleInstanceId,
            moduleInstanceId: HubPathRouting.currentModule.moduleInstanceId,
        };
    }
    PopupClose() {
        this.setState({ Popup: Popup.None, CurrentLog: "" });
    }
    getProjectData() {
        return __awaiter(this, void 0, void 0, function* () {
            let value = { success: true, message: "", data: null };
            let prom2 = getProjectMeta(this.state.projectId, this.state.moduleInstanceId).then(data => {
                if (!data.success) {
                    value = { success: false, message: data.message, data: null };
                    Sentry.captureMessage(data.message);
                }
                else {
                    let meta = data.data;
                    if (meta.doopAreas === undefined)
                        meta.doopAreas = [];
                    this.setState({ Meta: meta });
                    //HubPathRouting.currentModule.displayName = meta.nameRaw
                }
            });
            let prom4 = getProjectMetaDataAreas(this.state.projectId, this.state.moduleInstanceId).then(data => {
                if (!data.success) {
                    value = { success: false, message: data.message, data: null };
                    Sentry.captureMessage(data.message);
                }
                else {
                    this.setState({ AllAreas: data.data.allAreas });
                }
            });
            let prom3 = getProjectUsersData(this.state.projectId, this.state.moduleInstanceId).then(data => {
                if (!data.success) {
                    value = { success: false, message: data.message, data: null };
                    Sentry.captureMessage(data.message);
                }
                else {
                    this.setState({ DoopUser: data.data });
                }
            });
            yield Promise.all([prom2, prom3, prom4]);
            return value;
        });
    }
    render() {
        return React.createElement(HubFramework, { hubContent: moduleContent, module: this });
    }
}
