import * as React from "react";
export const ProjectOverview = "Project Overview";
export const NewProject = "New Project";
export const OrderOverview = "Order Overview";
export const _EditProject = "Edit Project";
export const Users = "Users";
export const StatusReport = "Status Report";
export const TabOrderOverview = "#OrderOverview";
export const TabEditProject = "#EditProject";
export const TabUsers = "#Users";
export const prefixApp = "app";
export const B2CReceiver = "B2CReceiver";
// Tabs
export const TabProjectOverview = "#ProjectOverview";
export const TabNewProject = "#NewProject";
export const TabStatusReport = "#StatusReport";
export const HomeTabs = [TabProjectOverview, TabStatusReport, TabNewProject];
export const optionsOISList = [
    { value: 'New', label: 'New' },
    { value: 'Estimate and Quote', label: 'Estimate and Quote' },
    { value: 'Quote Acceptance', label: 'Quote Acceptance' },
    { value: 'Order', label: 'Order' },
    { value: 'Ordered', label: 'Ordered' },
    { value: 'Request for Acceptance', label: 'Request for Acceptance' },
    { value: 'Done', label: 'Done' },
];
export const GetOrderedItemsForProject = "GetOrderedItemsForProject";
export const GetProjectListing = "GetProjectListing";
export const ProjectMeta = "ProjectMeta";
export const ProjectUsers = "ProjectUsers";
export const CreateNewProject = "CreateNewProject";
export const GetProjectEventLogs = "GetProjectEventLogs";
export const UserSettings = "UserSettings";
// Userroles
export const None = "None";
export const Admin = "Admin";
export const UserManager = "UserManager";
export const Reader = "Reader";
export const DoopAreas = "doopAreas";
export const Contractors = "contractors";
export const EditProjectShownKeys = [
    "debugMode",
    "defaultOrderNumber",
    "defaultPriceModel",
    DoopAreas,
    Contractors,
];
export const star = React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "25", height: "25", fill: "currentColor", className: "bi bi-star", viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" }));
export const filledStar = React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "25", height: "25", fill: "currentColor", className: "bi bi-star-fill", viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" }));
export const IconTrash = React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "16", height: "16", fill: "currentColor", className: "bi bi-trash", viewBox: "0 0 16 16" },
    React.createElement("path", { d: "M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" }),
    React.createElement("path", { fillRule: "evenodd", d: "M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" }));
