var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Button, Col, Container, Form, FormControl, ModalBody, ModalFooter, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { isValidEmail } from "../../../../Utils/checker";
import ModalHeader from "react-bootstrap/ModalHeader";
import { UsersEditMode } from "../../Interfaces/interfaces";
import { IconTrash } from "../../constants";
import { FileUploaderUserMails } from "../../components";
import { sendUsersEditDataCall } from "../../BackendFacade/apiCalls";
import styles from './../../IDMS.module.css';
import { SetGlobalTenantSystemContent, UserTenantManagement } from "../../Utils/tenantSystem";
import HubFunctions from "../../../../HubFramework/hubFunctions";
export class AddEditUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.props.state;
    }
    /**
     * Edit the value of a User scope
     * @param id id of the scope line
     * @param value value
     */
    EditScopeValue(id, value) {
        let tenantLines = this.state.tenantSystem;
        tenantLines.find(x => x.id === id).value = value;
        this.setState({ tenantSystem: tenantLines });
    }
    /**
     * Add a full scope to the tenant system to the webpage backend
     * @param value value of the scope
     */
    addScopeFull(value) {
        let scopes = this.state.tenantSystem;
        scopes.push({ id: scopes.length, scopes: [], value: value, tmp: "", fullscope: "" });
        this.setState({ tenantSystem: scopes });
    }
    /**
     * Saves the current user config
     */
    saveUser() {
        // if the key already exists and the user wants to ADD a user, interrupt and show an error message
        if ((this.state.OldSelected_key === "" && this.state.Selected_Key !== "" && Object.keys(this.state.userData).indexOf(this.state.Selected_Key) !== -1) ||
            // if the user is new
            (this.state.Selected_Key === "" && this.state.Selected_Value.mail !== "" &&
                // exists by usermail
                (Object.keys(this.state.userData).filter(x => this.state.userData[x].mail === this.state.Selected_Value.mail).length > 0 ||
                    // exists by usermail in key
                    Object.keys(this.state.userData).indexOf(this.state.Selected_Key) !== -1))) {
            HubFunctions.PopupCreate("Error", "The user already exists. Please choose another key or email.");
            return;
        }
        // if a user of the email list already exists, interrupt and show an error message
        if (this.state.emailList.length >= 1 && Object.keys(this.state.userData).filter(x => this.state.emailList.indexOf(this.state.userData[x].mail) !== -1).length > 0) {
            HubFunctions.PopupCreate("Error", "At least one user of the email list already exists. Please choose another email.");
            return;
        }
        let value;
        // if the tenant system is active, convert the tenant config
        if (this.state.AppConfig.DisableTenantManagement !== "true") {
            let config = {};
            for (let line of this.state.tenantSystem) {
                // skip empty value and empty scopes
                if (line.value === "-" || line.scopes.length === 0) {
                    continue;
                }
                config[line.scopes.join('/')] = line.value;
            }
            value = JSON.stringify(config);
            // old rights system
        }
        else {
            value = this.state.Selected_Value.authLevel;
        }
        // delete old entry, if the email or key is changed
        if (this.state.OldSelected_key !== ""
            && this.state.OldSelected_key !== this.state.Selected_Value.mail
            && this.state.OldSelected_key !== this.state.Selected_Key) {
            let sendValue = {};
            sendValue[this.state.OldSelected_key] = "";
            let valueobj = { value: sendValue };
            sendUsersEditDataCall(this.props.projectID, this.props.moduleInstanceId, valueobj).then(data => {
                if (!data.success) {
                    HubFunctions.PopupClose();
                    HubFunctions.PopupCreate("Error", "Error while deleting old user: " + data.message);
                    return;
                }
            });
        }
        // prepare list of emails which are to be added
        let editUsers = [];
        this.state.emailList.forEach(item => {
            editUsers.push({ id: item, newValue: value });
        });
        // use the key if already given
        if (this.state.Selected_Key !== "") {
            editUsers.push({ id: this.state.Selected_Key, newValue: value });
            // if the email field contains a valid email as well, add it too
        }
        else if (isValidEmail(this.state.Selected_Value.mail) && this.state.emailList.indexOf(this.state.Selected_Value.mail) === -1) {
            editUsers.push({ id: this.state.Selected_Value.mail, newValue: value });
        }
        else {
            console.error("Invalid operation");
        }
        let valueobj = { value: editUsers };
        sendUsersEditDataCall(this.props.projectID, this.props.moduleInstanceId, valueobj).then((data) => __awaiter(this, void 0, void 0, function* () {
            if (!data.success) {
                HubFunctions.PopupCreate("Error", "Error while saving user: " + data.message);
            }
            else {
                this.props.onSuccessFunction();
            }
        }));
    }
    /**
     * update values for old tenant system
     * @param authlevel
     * @param checked
     */
    clickAuthlevel_OldTenantSystem(authlevel, checked) {
        // if authLevel is not set or the new tenantSystem has to be removed
        let UsersAuthlevels = this.state.Selected_Value.authLevel === undefined || this.state.Selected_Value.authLevel[0] === "{" ?
            [] : this.state.Selected_Value.authLevel.split(',');
        // remove empty authLevels
        UsersAuthlevels = UsersAuthlevels.filter((item) => item !== "");
        let userdata = this.state.Selected_Value;
        if (checked) {
            if (UsersAuthlevels.indexOf(authlevel) === -1) {
                UsersAuthlevels.push(authlevel);
            }
        }
        else {
            UsersAuthlevels = UsersAuthlevels.filter((items) => items !== authlevel);
        }
        userdata.authLevel = UsersAuthlevels.sort().join(',');
        this.setState({ Selected_Value: userdata });
    }
    getAuthLevel_NewTenantManagement(globalid, defaultAuth) {
        return React.createElement(Row, null,
            React.createElement(Col, null,
                React.createElement(SetGlobalTenantSystemContent, { tenantSystem: this.state.tenantSystem, globalId: globalid, authLevels: this.state.AuthLevels, defaultAuth: defaultAuth, onEditFunction: this.EditScopeValue.bind(this) }),
                React.createElement(UserTenantManagement, { tenantSystem: this.state.tenantSystem, setTenantSystemFunction: this.setTenantSystemFunction.bind(this), projectId: "null", authLevels: this.state.AuthLevels, listOfIDMSSubscopes: this.state.listOfProjectSubscopes, global: false, useSelectOptions: false }),
                React.createElement("p", null, "Please remember to click \"Add\" for each new scope."),
                React.createElement("br", null),
                React.createElement(Button, { className: "uib-button uib-button--primary", onClick: () => this.addScopeFull(defaultAuth) }, "Add Scope")));
    }
    setTenantSystemFunction(tenantSystem) {
        this.setState({ tenantSystem: tenantSystem });
    }
    emailListDeleteItem(item) {
        let emails = this.state.emailList;
        emails.splice(emails.indexOf(item), 1);
        this.setState({ emailList: emails });
    }
    addMailsToList(maillist) {
        let errors = [];
        maillist.forEach(item => {
            let error = this.addMailToList(item);
            if (error !== undefined) {
                errors.push(error);
            }
        });
        if (errors.length > 0) {
            HubFunctions.PopupCreate("Error", "Errors during adding " + errors.join(", ") + ". Probably wrong or duplicate email address.");
        }
    }
    /**
     * Add mail to emaillist
     */
    addMailToList(newMail = "") {
        let newmailtmp;
        if (newMail !== "") {
            newmailtmp = newMail;
        }
        else if (this.state.Selected_Value.mail !== "") {
            newmailtmp = this.state.Selected_Value.mail;
        }
        if (isValidEmail(newmailtmp)) {
            if (this.state.emailList.indexOf(newmailtmp) !== -1) {
                HubFunctions.PopupCreate("Error", "Email already exists");
                return newmailtmp;
            }
            let user = this.state.Selected_Value;
            user.mail = "";
            this.setState({ emailList: [...this.state.emailList, newmailtmp], Selected_Value: user });
            return;
        }
        else {
            HubFunctions.PopupCreate("Error", "Please enter a valid email address");
            return newmailtmp;
        }
    }
    render() {
        let authLevel;
        let defaultAuth = this.state.AppConfig.defaultAuth === undefined ? "None" : this.state.AppConfig.defaultAuth;
        // ------ NEW tenant system ------
        if (this.state.AppConfig.DisableTenantManagement !== "true") {
            // id of the global scope
            let globalid = this.state.tenantSystem.find(x => x.scopes.length === 1 && x.scopes.every((value, index) => value === ["user"][index])).id;
            // set global to default auth if not set
            if (this.state.tenantSystem.find(x => x.id === globalid).value === "") {
                this.EditScopeValue(globalid, defaultAuth);
            }
            authLevel = this.getAuthLevel_NewTenantManagement(globalid, defaultAuth);
            // ---- OLD tenant system -----
        }
        else {
            // if the authLevels are set to the new system, ignore the config string, else separate them in a list
            let authlevels = this.state.Selected_Value.authLevel === undefined || this.state.Selected_Value.authLevel.indexOf('{') !== -1 ?
                [""] : this.state.Selected_Value.authLevel.split(',');
            authLevel = React.createElement(Row, null,
                React.createElement(Col, { md: 2, className: "align-self-center" }, "Values"),
                React.createElement(Col, null, this.state.AuthLevels.map((level, index) => {
                    return React.createElement(Form.Check, { inline: true, label: level, key: index, checked: authlevels.indexOf(level) !== -1, onChange: (item) => {
                            this.clickAuthlevel_OldTenantSystem(level, item.target.checked);
                        } });
                })));
        }
        // ---- OLD tenant system END -----
        let applyButton = React.createElement("div", null);
        if (isValidEmail(this.state.Selected_Value.mail) ||
            (this.state.Selected_Value.mail === "" && this.state.emailList.length > 0)) {
            // check if all tmp values of users_ tenent system are empty
            if ((this.state.tenantSystem.every(x => x.tmp === "") && this.state.AppConfig.DisableTenantManagement !== "true")
                || (this.state.AppConfig.DisableTenantManagement === "true" && this.state.Selected_Value.authLevel !== undefined && this.state.Selected_Value.authLevel !== "" && this.state.Selected_Value.authLevel[0] !== "{")) {
                applyButton = React.createElement(Button, { className: "uib-button uib-button--primary addUserButton", onClick: () => { this.saveUser(); } }, "Apply");
            }
            else {
                // show a warning if there are still values in the tenant-system
                applyButton = React.createElement(OverlayTrigger, { placement: "top", delay: { show: 50, hide: 200 }, overlay: React.createElement(Tooltip, { id: "tooltip-disabled" }, "Please make sure all Scopes are added by clicking \"Add\".") },
                    React.createElement(Button, { className: `uib-button uib-button--primary addUserButton ${styles.warningButton}` }, "Apply"));
            }
        }
        return React.createElement("div", null,
            React.createElement(ModalHeader, { closeButton: true }, "Add/Edit User"),
            React.createElement(ModalBody, null,
                React.createElement(Container, null,
                    this.state.emailList.length > 0 && this.state.editMode === UsersEditMode.Add ?
                        React.createElement(Row, { className: styles.popupSpaceBetween },
                            React.createElement(Col, { md: 2, className: "align-self-center" }, "Emails"),
                            React.createElement(Col, null, this.state.emailList.map((item, index) => {
                                return React.createElement(Button, { className: `${styles.spaceRight} ${styles.spaceButtom}`, key: index, onClick: () => this.emailListDeleteItem(item) },
                                    item,
                                    " ",
                                    IconTrash);
                            })))
                        : React.createElement("div", null),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 2, className: "align-self-center" }, "Email"),
                        React.createElement(Col, null,
                            React.createElement(FormControl, { placeholder: "Email adress", value: this.state.Selected_Value.mail, id: "users_email", onChange: (event) => {
                                    let user = this.state.Selected_Value;
                                    user.mail = event.target.value;
                                    this.setState({ Selected_Value: user });
                                } })),
                        this.state.editMode === UsersEditMode.Add ?
                            React.createElement(React.Fragment, null,
                                React.createElement(Col, { md: "auto" },
                                    React.createElement(Button, { className: "uib-button uib-button--primary", variant: "primary", onClick: () => this.addMailToList() }, " Add ")),
                                React.createElement(Col, { md: "auto" },
                                    React.createElement(FileUploaderUserMails, { handleFile: (e) => this.addMailsToList(e) })))
                            : React.createElement("div", null)),
                    !isValidEmail(this.state.Selected_Key) ?
                        React.createElement(Row, { className: styles.popupSpaceBetween },
                            React.createElement(Col, { md: 2, className: "align-self-center" }, "Key"),
                            React.createElement(Col, null, this.state.Selected_Key)) : React.createElement(React.Fragment, null),
                    authLevel)),
            React.createElement(ModalFooter, null,
                React.createElement(Button, { className: `uib-button uib-button--secondary ${styles.spaceRight}`, variant: "secondary", onClick: () => this.props.closeFunction() }, "Cancel"),
                applyButton));
    }
}
