var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Popup } from "../Interfaces/interfaces";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { AppDisplayName, authLevels, configDescriptionsTexts, defaultAuth, IconDelete, IconEdit, userTenant } from "../constants";
import { sendConfigEditDataCall } from "../BackendFacade/apiCalls";
import { isUserroleSufficient } from "../../../Utils/authorization";
import { UserRoles } from "../../../Utils";
import { stringContainsJson } from "../../../Utils/checker";
import styles from './../IDMS.module.css';
import { HubPathRouting } from "../../../HubFramework/pathBuilder";
import HubFunctions from "../../../HubFramework/hubFunctions";
import { getLinkedId } from "../../ProjectContent/Utils/projectInfo";
import { ConfigModalMode } from "../Interfaces/IAddEditConfigModal";
import { Config_AddEditModal } from "./Modals/Config_AddEdit";
const FIXED_CONFIGS = [authLevels, AppDisplayName, userTenant, defaultAuth];
export class Config extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Popup: Popup.None,
            clientId: "",
            AddEditConfigModal: undefined
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            HubFunctions.showLoading();
            let prom1 = this.getIDMSConfig();
            let prom2 = this.getLinkedId();
            yield Promise.all([prom1, prom2]);
            HubFunctions.hideLoading();
        });
    }
    getIDMSConfig() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.props.parentModule.getConfigData();
        });
    }
    getLinkedId() {
        return __awaiter(this, void 0, void 0, function* () {
            getLinkedId(HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId).then((data) => {
                this.setState({ clientId: data.projectId });
            });
        });
    }
    /**
     * Activate popup for delete config entry confirmation
     * @param key key of the config entry
     */
    Config_Delete_show(key) {
        HubFunctions.PopupCreateSingle("Delete Entry", "Do you really want to delete " + key + "?", () => this.Config_Delete_apply(key));
    }
    /**
     * Confirm config entry deletion
     */
    Config_Delete_apply(key) {
        let sendValue = {};
        sendValue[key] = "";
        console.log(sendValue);
        let valueObj = { value: sendValue };
        sendConfigEditDataCall(this.props.parentModule.state.projectID, this.props.parentModule.state.moduleInstanceId, valueObj).then((data) => __awaiter(this, void 0, void 0, function* () {
            if (!data.success) {
                HubFunctions.PopupCreate("Error", data.message);
                throw 'error';
            }
        })).then(() => this.props.parentModule.getConfigData())
            .catch();
    }
    // load the config once if it is available, else provide empty fields
    Config_EditEntry_show(key = "") {
        let mail = {
            mailReceivers: "",
            mailContent: "",
            subject: "",
            senderMail: "",
            templateID: "",
            templateData: ""
        };
        let AddEditModal = {
            Selected_Key: key,
            Selected_Mail: mail,
            ErrorMessage: "",
            tmp: "",
            Selected_Value: "",
            mode: key === "" ? ConfigModalMode.Add : ConfigModalMode.Edit,
        };
        // new Config line or edited to empty category
        if (this.props.parentModule.state.AppConfig[key] === undefined) {
            AddEditModal.Selected_Value = "";
        }
        else {
            // config value contains json for mail, fill mail form, plain value else
            if (stringContainsJson(this.props.parentModule.state.AppConfig[key])) {
                AddEditModal.Selected_Mail = JSON.parse(this.props.parentModule.state.AppConfig[key]);
                AddEditModal.Selected_Value = "";
            }
            else {
                AddEditModal.Selected_Value = this.props.parentModule.state.AppConfig[key];
            }
        }
        this.setState({ AddEditConfigModal: AddEditModal, Popup: Popup.Config_AddEdit });
    }
    closePopup() {
        this.setState({ Popup: Popup.None });
    }
    /**
     * Return description text if available, else a default answer
     * @param key
     * @constructor
     */
    Config_getDescriptionText(key) {
        let ret = [];
        if (configDescriptionsTexts[key] === undefined) {
            ret = ["Event: A users role was changed from one to another.", "There are several variations of this event, covering every possible change of roles."];
        }
        else {
            ret = configDescriptionsTexts[key];
        }
        // put every line in a <p> tag
        return React.createElement("div", null, ret.map((line, index) => {
            return React.createElement("p", { key: index }, line);
        }));
    }
    Config_DisplayInfo_show(key) {
        HubFunctions.PopupCreate("Info", this.Config_getDescriptionText(key));
    }
    // Display Mail receiver if possible, value else
    Config_evaluateDisplayedValue(value) {
        if (value.indexOf('{') !== -1)
            try {
                let mail = JSON.parse(value);
                return "mailto: " + mail.mailReceivers;
            }
            catch (e) {
            }
        return value;
    }
    render() {
        let popupContent = React.createElement("div", null);
        switch (this.state.Popup) {
            case Popup.Config_AddEdit:
                popupContent = React.createElement(Config_AddEditModal, { closeFunction: () => this.closePopup(), state: this.state.AddEditConfigModal, parentModule: this.props.parentModule });
                break;
            default:
                break;
        }
        return React.createElement("div", { className: styles.PageConfig },
            React.createElement(Modal, { size: "lg", show: this.state.Popup !== Popup.None, onHide: () => this.setState({ Popup: Popup.None }) }, popupContent),
            React.createElement(Form, null,
                React.createElement(Table, { striped: true, className: styles.maintable },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", null, "Key"),
                            React.createElement("th", null, "Value"),
                            React.createElement("th", { className: styles.alignCenter }, "Info"),
                            isUserroleSufficient(UserRoles.editor, HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId) &&
                                React.createElement(React.Fragment, null,
                                    React.createElement("th", { className: styles.alignCenter }, "Edit"),
                                    React.createElement("th", { className: styles.alignCenter }, "Delete")))),
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", { className: styles.configValueHeight }, "Client ID"),
                            React.createElement("td", null, this.state.clientId),
                            React.createElement("td", { className: styles.alignCenter },
                                React.createElement(Button, { variant: "outline-primary", className: styles.ButtonLogo, onClick: () => {
                                        this.Config_DisplayInfo_show("clientId");
                                    } }, "?")),
                            React.createElement("td", null),
                            React.createElement("td", null)),
                        Object.keys(this.props.parentModule.state.AppConfig).map((col, index) => {
                            let deleteButton = FIXED_CONFIGS.indexOf(col) !== -1 ? React.createElement("td", null) :
                                React.createElement("td", { className: styles.alignCenter },
                                    React.createElement(Button, { variant: "outline-danger", className: styles.ButtonLogo, onClick: () => {
                                            this.Config_Delete_show(col);
                                        } }, IconDelete));
                            return React.createElement("tr", { key: index },
                                React.createElement("td", null, col),
                                React.createElement("td", null, this.Config_evaluateDisplayedValue(this.props.parentModule.state.AppConfig[col])),
                                React.createElement("td", { className: styles.alignCenter },
                                    React.createElement(Button, { variant: "outline-primary", className: styles.ButtonLogo, onClick: () => {
                                            this.Config_DisplayInfo_show(col);
                                        } }, "?")),
                                isUserroleSufficient(UserRoles.editor, HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId) &&
                                    React.createElement(React.Fragment, null,
                                        React.createElement("td", { className: styles.alignCenter },
                                            React.createElement(Button, { variant: "outline-primary", className: styles.ButtonLogo, onClick: () => {
                                                    this.Config_EditEntry_show(col);
                                                } }, IconEdit)),
                                        deleteButton));
                        }))),
                isUserroleSufficient(UserRoles.editor, HubPathRouting.currentProject.moduleInstanceId, HubPathRouting.currentModule.moduleInstanceId) ?
                    React.createElement(Button, { className: "uib-button uib-button--primary", onClick: () => this.Config_EditEntry_show() }, "+ Add") : ""));
    }
}
