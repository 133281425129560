/**
 * This folder should all the api calls that are used in the module.
 * Every call must use the fetchFromApi function from the backendCalls.tsx file.
 * This function will handle the authentication and error handling.
 */
import { fetchFromApi, getBackendApiURL } from "BackendFacade";
import { deepCopy } from "../../../Utils/transformer";
export function getProject(projectId) {
    const url = getBackendApiURL() + "projects/" + projectId;
    return fetchFromApi(url, "GET");
}
export function updateProject(project) {
    const url = getBackendApiURL() + "projects";
    let value = { value: project };
    return fetchFromApi(url, "POST", value);
}
export function createModuleInstance(projectId, module) {
    let moduleToSend = deepCopy(module);
    const url = getBackendApiURL() + "projects/" + projectId + "/modules?migrateExisting=" + module.migrateExisting;
    delete moduleToSend.creationProps;
    return fetchFromApi(url, "PUT", {
        value: moduleToSend,
        moduleConfig: convertCreationPropertiesToDictionary(module.creationProps)
    });
}
export function updateModuleInstance(projectId, module) {
    const url = getBackendApiURL() + "projects/" + projectId + "/modules" + "?migrateExisting=true";
    return fetchFromApi(url, "POST", {
        value: module,
        moduleConfig: module.creationProps
    });
}
export function deleteModuleInstance(projectId, moduleInstanceId) {
    const url = getBackendApiURL() + "projects/" + projectId + "/modules/" + moduleInstanceId + "?hardDelete=false";
    return fetchFromApi(url, "DELETE");
}
function convertCreationPropertiesToDictionary(creationProps) {
    let dict = {};
    creationProps.forEach((prop) => {
        if (prop.type === "subProps") {
            Object.keys(prop.subProps).forEach((key) => {
                dict[key] = convertCreationPropertiesToDictionary(prop.subProps[key]);
            });
        }
        else {
            dict[prop.name] = prop.value;
        }
    });
    return dict;
}
export function DeleteProject(projectId, hardDelete) {
    const url = getBackendApiURL() + "projects/" + projectId + "?hardDelete=" + hardDelete;
    // if (hardDelete) {
    //     const headerAdditions = {
    //         "hardDelete": true
    //     }
    //     return fetchFromApi(url, "DELETE", headerAdditions)
    // }
    return fetchFromApi(url, "DELETE");
}
