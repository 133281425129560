var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Popup, SearchBy, UsersEditMode } from "../Interfaces/interfaces";
import { Button, Col, Form, FormControl, Modal, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import Select from "react-select";
import { DisableTenantManagement, ScopeAny, ValueAny } from "../constants";
import { getUserDataCall, sendUsersEditDataCall } from "../BackendFacade/apiCalls";
import makeAnimated from "react-select/animated";
import { IconDelete, IconEdit } from "../../../Assets/svgs";
import { isValidEmail } from "Utils/checker";
import { convertIntToDate, deepCopy } from "../../../Utils/transformer";
import { AddEditUserModal } from "./Modals/UsersTab_AddEdit";
import styles from './../IDMS.module.css';
import { isUserroleSufficient } from "../../../Utils/authorization";
import { UserRoles } from "../../../Utils";
import { HubPathRouting } from "../../../HubFramework/pathBuilder";
import { GlobalTenantSystemContent, TenantSystemContent } from "../Utils/tenantSystem";
import HubFunctions from "../../../HubFramework/hubFunctions";
const animatedComponents = makeAnimated();
export class UsersTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Sort_Key: "authLevel",
            Sort_Reverse: false,
            filterBy: SearchBy.Include,
            filterState: {},
            filterState_oldTen: [],
            filterValueState: {},
            listOfScopes: [],
            IDFilter: "",
            Selected_Key: "",
            ShowIDFilter: false,
            listOfProjectSubscopes: [],
            tenantSystem: [],
            userData: {},
            Popup: Popup.None,
            AddEditModal: undefined,
            PopupContent: ""
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            HubFunctions.showLoading();
            let prom1 = this.getUserData();
            let prom2 = this.props.parentModule.getConfigData();
            yield Promise.all([prom1, prom2]);
            HubFunctions.hideLoading();
        });
    }
    getUserData() {
        return __awaiter(this, void 0, void 0, function* () {
            this.setState({ userData: {}, Popup: Popup.None });
            this.resetFilter();
            yield getUserDataCall(this.props.parentModule.state.projectID, this.props.parentModule.state.moduleInstanceId).then((data) => {
                if (!data.success) {
                    HubFunctions.PopupCreate("Error", data.message);
                }
                else {
                    // convert list of users to dictionary
                    let userData = {};
                    data.data.value.forEach((user) => {
                        userData[user.id] = user;
                        userData[user.id].tenantSystem = this.convertAuthlevelToTenantsystem(user.authLevel);
                    });
                    let scopesOverview = this.createListOfSubScopes(userData);
                    this.setState({ userData: userData,
                        listOfProjectSubscopes: scopesOverview.subscopelist,
                        listOfScopes: scopesOverview.scopelist });
                }
            });
        });
    }
    /**
     * Creates a list of all subscopes
     */
    createListOfSubScopes(userData) {
        let scopeList = [];
        let subScopes = [];
        let userKeys = Object.keys(userData);
        userKeys.forEach((key) => {
            let tenantLines = userData[key].tenantSystem;
            tenantLines.forEach((tenant) => {
                tenant.scopes.forEach((subscope) => {
                    if (subScopes.indexOf(subscope) === -1)
                        subScopes.push(subscope);
                });
                if (scopeList.indexOf(tenant.scopes.join('/')) === -1)
                    scopeList.push(tenant.scopes.join('/'));
            });
        });
        return { scopelist: scopeList, subscopelist: subScopes };
    }
    /**
     * Displays the values if the
     * @param col key of the user
     * @returns
     */
    convertUsersValueToButtonOrText(col) {
        let value = this.state.userData[col].authLevel;
        if (value === undefined)
            return "";
        if (value.indexOf('{') !== -1 && this.props.parentModule.state.AppConfig.DisableTenantManagement === "true") {
            return React.createElement(Button, { variant: "outline-danger", className: styles.details, disabled: true }, "Wrong Config");
        }
        else if (value.indexOf('{') !== -1) {
            return React.createElement(Button, { variant: "outline-primary", className: styles.details, onClick: () => this.Details_show(col) }, "Details");
        }
        else if (value.indexOf('{') === -1 && this.props.parentModule.state.AppConfig.DisableTenantManagement !== "true") {
            return React.createElement(Button, { variant: "outline-danger", className: styles.details, disabled: true }, "Wrong Config");
        }
        else {
            return value;
        }
    }
    /**
     * Displays the details of the tenant config
     * @param key
     * @constructor
     */
    Details_show(key) {
        const globalId = this.state.userData[key].tenantSystem.find(x => x.scopes.length === 1 && x.scopes.every((value, index) => value === ["user"][index])).id;
        const header = `Details for ${key} ${isValidEmail(key) ?
            "(no key available)" : "(" + this.state.userData[key].mail + ")"}`;
        const tenantSystem = this.state.userData[key].tenantSystem;
        const content = React.createElement(React.Fragment, null,
            React.createElement(GlobalTenantSystemContent, { tenantSystem: tenantSystem, globalId: globalId }),
            React.createElement(TenantSystemContent, { tenantSystem: tenantSystem, global: false }));
        HubFunctions.PopupCreateSingle(header, content);
    }
    /**
     * prepare user add/edit popup config
     * @param key key of the user, empty for a new user
     */
    EditEntry_show(key = "") {
        let AddEdit = {
            AppConfig: this.props.parentModule.state.AppConfig,
            AuthLevels: this.props.parentModule.state.AuthLevels,
            editMode: undefined,
            userData: this.state.userData,
            tenantSystem: undefined,
            emailList: [],
            Selected_Key: key,
            Selected_Value: undefined,
            listOfProjectSubscopes: this.state.listOfProjectSubscopes,
            OldSelected_key: key,
        };
        // new Configline or edited to empty category
        if (this.state.userData[key] === undefined) {
            AddEdit.editMode = UsersEditMode.Add;
            AddEdit.Selected_Value = { authLevel: "", lastLogin: 0, mail: "" };
            AddEdit.tenantSystem = [{ id: 0, scopes: ["user"], value: "", tmp: "", fullscope: "user" }];
        }
        else {
            // convert config entry to new tenant system
            let scopes = [];
            if (this.props.parentModule.state.AppConfig[DisableTenantManagement] !== "true") {
                scopes = this.convertAuthlevelToTenantsystem(this.state.userData[key].authLevel);
            }
            // if the user is new and hasn't logged in so far, edit his pre-profile
            if (isValidEmail(key)) {
                // eslint-disable-next-line react/no-direct-mutation-state -- valid, state is set in next line
                this.state.userData[key].mail = key;
            }
            AddEdit.editMode = UsersEditMode.Edit;
            AddEdit.Selected_Value = deepCopy(this.state.userData[key]);
            AddEdit.tenantSystem = scopes;
        }
        this.setState({ Popup: Popup.Users_AddEdit, Selected_Key: key, AddEditModal: AddEdit });
    }
    /**
     * Activate popup for delete config entry confirmation
     * @param key key of the config entry
     */
    Delete_show(key) {
        HubFunctions.PopupCreate("Delete User", `Do you really want to delete ${key}?`, this.Delete_apply.bind(this, key));
    }
    /**
     * Confirm user entry deletion
     */
    Delete_apply(key) {
        let sendValue = [];
        sendValue.push({ id: key, newValue: "" });
        let valueobj = { value: sendValue };
        sendUsersEditDataCall(this.props.parentModule.state.projectID, this.props.parentModule.state.moduleInstanceId, valueobj).then((data) => __awaiter(this, void 0, void 0, function* () {
            if (!data.success) {
                HubFunctions.PopupCreate("Error", data.message);
            }
            else {
                yield this.getUserData();
            }
        }));
    }
    // convert the tenant string for the GUI
    convertAuthlevelToTenantsystem(authlevel) {
        let scopes = [];
        if (authlevel !== undefined && authlevel.indexOf('{') !== -1) {
            let userconf = JSON.parse(authlevel);
            for (let key in userconf) {
                // global scope
                if (userconf.hasOwnProperty(key) && key === "user") {
                    scopes.push({ id: scopes.length, scopes: ["user"], value: userconf[key], tmp: "", fullscope: "user" });
                }
                // userscopes
                if (userconf.hasOwnProperty(key) && key !== "user") {
                    let a = key.split('/');
                    scopes.push({ id: scopes.length, scopes: a, value: userconf[key], tmp: "", fullscope: key });
                }
            }
        }
        // add global scope if it does not exist
        if (scopes.find(x => x.scopes[0] === "user" && x.scopes.length === 1) === undefined) {
            scopes.push({ id: scopes.length, scopes: ["user"], value: "", tmp: "", fullscope: "user" });
        }
        return scopes;
    }
    /**
     * Filter the users list by the filter
     * @param list
     * @param listOfKeys
     */
    applyFilter(list, listOfKeys) {
        // old tenantmangement
        if (this.props.parentModule.state.AppConfig.DisableTenantManagement && this.props.parentModule.state.AppConfig.DisableTenantManagement === "true") {
            // list of filters
            let filters = this.state.filterState_oldTen.map(x => x.value);
            // filter by value
            if (filters.length > 0) {
                listOfKeys = listOfKeys.filter(x => filters.indexOf(list[x].authLevel) !== -1);
            }
        }
        else {
            // new tenant management    
            // include filter
            if (this.state.filterBy === SearchBy.Include) {
                if (this.state.filterState.value === ScopeAny) {
                    // filter by value
                    if (this.state.filterValueState.value !== ValueAny) {
                        listOfKeys = listOfKeys.filter(x => list[x].tenantSystem.find(y => y.value === this.state.filterValueState.value) !== undefined);
                    }
                }
                else {
                    if (this.state.filterValueState.value === ValueAny) {
                        listOfKeys = listOfKeys.filter(x => list[x].tenantSystem.find(y => y.fullscope === this.state.filterState.value) !== undefined);
                    }
                    else {
                        listOfKeys = listOfKeys.filter(x => list[x].tenantSystem.find(y => y.fullscope === this.state.filterState.value && y.value === this.state.filterValueState.value) !== undefined);
                    }
                }
            }
            else {
                // exclude filter
                if (this.state.filterState.value === ScopeAny) {
                    // filter by value
                    if (this.state.filterValueState.value !== ValueAny) {
                        listOfKeys = listOfKeys.filter(x => list[x].tenantSystem.find(y => y.value === this.state.filterValueState.value) === undefined);
                    }
                }
                else {
                    if (this.state.filterValueState.value === ValueAny) {
                        listOfKeys = listOfKeys.filter(x => list[x].tenantSystem.find(y => y.fullscope === this.state.filterState.value) === undefined);
                    }
                    else {
                        listOfKeys = listOfKeys.filter(x => list[x].tenantSystem.find(y => y.fullscope === this.state.filterState.value && y.value === this.state.filterValueState.value) === undefined);
                    }
                }
            }
        }
        // search by ID or email filter
        if (this.state.ShowIDFilter && this.state.IDFilter !== "" && this.state.IDFilter.length >= 3) {
            listOfKeys = listOfKeys.filter(x => {
                return (list[x].mail !== undefined && list[x].mail !== null && list[x].mail.indexOf(this.state.IDFilter) !== -1);
            });
        }
        return listOfKeys;
    }
    /**
     * sort the users list by clicking on the column header
     * @param list
     * @param key
     * @param reverse
     */
    sortListByKey(list, key, reverse = false) {
        if (Object.keys(list).length === 0) {
            return [];
        }
        let listofKeys = Object.keys(list);
        // apply filters
        listofKeys = this.applyFilter(list, listofKeys);
        // if first item of list is a number
        switch (key) {
            case "lastLogin":
                listofKeys.sort((a, b) => {
                    // if lastlogin is undefined, sort it to the end
                    if (list[a].lastLogin === undefined) {
                        return 1;
                    }
                    if (list[b].lastLogin === undefined) {
                        return -1;
                    }
                    if (list[a].lastLogin < list[b].lastLogin) {
                        return 1;
                    }
                    if (list[a].lastLogin > list[b].lastLogin) {
                        return -1;
                    }
                    return 0;
                });
                break;
            case "mail":
                listofKeys.sort((a, b) => {
                    // if key is undefined, sort by userMail instead
                    let aMail = list[a][key] === undefined ? list[a].mail : list[a][key];
                    let bMail = list[b][key] === undefined ? list[b].mail : list[b][key];
                    if (aMail < bMail) {
                        return -1;
                    }
                    if (aMail > bMail) {
                        return 1;
                    }
                    return 0;
                });
                break;
            default:
                listofKeys.sort((a, b) => {
                    if (list[a][key].toLowerCase() < list[b][key].toLowerCase()) {
                        return -1;
                    }
                    if (list[a][key].toLowerCase() > list[b][key].toLowerCase()) {
                        return 1;
                    }
                    // sort by key LastLogin if they are equal
                    if (list[a].LastLogin === undefined) {
                        return 1;
                    }
                    if (list[b].LastLogin === undefined) {
                        return -1;
                    }
                    if (list[a].LastLogin < list[b].LastLogin) {
                        return 1;
                    }
                    if (list[a].LastLogin > list[b].LastLogin) {
                        return -1;
                    }
                    return 0;
                });
        }
        if (reverse) {
            listofKeys.reverse();
        }
        return listofKeys;
    }
    setSortByKey(key) {
        this.setState({ Sort_Reverse: !this.state.Sort_Reverse, Sort_Key: key });
    }
    sort() {
        return this.sortListByKey(this.state.userData, this.state.Sort_Key, this.state.Sort_Reverse);
    }
    showSortIcon(key) {
        if (key === this.state.Sort_Key) {
            return this.state.Sort_Reverse ? React.createElement("i", { className: "uib-icon--arrow1-down uib-icon" }) : React.createElement("i", { className: "uib-icon--arrow1-up uib-icon" });
        }
    }
    resetFilter() {
        this.setState({ filterState_oldTen: [], filterState: { value: "any", label: "any" }, filterValueState: { value: "any", label: "any" } });
    }
    /**
     * Displays the filter above the users list
     * @constructor
     */
    filterbar() {
        let buttons = React.createElement("div", { className: `col col-auto ${styles.RButtons}` },
            React.createElement("label", { className: "col col-form-label" }),
            React.createElement("div", { className: "col-auto " },
                React.createElement(OverlayTrigger, { placement: "top", delay: { show: 150, hide: 200 }, overlay: React.createElement(Tooltip, { id: "tooltip-disabled" }, "Reset filters") },
                    React.createElement(Button, { className: `uib-button uib-button--primary uib-button--square ${styles.ButtonLogo} ${styles.spaceRightBigger}`, onClick: () => this.resetFilter() },
                        React.createElement("i", { className: `uib-icon uib-icon--settings-leave ${styles.logo}` }))),
                React.createElement(OverlayTrigger, { placement: "top", delay: { show: 150, hide: 200 }, overlay: React.createElement(Tooltip, { id: "tooltip-disabled" }, "Show (activate) /hide (deactivate) the filter by key") },
                    React.createElement(Button, { className: `uib-button uib-button--primary uib-button--square ${styles.ButtonLogo}`, onClick: () => this.setState({ ShowIDFilter: !this.state.ShowIDFilter }) },
                        React.createElement("i", { className: `uib-icon uib-icon--search ${styles.logo}` })))));
        let filterByKey = React.createElement("div", null);
        if (this.state.ShowIDFilter) {
            filterByKey = React.createElement("div", { className: "col col-md-4" },
                React.createElement("label", { className: "col col-form-label" }, "Filter by key (hidden field) and email"),
                React.createElement("div", { className: "col" },
                    React.createElement(FormControl, { id: "plainvalue", placeholder: "Value", onChange: (event) => { this.setState({ IDFilter: event.target.value }); }, value: this.state.IDFilter })));
        }
        // if tenant management is disabled
        if (this.props.parentModule.state.AppConfig.DisableTenantManagement && this.props.parentModule.state.AppConfig.DisableTenantManagement === "true") {
            return React.createElement(React.Fragment, null,
                React.createElement("div", { className: `form-group row ${styles.filterRow}` },
                    React.createElement("div", { className: "col col-md-4" },
                        React.createElement("label", { className: "col col-form-label" }, "Filter by Value"),
                        React.createElement("div", { className: "col" },
                            React.createElement(Select, { closeMenuOnSelect: true, components: animatedComponents, placeholder: "Select values. If empty, all are selected", isMulti: true, options: this.props.parentModule.state.AppConfig.authLevels.split(',').map(x => { return { value: x, label: x }; }), onChange: (option) => { this.setState({ filterState_oldTen: option }); }, value: this.state.filterState_oldTen, className: styles.selectFields }))),
                    buttons,
                    filterByKey));
        }
        // add any to option lists
        let scopes = this.state.listOfScopes.map(x => { return { value: x, label: x }; });
        scopes.push({ value: ScopeAny, label: ScopeAny });
        let authLevels = this.props.parentModule.state.AuthLevels.map(x => { return { value: x, label: x }; });
        authLevels.push({ value: ValueAny, label: ValueAny });
        // if tenant management is enabled
        return React.createElement(React.Fragment, null,
            React.createElement("div", { className: `form-group row ${styles.filterRow}` },
                React.createElement("div", { className: "col col-md-auto" },
                    React.createElement("label", { className: "col col-form-label" }, "Search for"),
                    React.createElement("div", { className: "col" },
                        React.createElement(Button, { className: this.state.filterBy === SearchBy.Exclude ? `uib-button uib-button--secondary ${styles.details} ${styles.spaceRight}` : `uib-button uib-button--primary ${styles.details} ${styles.spaceRight}`, onClick: () => this.setState({ filterBy: SearchBy.Include }) }, "Include"),
                        React.createElement(Button, { className: this.state.filterBy === SearchBy.Exclude ? `uib-button uib-button--primary ${styles.details} ` : `uib-button uib-button--secondary ${styles.details} `, onClick: () => this.setState({ filterBy: SearchBy.Exclude }) }, "Exclude"))),
                React.createElement("div", { className: "col col-md-4" },
                    React.createElement("label", { className: "col col-form-label" }, "Filter by Scopes"),
                    React.createElement("div", { className: "col" },
                        React.createElement(Select, { closeMenuOnSelect: true, components: animatedComponents, placeholder: "Select scopes", options: scopes, onChange: (option) => { this.setState({ filterState: option }); }, value: this.state.filterState, className: styles.selectFields }))),
                React.createElement("div", { className: "col col-md-2" },
                    React.createElement("label", { className: "col col-form-label" }, "Value"),
                    React.createElement("div", { className: "col" },
                        React.createElement(Select, { closeMenuOnSelect: true, components: animatedComponents, placeholder: "Value of scope", options: authLevels, onChange: (option) => { this.setState({ filterValueState: option }); }, value: this.state.filterValueState, className: styles.selectFields }))),
                buttons,
                filterByKey));
    }
    convertIntToDate(value) {
        let val = convertIntToDate(value);
        if (val === null) {
            return "Never logged in yet.";
        }
        else {
            return val;
        }
    }
    closePopup() {
        this.setState({ Popup: Popup.None });
    }
    render() {
        var _a, _b, _c, _d;
        const Sorted = this.sort();
        let popupContent = React.createElement("div", null);
        switch (this.state.Popup) {
            default:
                break;
            case Popup.Users_AddEdit:
                popupContent = React.createElement(AddEditUserModal, { onSuccessFunction: this.getUserData.bind(this), closeFunction: this.closePopup.bind(this), state: this.state.AddEditModal, projectID: this.props.parentModule.state.projectID, moduleInstanceId: this.props.parentModule.state.moduleInstanceId });
                break;
        }
        return React.createElement(React.Fragment, null,
            React.createElement(Modal, { size: "lg", show: this.state.Popup !== Popup.None, onHide: () => this.setState({ Popup: Popup.None }) }, popupContent),
            this.filterbar(),
            React.createElement("div", { className: styles.ScrollPageUsers },
                React.createElement(Form, null,
                    React.createElement(Table, { striped: true, className: `${styles.maintable} uib-table uib-table--striped` },
                        React.createElement("thead", { className: `uib-table__header ${styles.tableHead}` },
                            React.createElement("tr", { className: "uib-table__row--header" },
                                React.createElement("th", { className: styles.hoverPointer, onClick: () => this.setSortByKey("mail") },
                                    "Email",
                                    this.showSortIcon("userMail")),
                                React.createElement("th", { className: `${styles.alignCenter} ${styles.hoverPointer}`, onClick: () => this.setSortByKey("authLevel") },
                                    "Value",
                                    this.showSortIcon("AuthLevel")),
                                React.createElement("th", { className: styles.hoverPointer, onClick: () => this.setSortByKey("lastLogin") },
                                    "Time of last login",
                                    this.showSortIcon("LastLogin")),
                                isUserroleSufficient(UserRoles.editor, (_a = HubPathRouting.currentProject) === null || _a === void 0 ? void 0 : _a.moduleInstanceId, (_b = HubPathRouting.currentModule) === null || _b === void 0 ? void 0 : _b.moduleInstanceId) &&
                                    React.createElement(React.Fragment, null,
                                        React.createElement("th", { className: styles.alignCenter }, "Edit"),
                                        React.createElement("th", { className: styles.alignCenter }, "Delete")))),
                        React.createElement("tbody", null, Sorted.map((col, index) => {
                            var _a, _b;
                            if (this.state.userData[col].authLevel !== undefined)
                                return React.createElement("tr", { key: index },
                                    React.createElement("td", null, this.state.userData[col].mail === null ? this.state.userData[col].id : this.state.userData[col].mail),
                                    React.createElement("td", { className: styles.alignCenter }, this.convertUsersValueToButtonOrText(col)),
                                    React.createElement("td", null, this.convertIntToDate(this.state.userData[col].lastLogin)),
                                    isUserroleSufficient(UserRoles.editor, (_a = HubPathRouting.currentProject) === null || _a === void 0 ? void 0 : _a.moduleInstanceId, (_b = HubPathRouting.currentModule) === null || _b === void 0 ? void 0 : _b.moduleInstanceId) &&
                                        React.createElement(React.Fragment, null,
                                            React.createElement("td", { className: styles.alignCenter },
                                                React.createElement(Button, { variant: "outline-primary", className: styles.ButtonLogo, onClick: () => { this.EditEntry_show(col); } }, IconEdit)),
                                            React.createElement("td", { className: styles.alignCenter },
                                                React.createElement(Button, { variant: "outline-danger", className: styles.ButtonLogo, onClick: () => { this.Delete_show(col); } }, IconDelete))));
                            return null;
                        }))))),
            React.createElement("div", { className: `${styles.container} ${styles.FooterRoot}` },
                React.createElement(Row, null,
                    isUserroleSufficient(UserRoles.editor, (_c = HubPathRouting.currentProject) === null || _c === void 0 ? void 0 : _c.moduleInstanceId, (_d = HubPathRouting.currentModule) === null || _d === void 0 ? void 0 : _d.moduleInstanceId) &&
                        React.createElement(React.Fragment, null,
                            React.createElement(Col, { md: "auto" },
                                React.createElement(Button, { className: "uib-button uib-button--primary", onClick: () => this.EditEntry_show() }, "+ Add"))),
                    React.createElement(Col, { className: styles.FooterUser },
                        "Users: ",
                        Sorted.length))));
    }
}
