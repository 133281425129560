var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Button } from "react-bootstrap";
export const FileUploaderUserMails = (props) => {
    const hiddenFileInput = React.useRef(null);
    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };
    const handleChange = (event) => {
        const fileUploaded = event.target.files[0];
        event.preventDefault();
        const reader = new FileReader();
        reader.onload = (e) => __awaiter(void 0, void 0, void 0, function* () {
            const text = String(e.target.result);
            // convert text to list of emails
            let emails = text.split('\r\n').map(e => e.split(';'));
            // flatten list of emails
            let flatemails = Array.prototype.concat.apply([], emails);
            flatemails = flatemails.map(e => { return e.trim(); }).filter(e => e !== "");
            props.handleFile(flatemails);
        });
        reader.readAsText(fileUploaded);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { className: "uib-button uib-button--primary", variant: "primary", onClick: handleClick }, "Upload a file"),
        React.createElement("input", { type: "file", ref: hiddenFileInput, onChange: (event) => { handleChange(event); }, style: { display: 'none' } })));
};
