import * as React from "react";
import { Button } from "react-bootstrap";
export class Second extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: "test"
        };
    }
    componentDidMount() {
    }
    sayHelloFromModule() {
        this.props.parentModule.moduleSayHello();
    }
    render() {
        return React.createElement(React.Fragment, null,
            React.createElement("p", null, " This is a second template page."),
            React.createElement("p", null, this.state.text),
            React.createElement(Button, { onClick: () => this.sayHelloFromModule() }, "Say Hello from Module in Console"),
            React.createElement("br", null));
    }
}
