export var SearchByApiKeys;
(function (SearchByApiKeys) {
    SearchByApiKeys[SearchByApiKeys["Include"] = 0] = "Include";
    SearchByApiKeys[SearchByApiKeys["Exclude"] = 1] = "Exclude";
})(SearchByApiKeys || (SearchByApiKeys = {}));
export var Popup;
(function (Popup) {
    Popup[Popup["None"] = 0] = "None";
    Popup[Popup["ApiKey_AddEdit"] = 1] = "ApiKey_AddEdit";
})(Popup || (Popup = {}));
export var IApiKeyContentTypes;
(function (IApiKeyContentTypes) {
    IApiKeyContentTypes["hubIdmsTenantJson"] = "hubIdmsTenantJson";
    IApiKeyContentTypes["custom"] = "custom";
})(IApiKeyContentTypes || (IApiKeyContentTypes = {}));
