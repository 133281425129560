import * as React from "react";
import { Documentation } from "../../Documentation/Documentation";
export class ProjectDocumentation extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return React.createElement(React.Fragment, null,
            React.createElement(Documentation, { component: "hubProject" }));
    }
}
