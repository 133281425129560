import * as React from "react";
export class Module extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        window.location.assign("https://draegerwerk.app.blackduck.com/api/projects/" + this.props.hubArgs.linkedId.projectId);
    }
    render() {
        return React.createElement(React.Fragment, null);
    }
}
