var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { hasKey } from "./checker";
import { loginURL, LogoutURL } from "Config/authorization";
import { decodeQueryParam } from "./transformer";
import { fetchFromApi, getBackendApiURL } from "BackendFacade/backendCalls";
import * as React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from './../HubFramework/HubFramework.module.css';
import { jwtDecode } from "jwt-decode";
const idToken = "id_token2";
const refreshToken = "refresh_token2";
/**
 * Check if the user is logged in. If not, redirect to login page. Evaluate the code from the url if given.
 * @returns
 */
export function evaluateTokens() {
    return __awaiter(this, void 0, void 0, function* () {
        // is the token in the url?
        const search = window.location.href.split("?");
        if (search.length > 1 && search[1].indexOf('=') !== -1) {
            const params = search[1].split('&');
            let dict = {};
            params.forEach((item) => {
                let parts = item.split('=');
                dict[parts[0]] = parts[1];
            });
            // error code in url received,
            if (hasKey(dict, "error")) {
                // forwarded the error to the user
                if (hasKey(dict, "error_description")) {
                    return { success: false, error: decodeQueryParam(decodeURIComponent(dict["error_description"])) };
                }
                else {
                    return { success: false, error: decodeQueryParam(decodeURIComponent(dict["error"])) };
                }
            }
            // received a code in url, send it to backend to get tokens
            if (hasKey(dict, "code")) {
                let ret = { success: false, error: "" };
                yield Promise.all([
                    fetchFromApi(getBackendApiURL() + "static/b2cLogin", "POST", "code=" + dict["code"] + "&state=" + dict["state"], "application/x-www-form-urlencoded").then((response) => __awaiter(this, void 0, void 0, function* () {
                        ret = { success: response.success, error: response.message };
                    }))
                ]);
                return ret;
            }
        }
        // return false if no token is set, so the website stops loading while redirecting
        return { success: !(getToken() == null && getRefreshToken() == null), error: "" };
    });
}
/**
 * Does a simple backend call to validate the token
 * Sets the static modules
 */
export function validateToken() {
    // check the token first if they are set
    if (getToken() == null || getRefreshToken() == null) {
        openLoginPage();
        return { success: false, error: "" };
    }
    return { success: true, error: "" };
}
export function removeTokens() {
    localStorage.removeItem(idToken);
    localStorage.removeItem(refreshToken);
}
export function openLoginPage() {
    removeTokens();
    window.location.href = loginURL;
}
export function openLogoutPage() {
    const token = getToken();
    removeTokens();
    window.location.href = LogoutURL + token;
}
export function logoutButton() {
    return React.createElement("div", { className: styles.logoutButton2 },
        React.createElement("button", { className: `uib-link ${styles.logoutButton}`, onClick: () => {
                openLogoutPage();
            } },
            "Logout",
            React.createElement("i", { className: `uib-icon uib-icon--exit ${styles.exitlogo}` })));
}
/**
 * Sets the id_token and refresh_token from the response header.
 * @param dict Response from B2C
 */
export function setTokensFromHeader(dict) {
    if (dict.headers.get("id_token") !== null) {
        setToken(dict.headers.get("id_token"));
    }
    if (dict.headers.get("refresh_token") !== null) {
        setRefreshToken(dict.headers.get("refresh_token"));
    }
}
export function getUserRole() {
    try {
        let decoded = jwtDecode(getToken());
        return decoded.authLevel;
    }
    catch (error) {
        return false;
    }
}
export function getUserEmail() {
    try {
        let decoded = jwtDecode(getToken());
        return decoded.email_address;
    }
    catch (error) {
        return false;
    }
}
export function getUserKey() {
    try {
        let decoded = jwtDecode(getToken());
        if (decoded.b2cid !== undefined) {
            return decoded.b2cid;
        }
        return decoded.sub;
    }
    catch (error) {
        return false;
    }
}
export function getToken() {
    return localStorage.getItem(idToken);
}
export function setToken(token) {
    localStorage.setItem(idToken, token);
}
export function setRefreshToken(token) {
    localStorage.setItem(refreshToken, token);
}
export function getRefreshToken() {
    return localStorage.getItem(refreshToken);
}
export function showUserAccount() {
    return React.createElement(OverlayTrigger, { placement: "bottom", delay: { show: 50, hide: 2000 }, overlay: React.createElement(Tooltip, { id: "tooltip-key" }, getUserKey()) },
        React.createElement("span", null, getUserEmail()));
}
