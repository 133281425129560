var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { SearchBy } from "../Interfaces/IDOOP";
import { optionsOISList } from "../constants";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import { animatedComponents } from "../Interfaces/IDOOP";
import { getOrderedItemsForProject, } from "../BackendFacade/apiCalls";
import * as Sentry from "@sentry/react";
import styles from './../Doop.module.css';
import HubFunctions from "../../../HubFramework/hubFunctions";
export class Overview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Areas: [],
            From: "2021-01-01",
            ProjectData: undefined,
            Search: SearchBy.LastModified,
            SortAscend: false,
            SortBy: "name",
            State: [],
            To: new Date().toISOString().substring(0, 10),
            Error: "",
            CurrentLog: "",
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            HubFunctions.showLoading();
            let prom1 = this.getProjectMetaData();
            let prom2 = this.getProjectData();
            yield Promise.all([prom1, prom2]);
            HubFunctions.hideLoading();
        });
    }
    getProjectMetaData() {
        return __awaiter(this, void 0, void 0, function* () {
            let prom1 = this.props.parentModule.getProjectData().then(data => {
                if (!data.success) {
                    HubFunctions.PopupCreate("Error", data.message);
                    Sentry.captureMessage(data.message);
                }
            });
            yield Promise.all([prom1]);
        });
    }
    getProjectData() {
        return __awaiter(this, void 0, void 0, function* () {
            let prom1 = getOrderedItemsForProject(this.props.parentModule.state.projectId, this.props.parentModule.state.moduleInstanceId).then(data => {
                if (!data.success) {
                    HubFunctions.PopupCreate("Error", data.message);
                    Sentry.captureMessage(data.message);
                }
                else {
                    this.setState({ ProjectData: data.data }, () => { this.OO_sort(); });
                }
            });
            yield Promise.all([prom1]);
        });
    }
    OO_sortByKey(key) {
        this.setState({ ProjectData: this.sortListByKey(this.state.ProjectData, key, !this.state.SortAscend), SortAscend: !this.state.SortAscend, SortBy: key });
    }
    OO_sort() {
        this.setState({ ProjectData: this.sortListByKey(this.state.ProjectData, this.state.SortBy, this.state.SortAscend) });
    }
    OO_showSortIcon(key) {
        if (key === this.state.SortBy) {
            return this.state.SortAscend ? React.createElement("i", { className: "uib-icon--arrow1-down uib-icon" }) : React.createElement("i", { className: "uib-icon--arrow1-up uib-icon" });
        }
    }
    OO_reset() {
        this.setState({ State: [], From: "2021-01-01", To: new Date().toISOString().substring(0, 10) });
        this.OO_sort();
    }
    /**
     * Creates the CSV file of the current view. The order of columns is important
     */
    OO_download() {
        let data = this.filteredProjects().map((item) => {
            return {
                "Name": item.name,
                "URI": item.id,
                "Ordernumber": item.orderNumber,
                "State": item.journeyState,
                "Estimated Hours": item.estimatedHours,
                "Acceptance Date": this.trimDate(item.dateDone)
            };
        });
        let additionalData = "Sum of all shown entries;" + this.sumOfAllShownProjects() + " h";
        this.downloadListObjectAsCSV(data, this.props.parentModule.state.projectId, additionalData);
    }
    convertIDtoLink(id) {
        return React.createElement("a", { target: "_blank", href: "https://dev.azure.com/draeger/_workitems/edit/" + id }, id);
    }
    getFirstDayOfCurrentMonth() {
        let today = new Date();
        let firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        firstDay.setHours(2, 0, 0, 0);
        return firstDay.toISOString().substring(0, 10);
    }
    downloadListObjectAsCSV(list, projName, additional = "") {
        let csv = this.convertObjectListToCSV(list);
        csv += additional;
        let blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
        const element = document.createElement("a");
        element.href = URL.createObjectURL(blob);
        element.download = projName + ".csv";
        document.body.appendChild(element);
        element.click();
    }
    convertObjectListToCSV(list) {
        let csv = "";
        let keys = Object.keys(list[0]);
        csv += keys.join(";") + "\n";
        list.forEach((item) => {
            let values = [];
            keys.forEach((key) => {
                values.push(item[key]);
            });
            csv += values.join(";") + "\n";
        });
        return csv;
    }
    getLastDayOfCurrentMonth() {
        let today = new Date();
        let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        lastDay.setHours(2, 0, 0, 0);
        return lastDay.toISOString().substring(0, 10);
    }
    getFirstDayOfLastMonth() {
        let today = new Date();
        let firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        firstDay.setHours(2, 0, 0, 0);
        return firstDay.toISOString().substring(0, 10);
    }
    getLastDayOfLastMonth() {
        let today = new Date();
        let lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
        lastDay.setHours(2, 0, 0, 0);
        return lastDay.toISOString().substring(0, 10);
    }
    sortListByKey(list, key, reverse = false) {
        if (list.length === 0) {
            return list;
        }
        // if list item is a number
        if (!isNaN(list[0][key])) {
            list.sort((a, b) => {
                if (a[key] < b[key]) {
                    return -1;
                }
                if (a[key] > b[key]) {
                    return 1;
                }
                return 0;
            });
        }
        else {
            list.sort((a, b) => {
                if (a[key].toLowerCase() < b[key].toLowerCase()) {
                    return -1;
                }
                if (a[key].toLowerCase() > b[key].toLowerCase()) {
                    return 1;
                }
                return 0;
            });
        }
        if (reverse) {
            list.reverse();
        }
        return list;
    }
    trimDate(date) {
        if (date.indexOf('1970') !== -1) {
            return "-";
        }
        return date.substring(0, 10);
    }
    /**
     * Filter the shown Items by from/to date, then by searchFor state
     */
    filteredProjects() {
        if (!this.state.ProjectData)
            return [];
        return this.state.ProjectData.filter((item) => {
            // filter by last modified or acceptance date
            if (this.state.From && this.state.To) {
                if (this.state.Search === SearchBy.LastModified) {
                    return item.dateModified.substring(0, 10) >= this.state.From && item.dateModified.substring(0, 10) <= this.state.To;
                }
                else {
                    return item.dateDone.substring(0, 10) >= this.state.From && item.dateDone.substring(0, 10) <= this.state.To;
                }
            }
            else {
                return true;
            }
            // state filter    
        }).filter((item) => {
            if (this.state.State.length > 0) {
                return !!this.state.State.find((state) => state.value === item.journeyState);
            }
            else {
                return true;
            }
        })
            // filter by area
            .filter((item) => {
            if (this.state.Areas.length > 0) {
                return !!this.state.Areas.find((area) => area.value === item.area);
            }
            else {
                return true;
            }
        });
    }
    sumOfAllShownProjects() {
        if (this.filteredProjects().length === 0) {
            return 0;
        }
        let a = this.filteredProjects().map(i => i.estimatedHours).reduce((a, b) => {
            return a + b;
        });
        // round to 2 digits
        return Math.round(+a * 100) / 100;
    }
    truncateArea(area) {
        if (!area)
            return "";
        if (area.indexOf('\\') !== -1) {
            return area.split('\\')[1];
        }
        return area;
    }
    LogPopupShow(logData) {
        let htmlContent = typeof logData === "string" ? React.createElement("div", { dangerouslySetInnerHTML: { __html: logData } }) : logData;
        HubFunctions.PopupCreate("Log", htmlContent);
    }
    getCurrentDoopAreas() {
        const areas = this.props.parentModule.state.AllAreas;
        let optionList = [];
        if (areas) {
            optionList = areas.map((area) => {
                const split = area.name.split('\\');
                const name = split[split.length - 1];
                // pick last element of array
                return { label: name, value: area.name };
            });
        }
        return optionList;
    }
    render() {
        if (this.props.parentModule.state.Meta === null) {
            return React.createElement(React.Fragment, null);
        }
        return React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.ois },
                React.createElement("div", { className: styles.mainScreenHeadline }, "Order Overview"),
                React.createElement("div", { className: `form-group row ${styles.filterRow}` },
                    React.createElement("div", { className: "col col-md-auto" },
                        React.createElement("label", { className: "col col-form-label" }, "Search for"),
                        React.createElement("div", { className: "col" },
                            React.createElement(Button, { className: this.state.Search === SearchBy.AcceptanceDate ? `uib-button uib-button--secondary ${styles.SearchForButton}` : `uib-button uib-button--primary ${styles.SearchForButton}`, onClick: () => this.setState({ Search: SearchBy.LastModified }) }, "Last Modified"),
                            React.createElement(Button, { className: this.state.Search === SearchBy.AcceptanceDate ? `uib-button uib-button--primary ${styles.BiggerHeight}` : `uib-button uib-button--secondary ${styles.BiggerHeight}`, onClick: () => this.setState({ Search: SearchBy.AcceptanceDate }) }, "Acceptance Date"))),
                    React.createElement("div", { className: "col col-md-auto" },
                        React.createElement("label", { className: "col col-form-label" }, "From"),
                        React.createElement("div", { className: "col" },
                            React.createElement(Form.Control, { id: "dateFrom", type: "date", name: "dateFrom", max: "3000-12-31", value: this.state.From, min: "2021-01-01", className: `form-control ${styles.datepicker}`, onChange: (event) => this.setState({ From: event.target.value }) }))),
                    React.createElement("div", { className: "col col-md-auto" },
                        React.createElement("label", { className: "col col-form-label" }, "Until"),
                        React.createElement("div", { className: "col" },
                            React.createElement(Form.Control, { id: "dateUntil", type: "date", name: "dateUntil", max: "3000-12-31", value: this.state.To, min: "2021-01-01", className: `form-control ${styles.datepicker}`, onChange: (event) => this.setState({ To: event.target.value }) }))),
                    React.createElement("div", { className: "col" },
                        React.createElement("label", { className: "col col-form-label" }, "State"),
                        React.createElement("div", { className: "col" },
                            React.createElement(Select, { closeMenuOnSelect: false, className: styles.zindex, components: animatedComponents, placeholder: "If empty, all are selected", isMulti: true, options: optionsOISList, onChange: (option) => { this.setState({ State: option }); }, value: this.state.State }))),
                    React.createElement("div", { className: "col" },
                        React.createElement("label", { className: "col col-form-label" }, "Areas"),
                        React.createElement("div", { className: "col" },
                            React.createElement(Select, { closeMenuOnSelect: false, className: styles.zindex, components: animatedComponents, placeholder: "If empty, all are selected", isMulti: true, 
                                // options={convertAreasToOptionsList(this.state.AllAreas)}
                                options: this.getCurrentDoopAreas(), onChange: (option) => { this.setState({ Areas: option }); }, value: this.state.Areas }))),
                    React.createElement("div", { className: `col col-md-auto ${styles.RButtons}` },
                        React.createElement("label", { className: "col col-form-label" }),
                        React.createElement("div", { className: "col " },
                            React.createElement(OverlayTrigger, { placement: "top", delay: { show: 150, hide: 200 }, overlay: React.createElement(Tooltip, { id: "tooltip-disabled" }, "Reset filters") },
                                React.createElement(Button, { className: `uib-button uib-button--primary uib-button--square ${styles.resetButton} ${styles.ButtonLogo}`, onClick: () => this.OO_reset() },
                                    React.createElement("i", { className: `uib-icon uib-icon--settings-leave ${styles.logo}` }))),
                            React.createElement(OverlayTrigger, { placement: "top", delay: { show: 150, hide: 200 }, overlay: React.createElement(Tooltip, { id: "tooltip-disabled" }, "Refresh") },
                                React.createElement(Button, { className: `uib-button uib-button--primary uib-button--square ${styles.resetButton} ${styles.ButtonLogo}`, onClick: () => this.getProjectData() },
                                    React.createElement("i", { className: `uib-icon uib-icon--reload ${styles.logo}` }))),
                            React.createElement(OverlayTrigger, { placement: "top", delay: { show: 150, hide: 200 }, overlay: React.createElement(Tooltip, { id: "tooltip-disabled" }, "Download Current View as CSV") },
                                React.createElement(Button, { className: `uib-button uib-button--primary uib-button--square ${styles.ButtonLogo}`, onClick: () => this.OO_download() },
                                    React.createElement("i", { className: `uib-icon uib-icon--download ${styles.logo}` }))))),
                    React.createElement("div", { className: `row ${styles.filterRow} ${styles.buttonRow}` },
                        React.createElement("div", { className: `col col-md-auto ${styles.alignCenter}` },
                            React.createElement(Button, { className: `uib-button uib-button--primary ${styles.BiggerHeight}`, onClick: () => { this.setState({ From: this.getFirstDayOfCurrentMonth(), To: this.getLastDayOfCurrentMonth() }); } }, "View Current Month")),
                        React.createElement("div", { className: `col col-md-auto ${styles.alignCenter}` },
                            React.createElement(Button, { className: `uib-button uib-button--primary ${styles.BiggerHeight}`, onClick: () => { this.setState({ From: this.getFirstDayOfLastMonth(), To: this.getLastDayOfLastMonth() }); } }, "View Last Month")),
                        React.createElement("div", { className: "col" }),
                        React.createElement("div", { className: `col col-md-2 ${styles.alignCenter}` },
                            "Sum of all shown entries: ",
                            this.sumOfAllShownProjects(),
                            " h"))),
                React.createElement("div", { className: styles.OOtable },
                    React.createElement("table", { className: `uib-table uib-table--striped ${styles.tabletopborder}` },
                        React.createElement("thead", { className: `uib-table__header ${styles.tablehead}` },
                            React.createElement("tr", { className: "uib-table__row uib-table__row--header" },
                                React.createElement("th", { className: styles.hoverPointer, onClick: () => this.OO_sortByKey("name") },
                                    "Name",
                                    this.OO_showSortIcon("name")),
                                React.createElement("th", { className: `${styles.centerTD} ${styles.hoverPointer}`, onClick: () => this.OO_sortByKey("id") },
                                    "ID",
                                    this.OO_showSortIcon("id")),
                                React.createElement("th", { className: `${styles.centerTD} ${styles.hoverPointer}`, onClick: () => this.OO_sortByKey("estimatedHours") },
                                    "Estimate",
                                    this.OO_showSortIcon("estimatedHours")),
                                React.createElement("th", { className: `${styles.centerTD} ${styles.hoverPointer}`, onClick: () => this.OO_sortByKey("priceModel") },
                                    "Price Model",
                                    this.OO_showSortIcon("priceModel")),
                                React.createElement("th", { className: `${styles.centerTD} ${styles.hoverPointer}`, onClick: () => this.OO_sortByKey("orderNumber") },
                                    "Order Number",
                                    this.OO_showSortIcon("orderNumber")),
                                React.createElement("th", { className: `${styles.centerTD} ${styles.hoverPointer}`, onClick: () => this.OO_sortByKey("area") },
                                    "Area",
                                    this.OO_showSortIcon("area")),
                                React.createElement("th", { className: `${styles.centerTD} ${styles.hoverPointer}`, onClick: () => this.OO_sortByKey("journeyState") },
                                    "State",
                                    this.OO_showSortIcon("journeyState")),
                                React.createElement("th", { className: `${styles.centerTD} ${styles.hoverPointer}`, onClick: () => this.OO_sortByKey("dateDone") },
                                    "Acceptance Date",
                                    this.OO_showSortIcon("dateDone")),
                                React.createElement("th", { className: `${styles.centerTD} ${styles.hoverPointer}`, onClick: () => this.OO_sortByKey("dateModified") },
                                    "Last Modified",
                                    this.OO_showSortIcon("dateModified")),
                                React.createElement("th", { className: `${styles.centerTD}` }, "Logs"))),
                        React.createElement("tbody", { className: "uib-table__body" }, this.filteredProjects().map((item, index) => {
                            return React.createElement("tr", { className: "uib-table__row", key: index },
                                React.createElement("td", null, item.name),
                                React.createElement("td", { className: `${styles.centerTD} ${styles.link}` }, this.convertIDtoLink(item.id)),
                                React.createElement("td", { className: `${styles.centerTD}` },
                                    item.estimatedHours,
                                    " h"),
                                React.createElement("td", { className: `${styles.centerTD}` }, item.priceModel),
                                React.createElement("td", { className: `${styles.centerTD}` }, item.orderNumber),
                                React.createElement("td", { className: `${styles.centerTD}` }, this.truncateArea(item.area)),
                                React.createElement("td", { className: `${styles.centerTD}` }, item.journeyState),
                                React.createElement("td", { className: `${styles.centerTD}` }, this.trimDate(item.dateDone)),
                                React.createElement("td", { className: `${styles.centerTD}` }, this.trimDate(item.dateModified)),
                                React.createElement("td", { className: `${styles.centerTD}` },
                                    React.createElement(OverlayTrigger, { placement: "top", delay: { show: 150, hide: 200 }, overlay: React.createElement(Tooltip, { id: "tooltip-disabled" }, "Show Logs") },
                                        React.createElement(Button, { onClick: () => { this.LogPopupShow(item.logs); }, className: `uib-button uib-button--primary uib-button--square ${styles.ButtonLogo}` },
                                            React.createElement("i", { className: `uib-icon uib-icon--view ${styles.logo}` })))));
                        }))))));
    }
}
