/**
 * This folder should all the api calls that are used in the module.
 * Every call must use the fetchFromApi function from the backendCalls.tsx file.
 * This function will handle the authentication and error handling.
 */
import { fetchFromApi, getBackendApiURL } from "BackendFacade";
export function listProjects() {
    const url = getBackendApiURL() + "projects";
    return fetchFromApi(url, "GET");
}
export function ProcessB2cLogin(data) {
    const url = getBackendApiURL() + "static/b2cLogin";
    return fetchFromApi(url, "POST", data);
}
export function createProject(project) {
    const obj = {
        value: project
    };
    const url = getBackendApiURL() + "projects";
    return fetchFromApi(url, "PUT", obj);
}
export function updateProject(project) {
    const url = getBackendApiURL() + "projects";
    return fetchFromApi(url, "POST", project);
}
export function GetAccessRoles(projectId) {
    const url = getBackendApiURL() + "projects/" + projectId + "/permissions";
    return fetchFromApi(url, "GET");
}
export function UpdateAccessRoles(roles, projectId) {
    const url = getBackendApiURL() + "projects/" + projectId + "/permissions";
    const obj = {
        value: roles
    };
    return fetchFromApi(url, "POST", obj);
}
/**
 * List all module instances of a project
 * @param projectId
 */
export function listModuleInstances(projectId) {
    const url = getBackendApiURL() + "projects/" + projectId + "/modules";
    return fetchFromApi(url, "GET");
}
export function getApiKeys(project) {
    const url = getBackendApiURL() + "projects/" + project + "/apiKeys";
    return fetchFromApi(url, "GET");
}
export function updateApiKeys(project, data) {
    const url = getBackendApiURL() + "projects/" + project + "/apiKeys";
    const obj = {
        value: data
    };
    return fetchFromApi(url, "POST", obj);
}
export function createApiKeys(project, data) {
    const url = getBackendApiURL() + "projects/" + project + "/apiKeys";
    const obj = {
        value: data
    };
    return fetchFromApi(url, "PUT", obj);
}
export function deleteApiKeys(project, id) {
    const url = getBackendApiURL() + "projects/" + project + "/apiKeys/" + id;
    return fetchFromApi(url, "DELETE");
}
