var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { ConfigModalMode } from "../../Interfaces/IAddEditConfigModal";
import { authLevels, defaultAuth, DisableTenantManagement, IconTrash, SignUpEventAPI, SignUpWithDefaultAuth, SignUpWithExceptionAuth, userTenant } from "../../constants";
import { Button, Col, Container, Form, FormControl, ModalBody, ModalFooter, Row } from "react-bootstrap";
import styles from "../../IDMS.module.css";
import ModalHeader from "react-bootstrap/ModalHeader";
import { sendConfigEditDataCall } from "../../BackendFacade/apiCalls";
import HubFunctions from "../../../../HubFramework/hubFunctions";
export class Config_AddEditModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.props.state;
    }
    /**
     * Add the value to the config string of authLevels
     * @param value value to be added
     */
    authLevelsAdd(value) {
        let _authLevels = this.state.Selected_Value.split(',');
        if (_authLevels.indexOf(value) === -1) {
            _authLevels.push(value);
            this.setState({ Selected_Value: _authLevels.join(','), tmp: "" });
        }
    }
    /**
     * removes an element from the authLevels
     * @param value value to be removed
     */
    authLevelsDelete(value) {
        let _authLevels = this.state.Selected_Value.split(',');
        _authLevels = _authLevels.filter((items) => items !== value);
        // remove empty authLevels
        _authLevels = _authLevels.filter((items) => items !== "");
        this.setState({ Selected_Value: _authLevels.join(',') });
    }
    editEntry_save() {
        if (this.state.Selected_Key === "") {
            this.setState({ ErrorMessage: "Please choose a category" });
            return;
        }
        let sendValue = {};
        if (this.state.Selected_Key === SignUpWithExceptionAuth
            || this.state.Selected_Key === SignUpWithDefaultAuth
            || this.state.Selected_Key === SignUpEventAPI
            || (this.props.parentModule.state.AuthLevelChanges.indexOf(this.state.Selected_Key) !== -1 && this.state.Selected_Key.indexOf("AuthLevelChange") !== -1)) {
            sendValue[this.state.Selected_Key] = JSON.stringify(this.state.Selected_Mail);
        }
        else {
            if (this.state.Selected_Value === "") {
                this.setState({ ErrorMessage: "Please enter a value" });
                return;
            }
            sendValue[this.state.Selected_Key] = this.state.Selected_Value;
            //value += this.state.Selected_Value
        }
        HubFunctions.showLoading();
        // if the key was changed, remove the old key from the db
        // if(this.state.OldSelected_Key !== this.state.Selected_Key && this.state.OldSelected_Key !== "") {
        //     //value += ";" + this.state.OldSelected_Key + "="
        //     sendValue[this.state.OldSelected_Key] = ""
        //     sendValue[this.state.Selected_Key] = this.state.Selected_Value
        // }
        // send config to backend
        let valueObj = { value: sendValue };
        sendConfigEditDataCall(this.props.parentModule.state.projectID, this.props.parentModule.state.moduleInstanceId, valueObj).then((data) => __awaiter(this, void 0, void 0, function* () {
            if (!data.success) {
                HubFunctions.PopupCreate("Error", data.message);
            }
            else {
                HubFunctions.PopupClose();
                HubFunctions.showLoading();
                yield this.props.parentModule.getConfigData();
            }
            HubFunctions.hideLoading();
        }));
        this.props.closeFunction();
    }
    GetDefaultValue(value) {
        return React.createElement(Container, null,
            React.createElement(Row, { className: styles.popupSpaceBetween },
                React.createElement(Col, { md: 2, className: "align-self-center" }, "Value"),
                React.createElement(Col, null, value)));
    }
    GetHeaderTitle() {
        if (this.state.mode === ConfigModalMode.Add) {
            return "Add Configuration";
        }
        else {
            return "Edit Configuration";
        }
    }
    GetSelector() {
        if (this.state.mode === ConfigModalMode.Add) {
            return React.createElement(Form.Select, { onChange: (event) => {
                    this.setState({ Selected_Key: event.target.value });
                }, defaultValue: this.state.Selected_Key },
                React.createElement("option", { value: "" }, "Please choose"),
                this.props.parentModule.state.AuthLevelChanges.map((item, n_key) => {
                    if (Object.keys(this.props.parentModule.state.AppConfig).indexOf(item) === -1 || this.state.Selected_Key === item)
                        return React.createElement("option", { value: item, key: n_key }, item);
                    return React.createElement(React.Fragment, null);
                }));
        }
        else {
            // disabled input field
            return React.createElement(FormControl, { id: "plainvalue", placeholder: "Value", value: this.state.Selected_Key, disabled: true });
        }
    }
    isKeyInAuthLevelChanges() {
        if (this.props.parentModule.state.AuthLevelChanges.indexOf(this.state.Selected_Key) !== -1 && this.state.Selected_Key.indexOf("AuthLevelChange") !== -1)
            return this.state.Selected_Key;
        return "false";
    }
    render() {
        let mail = this.state.Selected_Mail;
        let ConfigValue;
        switch (this.state.Selected_Key) {
            case DisableTenantManagement:
                if (this.state.Selected_Value === "") {
                    this.setState({ Selected_Value: "false" });
                }
                const val = React.createElement(Form.Check, { inline: true, label: "Activate to disable tenant management", checked: this.state.Selected_Value === "true", onChange: (item) => {
                        this.setState({ Selected_Value: item.target.checked.toString() });
                    } });
                ConfigValue = this.GetDefaultValue(val);
                break;
            case userTenant:
                const val2 = React.createElement(Form.Select, { onChange: (event) => {
                        this.setState({ Selected_Value: event.target.value });
                    }, value: this.state.Selected_Value },
                    React.createElement("option", { value: "" }, "Please choose"),
                    React.createElement("option", { value: "draegerConnect" }, "draegerConnect (Dev)"),
                    React.createElement("option", { value: "draegerb2c" }, "draegerB2C (Prod)"));
                ConfigValue = this.GetDefaultValue(val2);
                break;
            case authLevels:
                let _authLevels = this.state.Selected_Value.split(',');
                ConfigValue = React.createElement(Container, null,
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 2, className: "align-self-center" }, "Value"),
                        React.createElement(Col, null, _authLevels.map((item, index) => {
                            if (item === "") {
                                return "";
                            }
                            return React.createElement(Button, { className: styles.spaceRight, key: index, onClick: () => this.authLevelsDelete(item) },
                                item,
                                " ",
                                IconTrash);
                        }))),
                    React.createElement(Row, null,
                        React.createElement(Col, { md: 2, className: "align-self-center" }, "Set new Value"),
                        React.createElement(Col, { className: styles.authLevels },
                            React.createElement(FormControl, { className: styles.authLevelsInput, id: "plainvalue", placeholder: "Value", onChange: (event) => {
                                    this.setState({ tmp: event.target.value });
                                }, value: this.state.tmp, onKeyDown: event => {
                                    if (event.key === 'Enter') {
                                        this.authLevelsAdd(this.state.tmp);
                                    }
                                } }),
                            React.createElement(Button, { className: "uib-button uib-button--primary", onClick: () => this.authLevelsAdd(this.state.tmp) }, "Add"))));
                break;
            case defaultAuth:
                if (this.props.parentModule.state.AppConfig.authLevels !== undefined && this.props.parentModule.state.AppConfig.authLevels !== "") {
                    let _authLevels = this.props.parentModule.state.AppConfig.authLevels.split(',');
                    if (this.state.Selected_Value === "") {
                        this.setState({ Selected_Value: _authLevels[0] });
                    }
                    const val3 = React.createElement(Form.Select, { onChange: (event) => {
                            this.setState({ Selected_Value: event.target.value });
                        }, value: this.state.Selected_Value }, _authLevels.map((item, index) => {
                        return React.createElement("option", { key: index, value: item }, item);
                    }));
                    ConfigValue = this.GetDefaultValue(val3);
                }
                else {
                    ConfigValue = this.GetDefaultValue(React.createElement("p", null, "There are no authLevels defined."));
                }
                break;
            case SignUpEventAPI:
            case SignUpWithDefaultAuth:
            case SignUpWithExceptionAuth:
            case this.isKeyInAuthLevelChanges():
                ConfigValue = React.createElement(Container, null,
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 2, className: "align-self-center" }, "Mail Receivers"),
                        React.createElement(Col, null,
                            React.createElement(FormControl, { placeholder: "Email", value: this.state.Selected_Mail.mailReceivers, id: "config_mailrec", onChange: (event) => {
                                    mail.mailReceivers = event.target.value;
                                    this.setState({ Selected_Mail: mail });
                                } }))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 2, className: "align-self-center" }, "Mail Content"),
                        React.createElement(Col, null,
                            React.createElement(FormControl, { as: "textarea", rows: 6, placeholder: "Value", value: this.state.Selected_Mail.mailContent, id: "config_mailText", onChange: (event) => {
                                    mail.mailContent = event.target.value;
                                    this.setState({ Selected_Mail: mail });
                                } }))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 2, className: "align-self-center" }, "Subject"),
                        React.createElement(Col, null,
                            React.createElement(FormControl, { placeholder: "Value", value: this.state.Selected_Mail.subject, id: "config_mailSubj", onChange: (event) => {
                                    mail.subject = event.target.value;
                                    this.setState({ Selected_Mail: mail });
                                } }))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 2, className: "align-self-center" }, "Sender Mail"),
                        React.createElement(Col, null,
                            React.createElement(FormControl, { placeholder: "Value", value: this.state.Selected_Mail.senderMail, id: "config_mailSender", onChange: (event) => {
                                    mail.senderMail = event.target.value;
                                    this.setState({ Selected_Mail: mail });
                                } }))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 2, className: "align-self-center" }, "Template ID"),
                        React.createElement(Col, null,
                            React.createElement(FormControl, { placeholder: "Value", value: this.state.Selected_Mail.templateID, id: "config_mailTempId", onChange: (event) => {
                                    mail.templateID = event.target.value;
                                    this.setState({ Selected_Mail: mail });
                                } }))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 2, className: "align-self-center" }, "Template Data"),
                        React.createElement(Col, null,
                            React.createElement(FormControl, { placeholder: "Value", value: this.state.Selected_Mail.templateData, id: "config_mailTempData", onChange: (event) => {
                                    mail.templateData = event.target.value;
                                    this.setState({ Selected_Mail: mail });
                                } }))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, null,
                            React.createElement("p", null, "You can use parameters by writing $(parameterName). Available parameters are:"),
                            React.createElement("span", null,
                                "userMail: eMail of the affected user.",
                                React.createElement("br", null),
                                "AppDisplayName: Display name of the app.",
                                React.createElement("br", null),
                                "eventName: Name of the event that triggered this mail."),
                            React.createElement("p", null, "For example: Entering $(userMail) as mail receiver will send this mail to the user that triggered the event."))));
                break;
            default:
                const val4 = React.createElement(FormControl, { id: "plainvalue", placeholder: "Value", onChange: (event) => {
                        this.setState({ Selected_Value: event.target.value });
                    }, value: this.state.Selected_Value });
                ConfigValue = this.GetDefaultValue(val4);
                break;
        }
        return React.createElement("div", null,
            React.createElement(ModalHeader, { closeButton: true }, this.GetHeaderTitle()),
            React.createElement(ModalBody, null,
                React.createElement(Container, null,
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 2, className: "align-self-center" }, "Category"),
                        React.createElement(Col, null, this.GetSelector())),
                    React.createElement("br", null),
                    React.createElement(Row, { className: styles.popupSpaceBetween }, ConfigValue),
                    this.state.ErrorMessage !== "" ? React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 2 }),
                        React.createElement(Col, null, this.state.ErrorMessage)) : "")),
            React.createElement(ModalFooter, null,
                React.createElement(Button, { className: "uib-button uib-button--secondary", onClick: this.props.closeFunction }, "Cancel"),
                this.state.Selected_Key !== "" &&
                    React.createElement(Button, { className: `uib-button uib-button--primary ${styles.addUserButton}`, onClick: () => this.editEntry_save() }, "Apply")));
    }
}
