import msAzureDevOpsDrg from "./azure-devops-logo.png";
import idmsDev from "./idmsDev.png";
import idmsProd from "./idmsProd.png";
import keyChangerProd from "./keyChangerProd.png";
import Doop from "./DOOP.png";
import DoopDev from "./DOOPDev.png";
import draeger from "./draeger.png";
import appleAppstore from "./AppleAppstore.png";
import blackduck from "./blackduck.png";
import firebase from "./firebase.png";
import googlecloudservices from "./googleCloudServices.png";
import googlePlay from "./googlePlayStoreConsole.png";
import appCenter from "./appcenter.png";
import azure from "./azure.png";
import sendgrid from "./sendgrid.png";
import sentry from "./sentry.png";
import sonarqube from "./sonarqube.png";
import bookmark from "./bookmark.png";
/**
 * Place here all the icons from this folder
 */
export let moduleIcons = {
    default: draeger,
    msAppCenter: appCenter,
    appleStoreConnect: appleAppstore,
    azurePortal: azure,
    blackduck: blackduck,
    customBookmark: bookmark,
    firebase: firebase,
    googlecloudservices: googlecloudservices,
    googlePlay: googlePlay,
    msAzureDevOpsDrg: msAzureDevOpsDrg,
    idmsDev: idmsDev,
    idmsProd: idmsProd,
    keyChangerProd: keyChangerProd,
    doopProd: Doop,
    doopDev: DoopDev,
    sendgrid: sendgrid,
    sentryio: sentry,
    sonarqube: sonarqube
};
