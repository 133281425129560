/**
 * The content of the module
 * This is mandatory for every module. It contains all the information about the module.
 */
export const moduleContent = {
    title: "Google Play (Console)",
    defaultPage: "",
    moduleName: "googlePlay",
    pages: [],
    creationProps: []
};
