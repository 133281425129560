var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { fetchFromApi, getBackendApiURL } from "../../BackendFacade";
import { GlobalDictionary } from "../globalDictionary";
export const PROFILEIMAGE = "profileImage";
export function GetProfileImages(emails) {
    return __awaiter(this, void 0, void 0, function* () {
        if (emails.length === 0)
            return false;
        // regex check for email structure
        const emailRegex = /^[^@]+@[^@]+\.[^@]+$/;
        // only take valid emails
        const validEmails = emails.filter(email => emailRegex.test(email))
            .map(email => encodeURIComponent(email));
        const url = getBackendApiURL() + `static/profilePictures?mailList=${validEmails.join(",")}`;
        yield fetchFromApi(url, "GET").then((response) => {
            Object.keys(response.data.value).forEach((key) => {
                GlobalDictionary.set(PROFILEIMAGE + key, response.data.value[key]);
            });
        });
    });
}
