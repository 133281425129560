var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Popup, animatedComponents, findIndexInContractorNames, convertAreasToOptionsList } from "../Interfaces/IDOOP";
import { Contractors, DoopAreas, EditProjectShownKeys, IconTrash, } from "../constants";
import { Button, Col, Container, Form, Modal, ModalBody, ModalFooter, Row, Table, } from "react-bootstrap";
import Select from "react-select";
import { setProjectMeta } from "../BackendFacade/apiCalls";
import ModalHeader from "react-bootstrap/ModalHeader";
import styles from './../Doop.module.css';
import HubFunctions from "../../../HubFramework/hubFunctions";
import { isUserroleSufficient } from "../../../Utils/authorization";
import { UserRoles } from "../../../Utils";
import { HubPathRouting } from "../../../HubFramework/pathBuilder";
export class EditProject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Popup: Popup.None,
            Loading: false,
            Name: "",
            Value: "",
            Areas: [],
            Contractors: [],
            PopupMessage: "",
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            HubFunctions.showLoading();
            let prom1 = this.getProjectMetaData();
            yield Promise.all([prom1]);
            HubFunctions.hideLoading();
        });
    }
    getProjectMetaData() {
        return __awaiter(this, void 0, void 0, function* () {
            let prom1 = this.props.parentModule.getProjectData().then(data => {
                if (!data.success) {
                    HubFunctions.PopupCreate("Error", data.message);
                }
            });
            yield Promise.all([prom1]);
        });
    }
    EditProjectShow(name, value) {
        switch (name) {
            case DoopAreas:
                this.setState({ Popup: Popup.EditProject, Name: name, Areas: this.convertListOfStringsToOptionsList(value) });
                break;
            case Contractors:
                this.setState({ Popup: Popup.EditProject, Name: name, Contractors: value, Value: "" });
                break;
            default:
                this.setState({ Popup: Popup.EditProject, Name: name, Value: value });
        }
    }
    EditProject_AddContractor() {
        if (this.state.Value === "") {
            return;
        }
        if (findIndexInContractorNames(this.state.Contractors, this.state.Value) === -1) {
            this.setState({ Contractors: [...this.state.Contractors, { Name: this.state.Value }], Value: "" });
        }
    }
    EditProject_DeleteContractor(item) {
        this.setState({ Contractors: this.state.Contractors.filter(i => i !== item) });
    }
    /**
     * Renders the edit project popup.
     * @constructor
     */
    EditProjectContent() {
        let editField;
        switch (this.state.Name) {
            case DoopAreas:
                editField = React.createElement(Select, { closeMenuOnSelect: false, components: animatedComponents, placeholder: "If empty, all are selected", isMulti: true, options: convertAreasToOptionsList(this.props.parentModule.state.AllAreas), onChange: (option) => { this.setState({ Areas: option }); }, value: this.state.Areas });
                break;
            case Contractors:
                editField = React.createElement("div", null,
                    React.createElement(Form, null,
                        React.createElement(Form.Group, { className: "", controlId: "" },
                            React.createElement(Form.Label, null, "Contractor names:"),
                            React.createElement("div", null, this.state.Contractors.map((item, index) => {
                                return React.createElement(Button, { className: styles.spaceRight, key: index, onClick: () => this.EditProject_DeleteContractor(item) },
                                    item.Name,
                                    " ",
                                    IconTrash);
                            })),
                            React.createElement("div", { className: styles.addContractor },
                                React.createElement(Form.Control, { type: "text", placeholder: 'Contractor name', value: this.state.Value, onChange: (event) => this.setState({ Value: event.target.value }), onKeyDown: event => { if (event.key === 'Enter') {
                                        this.EditProject_AddContractor();
                                    } } }),
                                React.createElement(Button, { className: `${styles.addContractorButton} uib-button uib-button--primary`, onClick: () => this.EditProject_AddContractor() }, "Add")))));
                break;
            default:
                editField = React.createElement("input", { type: "text", className: "uib-textfield__input", value: this.state.Value.toString(), onChange: (e) => this.setState({ Value: e.target.value }) });
                break;
        }
        return React.createElement("div", null,
            React.createElement(ModalHeader, { closeButton: true }, "Edit Project"),
            React.createElement(ModalBody, null,
                React.createElement(Container, null,
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 3, className: "align-self-center" }, this.state.Name),
                        React.createElement(Col, null,
                            React.createElement(Container, null,
                                React.createElement(Row, { md: 2 },
                                    React.createElement("div", { className: "uib-form-field__control uib-textfield" },
                                        editField,
                                        React.createElement("span", { className: "uib-textfield__validation-icon" })))))))),
            React.createElement(ModalFooter, null,
                React.createElement("button", { className: `uib-button uib-button--secondary ${styles.mr10}`, onClick: () => this.setState({ Popup: Popup.None }) }, "Cancel"),
                React.createElement("button", { className: "uib-button uib-button--primary", onClick: () => { this.submitProject(); } }, "Save")));
    }
    submitProject() {
        let body = this.props.parentModule.state.Meta;
        switch (this.state.Name) {
            case DoopAreas:
                body.doopAreas = this.state.Areas.map(i => i.value);
                break;
            case Contractors:
                body.contractors = this.state.Contractors;
                break;
            default:
                body[this.state.Name] = this.state.Value;
        }
        this.setState({ Loading: true, Popup: Popup.None });
        let bodyString = { projectMetaToUpdate: body };
        setProjectMeta(this.props.parentModule.state.projectId, this.props.parentModule.state.moduleInstanceId, bodyString).then((response) => {
            if (!response.success) {
                HubFunctions.PopupCreate("Error", response.message);
            }
            else {
                this.props.parentModule.state.getProjectData();
            }
        });
    }
    convertListOfStringsToOptionsList(areas) {
        let options = [];
        if (areas === undefined) {
            return options;
        }
        areas.forEach((x) => {
            options.push({
                value: x,
                label: x
            });
        });
        return options;
    }
    evaluateDisplayedValue(value) {
        if (value === undefined)
            return React.createElement("div", null);
        // if value is a list
        if (Array.isArray(value)) {
            if (value.length > 0) {
                switch (typeof value[0]) {
                    case "string":
                        return React.createElement("div", null, value.join(", "));
                    // default is Contractor[]
                    default:
                        return React.createElement("div", null, value.map((v) => v.Name).join(", "));
                }
            }
        }
        let strValue = value.toString();
        if (strValue[0] === '[' && strValue[strValue.length - 1] === ']') {
            let val = JSON.parse(strValue);
            return React.createElement("div", null, val.join(', '));
        }
        else if (strValue === "true") {
            return React.createElement("div", { className: styles.biggerIcons },
                React.createElement("i", { className: "uib-icon uib-icon--ok" }));
        }
        else if (strValue === "false") {
            return React.createElement("div", { className: styles.biggerIcons },
                React.createElement("i", { className: "uib-icon uib-icon--close" }));
        }
        else {
            return React.createElement("div", null, strValue);
        }
    }
    render() {
        var _a, _b;
        let popupContent = React.createElement("div", null);
        switch (this.state.Popup) {
            case Popup.EditProject:
                popupContent = this.EditProjectContent();
                break;
            default:
                break;
        }
        if (this.props.parentModule.state.Meta === null) {
            return React.createElement("div", null);
        }
        return React.createElement(React.Fragment, null,
            React.createElement(Modal, { size: "lg", show: this.state.Popup !== Popup.None, onHide: () => this.setState({ Popup: Popup.None }) }, popupContent),
            React.createElement("div", { className: styles.mainScreenHeadline }, "Edit Project"),
            React.createElement(Table, { striped: true, className: styles.maintable },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "Key"),
                        React.createElement("th", null, "Value"),
                        isUserroleSufficient(UserRoles.editor, (_a = HubPathRouting.currentProject) === null || _a === void 0 ? void 0 : _a.moduleInstanceId, (_b = HubPathRouting.currentModule) === null || _b === void 0 ? void 0 : _b.moduleInstanceId) && React.createElement(React.Fragment, null,
                            React.createElement("th", { className: styles.alignCenter }, "Edit")))),
                React.createElement("tbody", null, EditProjectShownKeys.map((col, index) => {
                    var _a, _b;
                    return React.createElement("tr", { key: index },
                        React.createElement("td", { className: styles.ePKey }, col),
                        React.createElement("td", { className: styles.ePKey }, this.evaluateDisplayedValue(this.props.parentModule.state.Meta[col])),
                        isUserroleSufficient(UserRoles.editor, (_a = HubPathRouting.currentProject) === null || _a === void 0 ? void 0 : _a.moduleInstanceId, (_b = HubPathRouting.currentModule) === null || _b === void 0 ? void 0 : _b.moduleInstanceId) && React.createElement(React.Fragment, null,
                            React.createElement("td", { className: `${styles.alignCenter} ${styles.ePEdit}` },
                                React.createElement("button", { className: `${styles.biggerIcons} ${styles.noShadow}`, onClick: () => { this.EditProjectShow(col, this.props.parentModule.state.Meta[col]); } },
                                    React.createElement("i", { className: "uib-icon uib-icon--edit " })))));
                }))));
    }
}
