import * as React from "react";
import { Button, Form, Table } from "react-bootstrap";
import { createProject } from "../BackendFacade/apiCalls";
import styles from "./../Hub.module.css";
import HubFunctions from "../../../HubFramework/hubFunctions";
export class NewProject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contacts: [],
            costCenter: 0,
            department: "",
            description: "",
            displayName: "",
            product: "",
            projectId: "",
            newMail: "",
            newJob: "",
            projectIdError: "",
            projectContactError: "",
            projectCostCenterError: "",
            internalOrderNumber: 0,
        };
    }
    componentDidMount() {
        this.resetFields();
    }
    resetFields() {
        this.setState({
            contacts: [],
            costCenter: 0,
            department: "",
            description: "",
            displayName: "",
            product: "",
            projectId: "",
            newMail: "",
            newJob: ""
        });
    }
    removeContact(mail) {
        const contacts = this.state.contacts.filter((contact) => contact.mail !== mail);
        this.setState({ contacts: contacts });
        this.checkContacts();
    }
    addContact() {
        if (this.state.newMail === "" || this.state.newJob === "") {
            return;
        }
        const contacts = this.state.contacts;
        contacts.push({ mail: this.state.newMail, job: this.state.newJob });
        this.setState({ contacts: contacts, newMail: "", newJob: "" });
        this.checkContacts();
    }
    submit() {
        if ( //this.state.projectId === "" ||
        this.state.displayName === "" ||
            this.state.description === "" ||
            this.state.product === "" ||
            this.state.department === "" ||
            this.state.costCenter === 0 ||
            this.state.contacts.length === 0) {
            HubFunctions.PopupCreate("Error", "Please fill in all fields");
            this.checkContacts();
            return;
        }
        if (this.state.projectIdError !== "") {
            HubFunctions.PopupCreate("Error", "Please fix the project id error");
            return;
        }
        if (this.state.costCenter <= 1000) {
            HubFunctions.PopupCreate("Error", "Cost center must be greater than 1000");
            return;
        }
        HubFunctions.showLoading();
        const send = {
            contacts: this.state.contacts,
            costCenter: this.state.costCenter,
            department: this.state.department,
            description: this.state.description,
            displayName: this.state.displayName,
            product: this.state.product,
            projectId: this.state.projectId,
            customProjectIcon: null,
            internalOrderNumber: this.state.internalOrderNumber
        };
        createProject(send).then((data) => {
            if (data.success) {
                this.resetFields();
                // popup success
                HubFunctions.PopupCreate("Success", "Project created successfully");
            }
            else {
                HubFunctions.PopupCreate("Error", "Project could not be created.\n " + data.message);
            }
        }).finally(() => {
            HubFunctions.hideLoading();
        });
    }
    checkProjectId() {
        // check if projectid only contains letters and numbers
        if (!this.state.projectId.match(/^[a-zA-Z0-9]+$/)) {
            this.setState({ projectIdError: "Project ID can only contain letters and numbers" });
        }
        else {
            this.setState({ projectIdError: "" });
        }
    }
    checkContacts() {
        // check if projectid only contains letters and numbers
        if (this.state.contacts.length === 0) {
            this.setState({ projectContactError: "Project needs at least one contact" });
        }
        else {
            this.setState({ projectContactError: "" });
        }
    }
    checkCostCenter() {
        if (this.state.costCenter === 0 || this.state.costCenter <= 1000) {
            this.setState({ projectCostCenterError: "Project needs a valid cost center. Number must be greater than 1000" });
        }
        else {
            this.setState({ projectCostCenterError: "" });
        }
    }
    checkInternalOrderNumber() {
        if (this.state.internalOrderNumber === 0 || this.state.internalOrderNumber <= 0) {
            this.setState({ projectCostCenterError: "Project needs a valid internal order number. Number must be greater than 0" });
        }
        else {
            this.setState({ projectCostCenterError: "" });
        }
    }
    render() {
        return React.createElement(React.Fragment, null,
            React.createElement("p", null, " Create a new project here."),
            React.createElement(Form, null,
                React.createElement(Form.Group, { className: `${styles.addNewProject}`, controlId: "displayName" },
                    React.createElement(Form.Label, null, "Display name"),
                    React.createElement(Form.Control, { type: "text", placeholder: "Enter display name", id: "displayName", value: this.state.displayName, onChange: (e) => this.setState({ displayName: e.target.value }) })),
                React.createElement(Form.Group, { className: `${styles.addNewProject}`, controlId: "description" },
                    React.createElement(Form.Label, null, "Description"),
                    React.createElement(Form.Control, { type: "text", placeholder: "Enter description", id: "description", value: this.state.description, onChange: (e) => this.setState({ description: e.target.value }) })),
                React.createElement(Form.Group, { className: `${styles.addNewProject}`, controlId: "allContacts" },
                    React.createElement(Form.Label, null, "Contacts"),
                    this.state.contacts.length >= 1 ?
                        React.createElement(Table, { hover: true, striped: true },
                            React.createElement("tbody", null, this.state.contacts.map((contact, index) => {
                                return React.createElement("tr", { key: index },
                                    React.createElement("td", { id: "enteredMail" + { index } }, contact.mail),
                                    React.createElement("td", { id: "enteredJob" + index }, contact.job),
                                    React.createElement("td", { className: `${styles.contactRemove}` },
                                        React.createElement(Button, { className: `uib-button uib-button--secondary `, id: `removeContact${index}`, onClick: () => this.removeContact(contact.mail) }, "Remove")));
                            })))
                        : React.createElement(React.Fragment, null),
                    React.createElement("div", { className: `${styles.addContact}` },
                        React.createElement(Form.Control, { type: "text", placeholder: "Enter mail", id: "newMail", value: this.state.newMail, onChange: (e) => this.setState({ newMail: e.target.value }) }),
                        React.createElement(Form.Control, { type: "text", placeholder: "Enter job", id: "newJob", value: this.state.newJob, onChange: (e) => this.setState({ newJob: e.target.value }) }),
                        React.createElement(Button, { className: `uib-button uib-button--primary ${styles.spaceLeft}`, id: "addContact", onClick: () => { this.addContact(); } }, "Add contact")),
                    React.createElement(Form.Text, { className: `text-muted ${styles.ErrorText}` }, this.state.projectContactError)),
                React.createElement(Form.Group, { className: `${styles.addNewProject}`, controlId: "costCenter" },
                    React.createElement(Form.Label, null, "Cost center"),
                    React.createElement(Form.Control, { type: "number", placeholder: "Enter cost center", id: "costCenter", value: this.state.costCenter.toString(), onChange: (e) => this.setState({ costCenter: parseInt(e.target.value) }, () => this.checkCostCenter()) })),
                React.createElement(Form.Group, { className: `${styles.addNewProject}`, controlId: "internalOrderNumber" },
                    React.createElement(Form.Label, null, "Internal Order Number"),
                    React.createElement(Form.Control, { type: "number", placeholder: "Enter internal Order Number (>= 1)", id: "internalOrderNumber", value: this.state.internalOrderNumber.toString(), onChange: (e) => this.setState({ internalOrderNumber: parseInt(e.target.value) }, () => this.checkInternalOrderNumber()) })),
                React.createElement(Form.Group, { className: `${styles.addNewProject}`, controlId: "department" },
                    React.createElement(Form.Label, null, "Department"),
                    React.createElement(Form.Control, { type: "text", placeholder: "Enter department", id: "department", value: this.state.department, onChange: (e) => this.setState({ department: e.target.value }) })),
                React.createElement(Form.Group, { className: `${styles.addNewProject}`, controlId: "product" },
                    React.createElement(Form.Label, null, "Product"),
                    React.createElement(Form.Control, { type: "text", placeholder: "Enter product", id: "product", value: this.state.product, onChange: (e) => this.setState({ product: e.target.value }) }))),
            React.createElement("div", { className: styles.Buttons },
                React.createElement(Button, { className: `uib-button uib-button--secondary `, id: "reset", onClick: () => { this.resetFields(); } }, "Reset"),
                React.createElement(Button, { className: `uib-button uib-button--primary ${styles.spaceLeft}`, id: "submit", onClick: () => { this.submit(); } }, "Submit")));
    }
}
