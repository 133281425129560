var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Alert, Button, Card, Col, Container, Form, FormControl, FormGroup, Modal, Row } from "react-bootstrap";
import { Popup, PopupMessage } from "../Interfaces/INewModuleInstances";
import { createModuleInstance } from "../BackendFacade/apiCalls";
import { getListOfAllModules } from "../../../Utils/modules";
import { deepCopy } from "../../../Utils/transformer";
import { IconTrash } from "../../../Assets/svgs";
import { isUserroleSufficient } from "../../../Utils/authorization";
import { UserRoles } from "../../../Utils";
import styles from "../ProjectContent.module.css";
import { moduleIcons } from "../Icons/Icons";
import { moduleContent as IDMSModule } from "../../IDMS";
import { moduleContent as IDMSDevModule } from "../../IDMSDev";
import { GlobalDictionary } from "../../../Utils/globalDictionary";
import { HubPathRouting } from "../../../HubFramework/pathBuilder";
import { Documentation } from "../../Documentation/Documentation";
import docuStyles from "../../Documentation/Documentation.module.css";
import HubFunctions from "../../../HubFramework/hubFunctions";
export class NewModuleInstances extends React.Component {
    constructor(props) {
        super(props);
        this.groups = [];
        this.state = {
            availableModules: [],
            newModule: undefined,
            popup: Popup.None,
            newModuleInfos: undefined,
            popupMessage: "",
            popupMessageType: PopupMessage.Info,
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.LoadModules();
            this.groups = this.getExistingModuleGroups();
        });
    }
    LoadModules() {
        return __awaiter(this, void 0, void 0, function* () {
            HubFunctions.showLoading();
            this.setState({ availableModules: yield GlobalDictionary.getStaticModules() });
            HubFunctions.hideLoading();
        });
    }
    /**
     * Gather all groups from existing module instances
     */
    getExistingModuleGroups() {
        let groups = [];
        if (GlobalDictionary.get(HubPathRouting.currentProject.moduleInstanceId + GlobalDictionary.MODULEINSTANCES)) {
            GlobalDictionary.get(HubPathRouting.currentProject.moduleInstanceId + GlobalDictionary.MODULEINSTANCES).forEach((module) => {
                if (!groups.includes(module.group)) {
                    groups.push(module.group);
                }
            });
        }
        return groups;
    }
    activateModule(module) {
        const header = React.createElement("span", null,
            moduleIcons[module.staticModuleId] ?
                React.createElement(Card.Img, { src: moduleIcons[module.staticModuleId], className: `${docuStyles.ModuleIcon}` }) :
                React.createElement(Card.Img, { src: moduleIcons["default"], className: `${styles.ModuleIcon}` }),
            " ",
            module.displayName,
            " ");
        HubFunctions.PopupCreateSingle(header, React.createElement(Documentation, { component: module.staticModuleId }));
    }
    /**
     * Renders the new module cards
     */
    newModuleCards() {
        let cards = [];
        this.state.availableModules
            // sort the groups alphabetically but "other" always last
            .sort((a, b) => {
            return a.displayName.localeCompare(b.displayName);
        })
            .forEach((module, key) => {
            cards.push(React.createElement(Col, { className: "col-3", key: key },
                React.createElement(Card, { className: `${styles.ModuleCard}`, onClick: () => this.AddModuleShow(module) },
                    React.createElement(Card.Body, null,
                        React.createElement(Button, { variant: "outline-secondary", className: `${styles.OptionsButton}`, onClick: (event) => {
                                event.stopPropagation();
                                this.activateModule(module);
                            } }, "?"),
                        React.createElement(Card.Title, null,
                            moduleIcons[module.staticModuleId] ?
                                React.createElement(Card.Img, { src: moduleIcons[module.staticModuleId], className: `${styles.ModuleIcon}` })
                                :
                                    React.createElement(Card.Img, { src: moduleIcons["default"], className: `${styles.ModuleIcon}` }),
                            module.displayName),
                        React.createElement(Card.Text, null, module.description)))));
        });
        return React.createElement(React.Fragment, null,
            React.createElement("div", { id: "orderModules", className: `${styles.GroupHeader}` }, "Order New Module Instance"),
            React.createElement(Row, { lg: 3 }, cards));
    }
    /**
     * Prepares the variables for the popup and opens it
     * @param module
     * @constructor
     */
    AddModuleShow(module) {
        let allModules = getListOfAllModules();
        let moduleContent = allModules[module.staticModuleId];
        if (moduleContent === undefined) {
            HubFunctions.PopupCreate("Error", "Module not available");
            return;
        }
        let newModule = {
            staticModuleId: module.staticModuleId,
            moduleInstanceId: "",
            displayName: "",
            group: "Other",
            linkedId: deepCopy(module.requiredLinkedIds),
            creationProps: moduleContent.moduleContent.creationProps ? deepCopy(moduleContent.moduleContent.creationProps) : undefined,
            migrateExisting: false
        };
        newModule.linkedId.projectId = "";
        let newModuleInfo = {
            staticModuleDisplayName: module.displayName,
            projectIdInfoText: module.requiredLinkedIds.projectId
        };
        this.setState({ popup: Popup.AddModule, newModule: newModule, newModuleInfos: newModuleInfo, popupMessage: "" });
    }
    /**
     * Creates the necessary fields for the popup.
     * @param creationProps
     * @constructor
     */
    CreateModuleCreationProps(creationProps) {
        // if there are no creation props or the user wants to migrate an existing module, skip
        if (creationProps === undefined || this.state.newModule.migrateExisting) {
            return React.createElement(React.Fragment, null);
        }
        let content = [];
        creationProps.forEach((prop) => {
            let formControl;
            // we want to create a form control for each property based on the type (on the left side of the screen)
            switch (prop.type) {
                case "string":
                    formControl = React.createElement(FormControl, { type: "text", id: prop.name, value: prop.value, onChange: (e) => {
                            prop.value = e.target.value;
                            this.setState({ newModule: this.state.newModule });
                        } });
                    break;
                case "number":
                    formControl = React.createElement(FormControl, { type: "number", id: prop.name, value: prop.value, onChange: (e) => {
                            prop.value = e.target.value;
                            this.setState({ newModule: this.state.newModule });
                        } });
                    break;
                case "boolean":
                    formControl = React.createElement(Form.Check, { type: "checkbox", id: prop.name, checked: prop.value, onChange: (e) => {
                            prop.value = e.target.checked;
                            this.setState({ newModule: this.state.newModule });
                        } });
                    break;
                case "select":
                    formControl = React.createElement(Form.Control, { as: "select", id: prop.name, value: prop.value, onChange: (e) => {
                            prop.value = e.target.value;
                            this.setState({ newModule: this.state.newModule });
                        } }, prop.options.map((option, key) => {
                        return React.createElement("option", { key: key }, option);
                    }));
                    break;
                case "string[]":
                    // this is not needed, this is wrong and needs to be fixed
                    formControl = React.createElement(FormGroup, null,
                        React.createElement("div", { className: "addListStrings" },
                            React.createElement(Form.Control, { type: "text", id: "newListElement" }),
                            React.createElement("div", null, prop.value.map((item, index) => {
                                return React.createElement(Button, { id: item + "_delete", className: `${styles.spaceRight} ${styles.spaceTop} uib-button uib-button--primary`, key: index, onClick: () => {
                                        //let newModule = this.state.newModule
                                        prop.value = prop.value.filter((value) => value !== item);
                                        this.setState({ newModule: this.state.newModule });
                                    } },
                                    item,
                                    " ",
                                    IconTrash);
                            })),
                            React.createElement(Button, { className: `${styles.spaceTop} uib-button uib-button--primary`, onClick: () => {
                                    let newValue = document.getElementById("newListElement").value;
                                    if (newValue === "" || prop.value.includes(newValue)) {
                                        return;
                                    }
                                    //let newModule = this.state.newModule
                                    prop.value = prop.value.concat(newValue);
                                    this.setState({ newModule: this.state.newModule });
                                } }, "Add")));
                    break;
                case "subProps":
                    Object.keys(prop.subProps).forEach((key) => {
                        content.push(this.CreateModuleCreationProps(prop.subProps[key]));
                    });
                    break;
                default:
                    formControl = React.createElement(React.Fragment, null);
                    break;
            }
            if (prop.type !== "subProps") {
                let c = React.createElement(Row, { className: `${styles.popupSpaceBetween}` },
                    React.createElement(Col, { md: 3, className: "align-self-center" }, prop.displayName ? prop.displayName : prop.name),
                    React.createElement(Col, null,
                        formControl,
                        React.createElement("small", { className: "text-muted" }, prop.description)));
                content.push(c);
            }
        });
        return React.createElement(React.Fragment, null, content);
    }
    /**
     * Content of the popup for adding a module.
     * @constructor
     */
    AddModuleContent() {
        return React.createElement(React.Fragment, null,
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null,
                    "Add Module ",
                    this.state.newModuleInfos.staticModuleDisplayName)),
            React.createElement(Modal.Body, null,
                React.createElement(Container, null,
                    React.createElement(Row, { className: `${styles.popupSpaceBetween}` },
                        React.createElement(Col, { md: 3, className: "align-self-center" }, "Display name"),
                        React.createElement(Col, null,
                            React.createElement(FormControl, { type: "text", id: "displayName", placeholder: "Display name for this module instance", value: this.state.newModule.displayName, onChange: (e) => this.setState({ newModule: Object.assign(Object.assign({}, this.state.newModule), { displayName: e.target.value }) }) }))),
                    React.createElement(Row, { className: `${styles.popupSpaceBetween}` },
                        React.createElement(Col, { md: 3, className: "align-self-center" }, "Group"),
                        React.createElement(Col, null,
                            React.createElement(FormControl, { type: "text", placeholder: "Corresponding group for this module instance", value: this.state.newModule.group, onChange: (e) => {
                                    this.setState({ newModule: Object.assign(Object.assign({}, this.state.newModule), { group: e.target.value }) });
                                }, list: "group", id: "group-input" }),
                            React.createElement("datalist", { id: "group" }, this.groups.map((scope, index) => {
                                return React.createElement("option", { key: index, value: scope });
                            })))),
                    React.createElement(Row, { className: `${styles.popupSpaceBetween}` },
                        React.createElement(Col, { md: 3, className: "align-self-center" }, "Project Id"),
                        React.createElement(Col, null,
                            React.createElement(FormControl, { type: "text", id: "projectId", placeholder: this.state.newModuleInfos.projectIdInfoText, value: this.state.newModule.linkedId.projectId, onChange: (e) => this.setState({ newModule: Object.assign(Object.assign({}, this.state.newModule), { linkedId: Object.assign(Object.assign({}, this.state.newModule.linkedId), { projectId: e.target.value }) }) }) }))),
                    isUserroleSufficient(UserRoles.editor) &&
                        React.createElement(Row, { className: `${styles.popupSpaceBetween}` },
                            React.createElement(Col, { md: 3, className: "align-self-center" }, "Migrate Existing Module"),
                            React.createElement(Col, null,
                                React.createElement(Form.Check, { type: "checkbox", id: "migrateExisting", checked: this.state.newModule.migrateExisting, onChange: (e) => this.setState({ newModule: Object.assign(Object.assign({}, this.state.newModule), { migrateExisting: e.target.checked }) }) }))),
                    this.CreateModuleCreationProps(this.state.newModule.creationProps),
                    React.createElement(Row, null,
                        React.createElement(Col, null,
                            React.createElement(Alert, { variant: this.state.popupMessageType, hidden: this.state.popupMessage === "" }, this.state.popupMessage))))),
            React.createElement(Modal.Footer, null,
                React.createElement(Button, { className: `uib-button uib-button--secondary ${styles.spaceRight}`, onClick: () => this.setState({ popup: Popup.None }) }, "Cancel"),
                React.createElement(Button, { className: "uib-button uib-button--primary", onClick: () => this.submitNewModuleInstance() }, "Submit")));
    }
    /**
     * Check all creation props for content
     * @constructor
     */
    AreAllValuesFilled() {
        let creationProps = this.state.newModule.creationProps;
        if (creationProps === undefined) {
            return true;
        }
        for (let i = 0; i < creationProps.length; i++) {
            if (creationProps[i].type === "subProps") {
                let subProps = creationProps[i].subProps["value"];
                for (let j = 0; j < subProps.length; j++) {
                    if (subProps[j].value === undefined || subProps[j].value === "") {
                        return false;
                    }
                }
                continue;
            }
            if (creationProps[i].value === undefined || creationProps[i].value === "") {
                return false;
            }
        }
        return true;
    }
    submitNewModuleInstance() {
        let a = this.AreAllValuesFilled();
        if (this.state.newModule.displayName === "" || this.state.newModule.group === "" || this.state.newModule.linkedId.projectId === "" || (!this.AreAllValuesFilled() && !this.state.newModule.migrateExisting)) {
            this.setState({ popupMessage: "Please fill all fields", popupMessageType: PopupMessage.Alert });
            return;
        }
        let newModule = this.state.newModule;
        // if the module is the IDMS module, the project ID must be refactored
        // ths project ID must start with a leading "a" if the first letter is a number
        // all "-" must be removed
        if (newModule.staticModuleId === IDMSModule.moduleName ||
            newModule.staticModuleId === IDMSDevModule.moduleName) {
            newModule.linkedId.projectId = newModule.linkedId.projectId.replace(/-/g, "");
            if (!isNaN(parseInt(newModule.linkedId.projectId.charAt(0)))) {
                newModule.linkedId.projectId = "a" + newModule.linkedId.projectId;
            }
        }
        this.setState({ popupMessage: "Creating new module instance...", popupMessageType: PopupMessage.Info });
        createModuleInstance(this.props.parentModule.state.id, newModule).then((response) => __awaiter(this, void 0, void 0, function* () {
            if (response.success) {
                yield this.LoadModules();
                this.setState({ popup: Popup.None });
                // Reset the all module instances cache
                GlobalDictionary.remove(GlobalDictionary.MODULEINSTANCES);
            }
            else {
                this.setState({ popupMessage: response.message, popupMessageType: PopupMessage.Alert });
            }
        }));
    }
    render() {
        let popupContent = React.createElement("div", null);
        switch (this.state.popup) {
            case Popup.AddModule:
                popupContent = this.AddModuleContent();
                break;
            default:
                break;
        }
        return React.createElement(React.Fragment, null,
            React.createElement(Modal, { size: "lg", show: this.state.popup !== Popup.None, onHide: () => this.setState({ popup: Popup.None }) }, popupContent),
            this.newModuleCards());
    }
}
