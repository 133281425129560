import { BlobURLBasic } from "./environmentVars";
export const listDirectoryContent = "listDirectoryContent";
export const Upload = "upload";
export const AutomationConfig = "automationConfig";
export const fileDetails = "fileDetails";
// Config file return messages
export const invalidCSV = "invalidCSV";
// Files fixed folders
export const FolderImage = "Images";
export const FolderDocument = "Documents";
export const FolderSecret = "Secret Files";
// user roles
export const user = "user";
export const admin = "admin";
export const BlobOriginalURL = BlobURLBasic + "original/";
export const Blob1080URL = BlobURLBasic + "1080px/";
export const BlobDocuments = BlobURLBasic + "documents/";
