import * as React from "react";
import { moduleContent } from "../../ProjectContent";
import { listProjects } from "../BackendFacade/apiCalls";
import { GlobalDictionary } from "../../../Utils/globalDictionary";
import { HubPathRouting } from "../../../HubFramework/pathBuilder";
import styles from './../Hub.module.css';
import { ProfilImage } from "../../../Utils/ProfilImage/ProfileImage";
import HubFunctions from "../../../HubFramework/hubFunctions";
import { GetProfileImages } from "../../../Utils/ProfilImage/ProfileImageApi";
export class AllProjects extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listOfProjects: [],
            showDescription: false,
            showIndex: -1,
            deleteProjectId: "",
            loading: true,
        };
    }
    componentDidMount() {
        if (!GlobalDictionary.get(GlobalDictionary.PROJECTS)) {
            this.getListOfAllProjectsWait();
        }
        else {
            this.setListOfProjects();
            this.getListOfAllProjects();
        }
    }
    /**
     * Gets the list of all projects from the backend with Loading screen
     */
    getListOfAllProjectsWait() {
        HubFunctions.showLoading();
        this.setState({ loading: true });
        this.getListOfAllProjects();
    }
    /**
     * Gets the list of all projects from the backend
     */
    getListOfAllProjects() {
        listProjects().then((data) => {
            if (data.success) {
                GlobalDictionary.set(GlobalDictionary.PROJECTS, data.data.value);
                GetProfileImages(this.extractListOfContacts(data.data.value));
                this.setListOfProjects();
            }
            else {
                HubFunctions.PopupCreate("Error", data.message);
            }
            HubFunctions.hideLoading();
        });
    }
    setListOfProjects() {
        const listOfProjects = GlobalDictionary.get(GlobalDictionary.PROJECTS);
        this.setState({ listOfProjects: listOfProjects, loading: false });
    }
    extractListOfContacts(projects) {
        const listOfContacts = projects.map((item) => {
            return item.contacts;
        });
        // create a list of mail of all contacts
        let setOfContacts = [];
        listOfContacts.forEach((item) => {
            item.forEach((contact) => {
                setOfContacts.push(contact.mail);
            });
        });
        return [...new Set(setOfContacts)];
    }
    openProject(appId, projectName) {
        const projectContent = moduleContent.moduleName;
        GlobalDictionary.set("project", { id: appId, name: projectName });
        HubPathRouting.currentProject = { displayName: projectName, moduleName: projectContent, moduleInstanceId: appId };
        HubFunctions.switchModule(projectContent, appId, projectName);
    }
    createLinkUrl(projectId) {
        return window.location.origin + "/#Hub/ProjectContent/" + projectId;
    }
    handleMouseEnter(index) {
        this.setState({ showDescription: true, showIndex: index });
    }
    handleMouseLeave() {
        this.setState({ showDescription: false, showIndex: -1 });
    }
    getProfileImage(contacts) {
        return contacts.map((contact, c_index) => {
            return (React.createElement(ProfilImage, { contact: contact, key: c_index }));
        });
    }
    getProjectIcon(customProjectIcon) {
        if (customProjectIcon) {
            return React.createElement("img", { src: customProjectIcon, alt: "Project Icon", className: `${styles.projectIcon}` });
        }
        else {
            return React.createElement("i", { className: "uib-icon uib-icon--collection" });
        }
    }
    render() {
        return React.createElement(React.Fragment, null,
            React.createElement("div", { id: "allProjectsList", className: `${styles.spaceRight} ${styles.Projectlist}` },
                React.createElement("ul", { className: `uib-list ${styles.orderOverview}` }, this.state.loading ? React.createElement(React.Fragment, null) :
                    this.state.listOfProjects.length > 0 ?
                        this.state.listOfProjects.sort((a, b) => {
                            if (a.displayName.toLowerCase() > b.displayName.toLowerCase()) {
                                return 1;
                            }
                            else if (a.displayName.toLowerCase() < b.displayName.toLowerCase()) {
                                return -1;
                            }
                            else {
                                return 0;
                            }
                        }).map((item, index) => {
                            return React.createElement("li", { className: "uib-list__item", key: index },
                                React.createElement("a", { className: `uib-list__link ${styles.overviewLink} row`, onClick: () => this.openProject(item.projectId, item.displayName), onMouseEnter: () => this.handleMouseEnter(index), onMouseLeave: () => this.handleMouseLeave(), href: this.createLinkUrl(item.projectId) },
                                    React.createElement("div", { className: `${styles.biggerIcons}` }, this.getProjectIcon(item.customProjectIcon)),
                                    React.createElement("div", { className: "col-10" },
                                        item.displayName,
                                        React.createElement("div", { className: `${styles.description}` },
                                            item.description,
                                            this.getProfileImage(item.contacts))),
                                    React.createElement("div", { className: `col-auto ${styles.icons}` })));
                        }) : React.createElement("p", null, "Keine Projekte vorhanden. Falls ein Zugriff vorhanden sein sollte, bitte neu einloggen."))));
    }
}
