/**
 * Converts the given list to a CSV and downloads it.
 * @param list list to download
 * @param filename filename
 * @param additional additional data to add to the csv (optional)
 */
export function downloadListObjectAsCSV(list, filename, additional = "") {
    let csv = convertObjectlistToCSV(list);
    csv += additional;
    let blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    const element = document.createElement("a");
    element.href = URL.createObjectURL(blob);
    element.download = filename + ".csv";
    document.body.appendChild(element);
    element.click();
}
/**
 * Converts the given list to a CSV
 * @param list
 * @returns
 */
function convertObjectlistToCSV(list) {
    let csv = "";
    let keys = Object.keys(list[0]);
    csv += keys.join(";") + "\n";
    list.forEach((item) => {
        let values = [];
        keys.forEach((key) => {
            values.push(item[key]);
        });
        csv += values.join(";") + "\n";
    });
    return csv;
}
// export function createDownloadObject(fullPath: string, filename: string) {
//     const link = document.createElement('a');
//     link.target = "_blank";
//     link.href = fullPath;
//     link.download = filename
//     link.rel = "noopener noreferrer"
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
// }
export function createDownloadObject(base64Data, filename) {
    // convert bytes from base64 to bytes
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(url);
}
