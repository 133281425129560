import { clientID, replyURL, tenantName } from "./environmentVars";
/**
 * Hub Login URL
 */
export const loginURL = `https://${tenantName}login.draeger.com/oauth2/default/v1/authorize?client_id=${clientID}&nonce=defaultNonce&redirect_uri=${replyURL}&scope=openid%20offline_access&response_type=code&state=STATE-34565654546678797567432113`;
export const LogoutURL = `https://${tenantName}login.draeger.com/oauth2/default/v1/logout?post_logout_redirect_uri=${replyURL}&id_token_hint=`;
/**
 * User rights for authorization
 */
export const UserRights = {
    blocked: 0,
    user: 1,
    reader: 2,
    editor: 3,
    admin: 4,
};
