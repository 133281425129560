var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { Button, Col, Container, Form, FormControl, ModalBody, ModalFooter, Row } from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import styles from './../../Hub.module.css';
import { deepCopy, formatDateToBackend } from "../../../../Utils/transformer";
import { HubPathRouting } from "../../../../HubFramework/pathBuilder";
import { IApiKeysEditMode } from "../../Interfaces/IApiKeysAddEditModal";
import { SetGlobalTenantSystemContent, UserTenantManagement } from "../../../IDMS/Utils/tenantSystem";
import { createApiKeys, updateApiKeys } from "../../BackendFacade/apiCalls";
import { IApiKeyContentTypes } from "../../Interfaces/IApiKeyManagement";
import { AuthLevels, defaultAuth } from "../../constants";
import HubFunctions from "../../../../HubFramework/hubFunctions";
export class ApiKeys_AddEditModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = deepCopy(this.props.state);
    }
    componentDidMount() {
        // on project level, if the user has no scopes in this project, add an empty one
        if (this.state.Selected_Value.contentType === IApiKeyContentTypes.hubIdmsTenantJson &&
            this.state.tenantSystem.length <= 1 &&
            HubPathRouting.currentProject &&
            this.state.tenantSystem[0].fullscope.indexOf(HubPathRouting.currentProject.moduleInstanceId) === -1) {
            this.addGlobalScope();
        }
    }
    /**
     * Add a full scope to the tenant system to the webpage backend
     */
    addGlobalScope() {
        let scopes = this.state.tenantSystem;
        let newLine = { id: scopes.length, scopes: ["user"], value: defaultAuth, tmp: "", fullscope: "" };
        if (this.props.projectId !== "null") {
            newLine.scopes.push(this.props.projectId);
        }
        scopes.push(newLine);
        this.setState({ tenantSystem: scopes });
    }
    /**
     * Saves the current user config
     */
    saveUser() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.state.Selected_Value.contentType === IApiKeyContentTypes.hubIdmsTenantJson) {
                let tenantData = {};
                // evaluate tenant data
                for (let line of this.state.tenantSystem) {
                    // skip empty value and empty scopes
                    if (line.value === "-" || line.scopes.length === 0 || line.value === "") {
                        continue;
                    }
                    tenantData[line.scopes.join('/')] = line.value;
                }
                // stringify the tenant data
                this.state.Selected_Value.content = JSON.stringify(tenantData);
            }
            if (this.state.expirationDate) {
                this.state.Selected_Value.validUntil = formatDateToBackend(this.state.Selected_Value.validUntil);
            }
            if (this.state.editMode === IApiKeysEditMode.Add) {
                createApiKeys(this.props.projectId, this.state.Selected_Value).then((data) => {
                    if (data.success) {
                        this.props.onSuccessFunction();
                    }
                    else {
                        HubFunctions.PopupCreate("Error", "Error while saving api key: " + data.message);
                    }
                });
            }
            else {
                updateApiKeys(this.props.projectId, this.state.Selected_Value).then((data) => {
                    if (data.success) {
                        this.props.onSuccessFunction();
                    }
                    else {
                        HubFunctions.PopupCreate("Error", "Error while saving api key: " + data.message);
                    }
                });
            }
        });
    }
    /**
     * Edit the value of a User scope
     * @param id id of the scope line
     * @param value value
     */
    EditScopeValue(id, value) {
        let tenantLines = this.state.tenantSystem;
        tenantLines.find(x => x.id === id).value = value;
        this.setState({ tenantSystem: tenantLines });
    }
    /**
     * displays the user config for the individual scopes
     */
    getAuthLevel_NewTenantManagement() {
        let globalScope = React.createElement(React.Fragment, null);
        // hub level configuration
        if (this.props.projectId === "null") {
            // id of the global scope
            let mglobalid = this.state.tenantSystem.find(x => x.scopes.length === 1 && x.scopes.every((value, index) => value === ["user"][index])).id;
            // set global to default auth if not set
            if (this.state.tenantSystem.find(x => x.id === mglobalid).value === "") {
                this.EditScopeValue(mglobalid, defaultAuth);
            }
            // if called from within project, don't show global scope
            globalScope = React.createElement(SetGlobalTenantSystemContent, { tenantSystem: this.state.tenantSystem, globalId: mglobalid, authLevels: AuthLevels, defaultAuth: defaultAuth, onEditFunction: this.EditScopeValue.bind(this) });
        }
        return React.createElement(Row, null,
            React.createElement(Col, null,
                globalScope,
                React.createElement(UserTenantManagement, { tenantSystem: this.state.tenantSystem, setTenantSystemFunction: this.setTenantSystemFunction.bind(this), projectId: this.props.projectId, authLevels: AuthLevels, listOfIDMSSubscopes: this.state.listOfSubscopes, global: this.props.projectId === "null", useSelectOptions: true }),
                React.createElement("p", null, "Please remember to click \"Add\" for each new scope."),
                React.createElement("br", null),
                React.createElement(Button, { className: "uib-button uib-button--primary", id: "addScope", onClick: () => this.addGlobalScope() }, "Add Scope")));
    }
    setTenantSystemFunction(tenantSystem) {
        this.setState({ tenantSystem: tenantSystem });
    }
    render() {
        let authLevel;
        if (this.state.Selected_Value.contentType === IApiKeyContentTypes.hubIdmsTenantJson) {
            authLevel = this.getAuthLevel_NewTenantManagement();
        }
        else {
            // create a giant text box
            authLevel = React.createElement(Row, null,
                React.createElement(Col, null,
                    React.createElement(FormControl, { id: "authLevelCustom", as: "textarea", rows: 10, value: this.state.Selected_Value.content, onChange: (event) => {
                            let apiKey = this.state.Selected_Value;
                            apiKey.content = event.target.value;
                            this.setState({ Selected_Value: apiKey });
                        } })));
        }
        return React.createElement("div", null,
            React.createElement(ModalHeader, { closeButton: true },
                this.state.editMode === IApiKeysEditMode.Add ? "Add" : "Edit",
                " API Key"),
            React.createElement(ModalBody, null,
                React.createElement(Container, null,
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 2, className: "align-self-center" }, "Display Name"),
                        React.createElement(Col, null,
                            React.createElement(FormControl, { placeholder: "Display Name", value: this.state.Selected_Value.displayName, id: "displayName", onChange: (event) => {
                                    let apiKey = this.state.Selected_Value;
                                    apiKey.displayName = event.target.value;
                                    this.setState({ Selected_Value: apiKey });
                                } }))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 2, className: "align-self-center" }, "Expiration Date"),
                        React.createElement(Col, null,
                            React.createElement(Form.Check, { checked: this.state.expirationDate, id: "useExpirationDate", onChange: (event) => {
                                    this.setState({ expirationDate: event.target.checked });
                                } }))),
                    this.state.expirationDate &&
                        React.createElement(Row, { className: styles.popupSpaceBetween },
                            React.createElement(Col, { md: 2, className: "align-self-center" }, "Valid Until"),
                            React.createElement(Col, null,
                                React.createElement(FormControl, { type: "date", value: this.state.Selected_Value.validUntil, id: "validUntil", onChange: (event) => {
                                        let apiKey = this.state.Selected_Value;
                                        apiKey.validUntil = event.target.value;
                                        this.setState({ Selected_Value: apiKey });
                                    } }))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 2, className: "align-self-center" }, "Use tenant system"),
                        React.createElement(Col, null,
                            React.createElement(Form.Check, { checked: this.state.Selected_Value.contentType === IApiKeyContentTypes.hubIdmsTenantJson, id: "useTenantSystem", onChange: (event) => {
                                    let apiKey = this.state.Selected_Value;
                                    apiKey.contentType = event.target.checked ? IApiKeyContentTypes.hubIdmsTenantJson : IApiKeyContentTypes.custom;
                                    this.setState({ Selected_Value: apiKey });
                                } }))),
                    authLevel)),
            React.createElement(ModalFooter, null,
                React.createElement(Button, { className: "uib-button uib-button--secondary", variant: "secondary", onClick: () => this.props.closeFunction(), id: "cancelButton" }, "Cancel"),
                React.createElement(Button, { className: `uib-button uib-button--primary ${styles.addUserButton}`, id: "applyButton", onClick: () => { this.saveUser(); } }, "Apply")));
    }
}
