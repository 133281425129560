var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from "react";
import { DeleteProject, getProject } from "../BackendFacade/apiCalls";
import { HubPathRouting, switchModule } from "../../../HubFramework/pathBuilder";
import styles from "../ProjectContent.module.css";
import { Button, Modal } from "react-bootstrap";
import { Popup } from "../Interfaces/IProjectInformation";
import { ProjectInformation_Edit } from "./Modals/ProjectInformation_Edit";
import { isUserroleSufficient } from "../../../Utils/authorization";
import { UserRoles } from "../../../Utils";
import { GlobalDictionary } from "../../../Utils/globalDictionary";
import { moduleContent as Hubmodule } from "../../Hub";
import HubFunctions from "../../../HubFramework/hubFunctions";
const PROJECTINFO = "projectInfo";
export class ProjectInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            description: "",
            contacts: [],
            costCenter: 0,
            department: "",
            product: "",
            popup: Popup.None,
            popupMessage: "",
            editModal: undefined,
            customProjectIcon: "",
            internalOrderNumber: 0,
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!GlobalDictionary.get(PROJECTINFO + HubPathRouting.currentProject.moduleInstanceId)) {
                HubFunctions.showLoading();
                yield this.getProjectInfo();
                HubFunctions.hideLoading();
            }
            else {
                this.prepareProjectInfo();
                this.getProjectInfo();
            }
        });
    }
    getProjectInfo() {
        return __awaiter(this, void 0, void 0, function* () {
            yield getProject(HubPathRouting.currentProject.moduleInstanceId).then((data) => {
                if (data.success) {
                    let project = data.data.value;
                    GlobalDictionary.set(PROJECTINFO + HubPathRouting.currentProject.moduleInstanceId, project);
                    this.prepareProjectInfo();
                }
                else {
                    HubFunctions.PopupCreate("Error", data.message);
                }
            });
        });
    }
    prepareProjectInfo() {
        let project = GlobalDictionary.get(PROJECTINFO + HubPathRouting.currentProject.moduleInstanceId);
        this.setState({
            description: project.description,
            contacts: project.contacts,
            costCenter: project.costCenter,
            department: project.department,
            product: project.product,
            customProjectIcon: project.customProjectIcon,
            internalOrderNumber: project.internalOrderNumber,
        });
        this.props.parentModule.setDisplayName(project.displayName);
    }
    EditProject_show() {
        let editModal = {
            projectId: HubPathRouting.currentProject.moduleInstanceId,
            description: this.state.description,
            contacts: this.state.contacts,
            costCenter: this.state.costCenter,
            department: this.state.department,
            product: this.state.product,
            customProjectIcon: this.state.customProjectIcon,
            internalOrderNumber: this.state.internalOrderNumber,
            newContact: { mail: "", job: "" },
            displayName: this.props.parentModule.state.name,
        };
        this.setState({ popup: Popup.Edit, editModal: editModal });
    }
    closePopup() {
        this.setState({ popup: Popup.None });
    }
    deleteProject(projectId, hardDelete = false) {
        HubFunctions.showLoading();
        DeleteProject(projectId, hardDelete).then((data) => {
            if (data.success) {
                this.closePopup();
                switchModule(Hubmodule.moduleName);
            }
            else {
                HubFunctions.PopupCreate("Error", data.message);
            }
            HubFunctions.hideLoading();
        });
    }
    showHardDeletePopup() {
        HubFunctions.PopupCreate("Delete Project", "Are you sure, Project " + HubPathRouting.currentProject.displayName + " shall be deleted? There is no undo!", this.deleteProject.bind(this, HubPathRouting.currentProject.moduleInstanceId, true));
    }
    showSoftDeletePopup() {
        HubFunctions.PopupCreate("Delete Project", "Are you sure, Project " + HubPathRouting.currentProject.displayName + " shall be deleted? There is no undo!", this.deleteProject.bind(this, HubPathRouting.currentProject.moduleInstanceId));
    }
    projectIcon() {
        if (this.state.customProjectIcon === "" || this.state.customProjectIcon === null)
            return (React.createElement(React.Fragment, null));
        // Convert the base64 string to a Uint8Array
        const base64Image = this.state.customProjectIcon;
        const byteString = atob(base64Image.split(',')[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            uint8Array[i] = byteString.charCodeAt(i);
        }
        // Create a Blob from the Uint8Array and display the image
        const blob = new Blob([uint8Array], { type: 'image/png' });
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(blob);
        return React.createElement("img", { src: imageUrl, alt: 'Project Icon', className: styles.projectIcon });
    }
    render() {
        var _a, _b;
        let popupContent = React.createElement("div", null);
        switch (this.state.popup) {
            case Popup.Edit:
                popupContent = React.createElement(ProjectInformation_Edit, { onSuccessFunction: this.getProjectInfo.bind(this), closeFunction: this.closePopup.bind(this), state: this.state.editModal });
                break;
            default:
                break;
        }
        return React.createElement(React.Fragment, null,
            React.createElement(Modal, { size: "lg", show: this.state.popup !== Popup.None, onHide: () => this.setState({ popup: Popup.None }) }, popupContent),
            React.createElement("div", { id: "title", className: `${styles.title}` },
                React.createElement("div", { className: styles.titlecontainer },
                    this.projectIcon(),
                    this.props.parentModule.state.name)),
            React.createElement("div", { className: `${styles.GroupHeader}` }, "Description"),
            React.createElement("div", { id: "description", className: styles.GroupContent }, this.state.description),
            React.createElement("div", { className: `${styles.GroupHeader}` }, "Contacts"),
            this.state.contacts.map((contact, key) => {
                return React.createElement("div", { id: "contact" + key, className: styles.GroupContent },
                    contact.mail,
                    " - ",
                    contact.job);
            }),
            React.createElement("div", { className: `${styles.GroupHeader}` }, "Cost Center"),
            React.createElement("div", { id: "costCenter", className: styles.GroupContent }, this.state.costCenter),
            React.createElement("div", { className: `${styles.GroupHeader}` }, "Internal Order Number"),
            React.createElement("div", { id: "internalOrderNumber", className: styles.GroupContent }, this.state.internalOrderNumber),
            React.createElement("div", { className: `${styles.GroupHeader}` }, "Department"),
            React.createElement("div", { id: "department", className: styles.GroupContent }, this.state.department),
            React.createElement("div", { className: `${styles.GroupHeader}` }, "Product"),
            React.createElement("div", { id: "product", className: styles.GroupContent }, this.state.product),
            isUserroleSufficient(UserRoles.editor, (_a = HubPathRouting.currentProject) === null || _a === void 0 ? void 0 : _a.moduleInstanceId) &&
                React.createElement("div", { className: `${styles.EditButton}` },
                    React.createElement(Button, { id: "editInformation", className: "uib-button uib-button--primary", onClick: () => this.EditProject_show() }, "Edit Information")),
            isUserroleSufficient(UserRoles.admin, (_b = HubPathRouting.currentProject) === null || _b === void 0 ? void 0 : _b.moduleInstanceId) &&
                React.createElement("div", { className: `${styles.EditButton} ` },
                    React.createElement("div", { className: `${styles.title}` }, "Delete the project"),
                    React.createElement("p", null,
                        "If you want to delete the project including all modules, press \"Delete Project (hard)\". Remember: There is no undo!",
                        React.createElement("br", null),
                        "\"Delete Project (soft)\" will delete this project, but all modules instances will preserve. You will need the assist of a Hub Admin to reinstantiate the module instances.",
                        React.createElement("br", null)),
                    React.createElement("br", null),
                    React.createElement("span", null,
                        React.createElement(Button, { id: "deleteProjectHard", className: `uib-button uib-button--primary ${styles.errorButton} ${styles.spaceRight}`, onClick: () => { this.showHardDeletePopup(); } }, "Delete Project (hard)"),
                        React.createElement(Button, { id: "deleteProjectSoft", className: `uib-button uib-button--primary ${styles.errorButton}`, onClick: () => { this.showSoftDeletePopup(); } }, "Delete Project (soft)"))));
    }
}
