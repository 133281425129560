import * as React from "react";
import { deepCopy } from "../../../../Utils/transformer";
import { updateModuleInstance } from "../../BackendFacade/apiCalls";
import { Button, Col, Container, FormControl, ModalBody, ModalFooter, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import styles from "../../ProjectContent.module.css";
import ModalHeader from "react-bootstrap/ModalHeader";
import { Popup } from "../../Interfaces/IAllModules_Edit";
import { AllModules_Delete } from "./AllModules_Delete";
import HubFunctions from "../../../../HubFramework/hubFunctions";
export class AllModules_Edit extends React.Component {
    constructor(props) {
        super(props);
        this.projectId = this.props.projectId;
        this.state = deepCopy(this.props.state);
        // this.setState({popup: Popup.None})
    }
    /**
     * Applies the changes to the project
     */
    applyProject() {
        HubFunctions.showLoading();
        updateModuleInstance(this.projectId, this.state).then((data) => {
            if (data.success) {
                this.props.onSuccessFunction();
                this.props.closeFunction();
            }
            else {
                HubFunctions.PopupCreate("Error", data.message);
            }
        });
        HubFunctions.hideLoading();
    }
    closePopup() {
        this.setState({ popup: Popup.None });
    }
    showDeletePopup() {
        this.setState({ popup: Popup.DeleteModule });
    }
    mainContent() {
        let applyButton = React.createElement(OverlayTrigger, { placement: "top", delay: { show: 1, hide: 200 }, overlay: React.createElement(Tooltip, { id: "tooltip-disabled" }, "Please make sure all fields except for the description are filled.") },
            React.createElement(Button, { className: `uib-button uib-button--primary ${styles.applyButton} ${styles.errorButton}` }, "Apply"));
        // check if every field is filled, activate apply button
        if (this.state.displayName !== ""
            && this.state.group !== "") {
            applyButton = React.createElement(Button, { className: `uib-button uib-button--primary ${styles.applyButton}`, onClick: () => { this.applyProject(); } }, "Apply");
        }
        return React.createElement("div", null,
            React.createElement(ModalHeader, { closeButton: true }, "Add/Edit User"),
            React.createElement(ModalBody, null,
                React.createElement(Container, null,
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 2, className: "align-self-center" }, "Display name"),
                        React.createElement(Col, null,
                            React.createElement(FormControl, { placeholder: "Display name", value: this.state.displayName, id: "displayname", onChange: (event) => {
                                    this.setState({ displayName: event.target.value });
                                } }))),
                    React.createElement(Row, { className: styles.popupSpaceBetween },
                        React.createElement(Col, { md: 2, className: "align-self-center" }, "Group"),
                        React.createElement(Col, null,
                            React.createElement(FormControl, { placeholder: "Group name", value: this.state.group, id: "group", onChange: (event) => {
                                    this.setState({ group: event.target.value });
                                } }))))),
            React.createElement(ModalFooter, null,
                React.createElement("div", { className: styles.EditModuleFooter },
                    React.createElement(Button, { className: `uib-button uib-button--secondary ${styles.errorButton}`, variant: "secondary", onClick: () => { this.showDeletePopup(); } }, "Delete"),
                    React.createElement("div", null,
                        React.createElement(Button, { className: "uib-button uib-button--secondary", variant: "secondary", onClick: () => this.props.closeFunction() }, "Cancel"),
                        applyButton))));
    }
    deleteSuccess() {
        this.props.onSuccessFunction();
        this.props.closeFunction();
    }
    render() {
        let popupContent = React.createElement("div", null);
        switch (this.state.popup) {
            case Popup.DeleteModule:
                popupContent = React.createElement(AllModules_Delete, { projectId: this.projectId, moduleInstanceId: this.state.moduleInstanceId, onSuccessFunction: () => { this.deleteSuccess(); }, closeFunction: () => { this.closePopup(); } });
                break;
            default:
                popupContent = this.mainContent();
                break;
        }
        return React.createElement("div", null, popupContent);
    }
}
